import axios from 'axios';

const FVL2PAPI = axios.create({
   
    baseURL: process.env.REACT_APP_BASE_URL + '/fuerza-venta-l2p'
});

FVL2PAPI.interceptors.request.use(config =>{
    config.headers = {
        ...config.headers,
        'x-token' : localStorage.getItem('token')
    }
    return config;
})

export default FVL2PAPI;