import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useSelector } from "react-redux";


import Variacion from '../../../components/dashboards/general/Variacion';
import Produccion from '../../../components/dashboards/general/Produccion';
import Ingresos from '../../../components/dashboards/general/Ingresos';
import GraficoVentas from 'components/dashboards/general/GraficoVentas';
import TablaVentas from 'components/dashboards/general/TablaVentas';

import { useGeneralStore } from 'hooks/useGeneralStore';

import GreetingsDate from '../../../components/dashboards/crm/greetings/GreetingsDate';
import GreetingCard from '../../../components/dashboards/fuerza-de-venta/GreetingCard';
import RetornoInversion from 'components/dashboards/general/RetornoInversion';
import { useUtilStore } from 'hooks/useUtilStore';


const General = () => {
    const [variacionPromise, setVariacionPromise] = useState([
        { id: 1, value: 0, name: `Emisiones año anterior`, color: 'gray-300' },
        { id: 2, value: 0, name: `Emisiones año actual`, color: 'primary' }
    ]);
    const [rendimientoPromise, setRendimientoPromise] = useState([
        { ventas: 0 }, { cotizaciones: 0 }
    ]);
    const [pendientesPromise, setPendientesPromise] = useState([
        { pendientesAcumuladoPropYdoc: 0 },
        { pendientesAcumuladoPago: 0 },
        { pendientesInspeccion: 0 }
    ])
    const [produccionPromise, setProduccionPromise] = useState([
        { primaNetaVendida: 0 },
        { primaNetaPromedio: 0 },
        { primaNetaAnulada: 0 },
        { tasaDeAnulacion: 0 }
    ])
    const [ingresosPromise, setIngresosPromise] = useState([
        { primaNetaVigente: 0 },
        { tasaComisionPromedio: 0 },
        { ingresosNetos: 0 },
        { ingresosNetosEnCLP: 0 },
        { ingresoPromedio: 0 }
    ])
    const {
        startGeneralVariacion,
        startGeneralProduccionAdmin,
        startGeneralIngresosAdmin,
        startGraficoVentas,
        startGraficoRenovaciones,
        startGraficoStock,
        startRankingAnulaciones,
        startRankingCancelaciones,
        startIPC,
        startTablaRI
    } = useGeneralStore();

    const { startufDelDia, startObtener_ipc } = useUtilStore();


    const [graficoVentas, setGraficoVentas] = useState()
    const [graficoRenovaciones, setGraficoRenovaciones] = useState()
    const [graficoStock, setGraficoStock] = useState()

    const [rankingAnulacion, setRankingAnulacion] = useState()
    const [rankingCancelaciones, setRankingCancelaciones] = useState()

    const [ipcActual, setIpcActual] = useState()

    const [tablaRI, setTablaRI] = useState()
    //States
    const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
    const { user } = useSelector(state => state.login);


    useEffect(async () => {
        let variacionPromise = await startGeneralVariacion(dataInicio, dataFinal);
        setVariacionPromise(variacionPromise);
        let produccionPromise = await startGeneralProduccionAdmin(dataInicio, dataFinal);
        setProduccionPromise(produccionPromise);
        let ingresosPromise = await startGeneralIngresosAdmin(dataInicio, dataFinal);
        setIngresosPromise(ingresosPromise);
    }, [dataInicio, dataFinal]);

    useEffect(async () => {

        let GraficoVentas = await startGraficoVentas();
        setGraficoVentas(GraficoVentas)

        let GraficoRenovaciones = await startGraficoRenovaciones();
        setGraficoRenovaciones(GraficoRenovaciones)

        let GraficoStock = await startGraficoStock();
        setGraficoStock(GraficoStock)

        let RankingAnulacion = await startRankingAnulaciones(); 
        setRankingAnulacion(RankingAnulacion)


        let RankingCancelaciones = await startRankingCancelaciones();
        setRankingCancelaciones(RankingCancelaciones)
        

        let ipcActual = await startObtener_ipc();
       
        setIpcActual(parseFloat(ipcActual?.valorAcumuladoIPC).toFixed(1))


        let tablaRI = await startTablaRI();
        setTablaRI(tablaRI)


        
        
        
    }, []);

    return (
        <>
            <Card className="bg-100 shadow-none border mb-3">
                <Card.Body>
                    <Row className="g-0 justify-content-between">
                        <Col md="auto" className="mb-3 mb-md-0">
                            <Row className="g-3 gy-md-0 h-100 align-items-center">
                                <Col xs="auto">
                                    <h6 className="text-700 mb-0 text-nowrap">Mostrando información para las fechas:</h6>
                                </Col>
                                <Col md="auto">
                                    <GreetingsDate />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <Row className="g-3">
                        <Col xs={6}>
                            <GreetingCard nombre={user.name} />
                        </Col>
                        <Col xs={6}>
                            <Variacion data={variacionPromise} radius={['100%', '80%']} />
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                <Col md={6}>
                                    <Produccion data={produccionPromise} />
                                </Col>
                                <Col md={6}>
                                    <Ingresos variacion={variacionPromise} ipc={ipcActual} data={ingresosPromise} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                {
                                    graficoVentas?<GraficoVentas nombreVal="Activas" data={graficoVentas} />:'Cargando'
                                }
                                
                            </Row>
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                {
                                    graficoRenovaciones?<GraficoVentas nombreVal="Renovadas" data={graficoRenovaciones} />:'Cargando'
                                }
                                
                            </Row>
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                {
                                    graficoStock?<GraficoVentas nombreVal="Stock" data={graficoStock} />:'Cargando'
                                }
                                
                            </Row>
                        </Col>
                        <Row className='g-3'>
                            <Col lg={6} >
                                {
                                    rankingAnulacion?<TablaVentas nombre="Anulacion" data={rankingAnulacion} />:'Cargando'
                                }
                                
                            </Col>
                            <Col lg={6}>
                                {
                                    <TablaVentas nombre="Cancelacion" data={rankingCancelaciones} />
                                }
                               
                            </Col>
                            <Col lg={12}>
                                {
                                    tablaRI?<RetornoInversion nombre="Retorno sobre la inversión" data={tablaRI.data} ufActual={tablaRI.ufActual} />:'Cargando'
                                }
                               
                            </Col>
                        </Row>

                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default General;
