import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import VentasVsEjecutivoChart from './VentasVsEjecutivoChart';

const VentasVsEjecutivo = (props) => {
  const [ejecutivoSeleccionado, setEjecutivoSeleccionado] = useState('');
  const [dataFiltrada, setDataFiltrada] = useState(props.data);

  useEffect(() => {
    if (!ejecutivoSeleccionado) {
      // Si no hay selección en el select, utiliza la data inicial
      setDataFiltrada(props.data);
    } else {
      // Si hay selección en el select, filtra la data correspondiente
      const filteredObjects = props.data.map(item => ({
        [ejecutivoSeleccionado]: item[ejecutivoSeleccionado],
        date: item['date']
      }));
      setDataFiltrada(filteredObjects);
    }
  }, [ejecutivoSeleccionado, props.data]);

  const ejecutivos = Object.keys(props.data[0]).slice(1);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setEjecutivoSeleccionado(selectedValue);
  };

  return (
    <Card className='mt-3'>
      <Card.Header className='pb-0'>
        <div style={{ display: 'flex' }}>
          <h6 className='mb-0 mt-2' style={{ paddingRight: '1rem' }}>
            Venta nueva por ejecutivo{' '}
          </h6>
          <select value={ejecutivoSeleccionado} onChange={handleChange}>
            <option value=''>Todos</option>
            {ejecutivos.map((ejecutivo) => (
              <option key={ejecutivo} value={ejecutivo}>
                {ejecutivo}
              </option>
            ))}
          </select>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className='g-1 align-items-center'>
          <Col xxl={12} xl={12}>
            <VentasVsEjecutivoChart data={dataFiltrada} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default VentasVsEjecutivo;