import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table, Container } from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { BsChevronBarExpand, BsChevronBarDown, BsChevronBarUp } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useUtilStore } from 'hooks/useUtilStore';
import moment from 'moment';
import Swal from 'sweetalert2';

const TablaReact = ({ columns, data, size = 'sm', Ncolum = 10, onRadioChange, btnCotizar=false }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: Ncolum,sortBy: [
        {
          id: 'cotiza_fecha', // Identificador de la columna
          desc: true, // Orden descendente
        },
      ], }, // Initial page index and page size
    },
    useGlobalFilter,
    useSortBy, // Habilita la ordenación
    usePagination
  );

  const [selectedRadio, setSelectedRadio] = useState(null);

const {

  startGetEmbajador
  // Otros métodos del useGestionStore
} = useUtilStore();


  const { user } = useSelector(state => state.login);
  const [linkEmbajador, setLinkEmbajador] = useState('')
 
  


  useEffect(async() => {
    let dataTemp = await startGetEmbajador(user.uid)
    dataTemp?setLinkEmbajador(dataTemp.Link):setLinkEmbajador('')

  }, [])
  

  const handleRadioChange = (rowId) => {
    
    // Parsear la fecha en formato ISO 8601
    let fechaOferta = moment(rowId.ofcotiza_fecha);
    let fechaActual = moment();

    let diferencia = fechaActual.diff(fechaOferta, 'days');

    if(diferencia>=13){
      Swal.fire({
        icon: 'warning',
        title: 'Oferta excede tiempo de validez',
        text: 'Para obtener ofertas válidas cotice nuevamente',
      })
      
    }else{
      setSelectedRadio(rowId.ofcotiza_id);
      // Call the callback function with the selected value
      onRadioChange(rowId.ofcotiza_id);
    }

  };

  return (
    <div className='tablaCaja'>
      <Container fluid>
        <Row className='p-22'>
          <Col xs='6'>
            <Form.Label htmlFor='inlineFormInputGroup' visuallyHidden>
              Username
            </Form.Label>
            <InputGroup className='mb-2'>
              <InputGroup.Text>🔎</InputGroup.Text>
              <Form.Control
                id='inlineFormInputGroup'
                placeholder='Buscar...'
                value={globalFilter || ''}
                onChange={(e) => {
                  setGlobalFilter(e.target.value || undefined);
                }}
              />
            </InputGroup>
          </Col>
          {
            btnCotizar?( <Col xs='6 flexMagic'>
            <a target='_blank' href={linkEmbajador} className=''><Button  className='p-04-6'>Cotizar</Button></a>
            </Col>):''
          }
         
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Table
            responsive
            hover
            size={size}
            className='compact-table'
            {...getTableProps()}
            style={{ tableLayout: 'auto', maxWidth: '100%' }}
          >
            <thead className='headerTabla'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())} // Agrega las propiedades de ordenación
                      style={{ whiteSpace: 'nowrap' }} // Evita que el texto se divida en varias líneas
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ?  <BsChevronBarUp className='ml-05' /> : <BsChevronBarDown className='ml-05' />) :  <BsChevronBarExpand className='ml-05' />}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} style={{ width: cell.column.width, textTransform:'capitalize' }}>
                        {cell.column.id === 'Seleccione' ? (
                          <div className='d-grid text-center'>
                            <Form.Check
                              type={'radio'}
                              name={'radioGroup'}
                              value={row.original.ofcotiza_id}
                              checked={selectedRadio === row.original.ofcotiza_id}
                              onChange={() => handleRadioChange(row.original)}
                            />
                          </div>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <div className='btnTabla'>
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Anterior
              </Button>
            </div>
            <div>
              <span>
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageCount}
                </strong>{' '}
              </span>
            </div>
            <div className='btnTabla'>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Siguiente
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TablaReact;
