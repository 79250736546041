// En TablaReact
import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table, Container } from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { BsChevronBarExpand, BsChevronBarDown, BsChevronBarUp } from "react-icons/bs";


const TablaReact = React.memo(({ columns, data, size = 'sm', Ncolum = 10, onRadioChange, onEstadoFiltroChange }) => {
    const dataArray = Array.isArray(data) ? data : data.data;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, globalFilter },
        setGlobalFilter,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = useTable(
        {
            columns,
            data: dataArray,
            initialState: { pageIndex: 0, pageSize: Ncolum },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const [selectedRadio, setSelectedRadio] = useState(null);

    const handleRadioChange = (rowId) => {
        setSelectedRadio(rowId);
        // Call the callback function with the selected value
        onRadioChange(rowId);
    };

    const handleEstadoFiltroChange = (estado) => {
        // Call the callback function with the selected value
        onEstadoFiltroChange(estado);
    };

    return (
        <div className='tablaCaja'>
            <Container fluid>
                <Row>
                    <Col xs='6'>
                        <h6 className="mb-1 text-700 fw-normal lh-1">Buscar en la tabla:</h6>
                        <InputGroup className='mb-2'>
                            <InputGroup.Text>🔎</InputGroup.Text>
                            <Form.Control
                                id='inlineFormInputGroup'
                                placeholder='Buscar...'
                                value={globalFilter || ''}
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value || undefined);
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs='6'>
                        <h6 className="mb-1 text-700 fw-normal lh-1">Filtrado por</h6>
                        <InputGroup className='mb-2'>
                        <Form.Control
    as="select"
    name="userRole"
    value={selectedRadio}
    onChange={(e) => {
        setSelectedRadio(e.target.value);
        // Llama a la función onRadioChange con el valor seleccionado
        onRadioChange(parseInt(e.target.value));
    }}
>
    <option value={1}>Solo Activos</option>
    <option value={2}>Todos los usuarios</option>
</Form.Control>
                        </InputGroup>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Table
                        responsive
                        hover
                        size={size}
                        className='compact-table'
                        {...getTableProps()}
                        style={{ tableLayout: 'auto', maxWidth: '100%' }}
                    >
                        <thead className='headerTabla'>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())} // Agrega las propiedades de ordenación
                                            style={{ whiteSpace: 'nowrap' }} // Evita que el texto se divida en varias líneas
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? <BsChevronBarUp className='ml-05' /> : <BsChevronBarDown className='ml-05' />) : <BsChevronBarExpand className='ml-05' />}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} >
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()} style={{ width: cell.column.width, textTransform: 'capitalize', textAlign: cell.column.textAlign }}>
                                                {cell.column.id === 'Seleccione' ? (
                                                    <div className='d-grid text-center'>
                                                        <Form.Check
                                                            type={'radio'}
                                                            name={'radioGroup'}
                                                            value={row.original.ofcotiza_id}
                                                            checked={selectedRadio === row.original.ofcotiza_id}
                                                            onChange={() => handleRadioChange(row.original.ofcotiza_id)}
                                                        />
                                                    </div>
                                                ) : (
                                                    cell.render('Cell')
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Col className='d-flex justify-content-center align-items-center'>
                        <div className='btnTabla'>
                            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                Anterior
                            </Button>
                        </div>
                        <div>
                            <span>
                                Página{' '}
                                <strong>
                                    {pageIndex + 1} de {pageCount}
                                </strong>{' '}
                            </span>
                        </div>
                        <div className='btnTabla'>
                            <Button onClick={() => nextPage()} disabled={!canNextPage}>
                                Siguiente
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
});

export default TablaReact;
