import React from 'react';
import { Card, Col } from 'react-bootstrap';
import Flex from '../../../components/common/Flex';

const Ingresos = ({ data }) => {
    const format = (number) => {
        if( number !== undefined){
            const parts = number.toString().split('.');
            const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            const decimalPart = parts[1] ? ',' + parts[1] : '';
            return integerPart + decimalPart;
        }

    }
    let colorTasaCommisionPromedio = '';
    const tasaComisionPromedio = data[1].tasaComisionPromedio;
    if (tasaComisionPromedio <= 13.9) {
        colorTasaCommisionPromedio = 'danger';
    } else if (tasaComisionPromedio >= 14 && tasaComisionPromedio <= 16) {
        colorTasaCommisionPromedio = 'warning';
    } else if (tasaComisionPromedio >= 16.1 && tasaComisionPromedio <= 19.9) {
        colorTasaCommisionPromedio = 'success';
    } else if (tasaComisionPromedio >= 20) {
        colorTasaCommisionPromedio = 'primary'
    }
    let colorIngresoPromedio = '';
    const ingresoPromedio = data[4].ingresoPromedio;
    if (ingresoPromedio <= 1.2) {
        colorIngresoPromedio = 'danger';
    } else if (ingresoPromedio >= 1.3 && ingresoPromedio <= 1.6) {
        colorIngresoPromedio = 'warning';
    } else if (ingresoPromedio >= 1.7 && ingresoPromedio <= 2) {
        colorIngresoPromedio = 'success';
    } else if (ingresoPromedio >= 2.1) {
        colorIngresoPromedio = 'primary'
    }
    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Ingresos</h6>
            </Card.Header>
            <Card.Body as={Flex} >
                <Col xl={4} className='pe-3'>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{format(data[0].primaNetaVigente?data[0].primaNetaVigente.toFixed(2):0)}</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-${colorTasaCommisionPromedio}`}>
                            {format(data[1].tasaComisionPromedio?data[1].tasaComisionPromedio.toFixed(2):0)}%
                        </span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span>UF&nbsp;{format(data[2].ingresosNetos?data[2].ingresosNetos.toFixed(2):0)}</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span>${format(data[3].ingresosNetosEnCLP?data[3].ingresosNetosEnCLP.toFixed(0):0)}</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-${colorIngresoPromedio}`}>
                            UF&nbsp;{format(data[4].ingresoPromedio?data[4].ingresoPromedio.toFixed(2):0)}
                        </span>
                    </h6>
                </Col>
                <Col xl={8}>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta renovada vigente
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Tasa comisión promedio
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Ingresos netos
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Ingresos netos app en CLP
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Ingreso promedio
                    </h6>
                </Col>
            </Card.Body>
        </Card>
    );
}

export default Ingresos;
