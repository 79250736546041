import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Button, Card, Tooltip, OverlayTrigger, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useUsuariosStore } from "hooks/useUsuariosStore";

const ModalEditUsuarios = ({
    show, onHide, initialData, onSave, roles
}) => {
    const [formData, setFormData] = useState({
        ...initialData,
        password: '',
        confirmPassword: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const handleSave = () => {
        if (formData.password !== formData.confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Claves no coinciden!'
            });
            return;
        }
        const dataToSave = {
            ...initialData,
            u_nombre: formData.u_nombre,
            u_usuario: formData.u_usuario,
            u_email: formData.u_email,
            password: formData.password,
            userRole: formData.userRole,
            ejec_eq: eqSelect || formData.ejec_eq,
            id_ejec: formData.id_ejec,
            eje_por: porSelect || formData.eje_por

        };
        onSave(dataToSave);
        onHide();
    }
    const [equipos, setEquipos] = useState([])
    const [eqSelect, setEqSelect] = useState()
    const [porSelect, setPorSelect] = useState('')
    const {
        startGetEquipo
    } = useUsuariosStore();

    useEffect(async() => {

        if (initialData.u_rol) {
            setFormData((prevData) => ({
                ...prevData,
                userRole: initialData.u_rol
            }))
        }

        let dataTemp = await startGetEquipo()
        if(dataTemp){
            setEquipos(dataTemp.data)
        }
    }, [initialData.u_rol]);

    return (
        <Modal
            show={show} onHide={onHide} size="lg"
            aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editar Usuario
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
            <Row className="p-2 mb-1">
                
                <Col xs={4} className="p-2 ">
                    <Form.Group controlId="formNombreUsuario">
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Control
                                type="text"
                                name="u_nombre"
                                value={formData.u_nombre}
                                onChange={handleChange}></Form.Control>
                        </Form.Group>
                </Col>

                
                <Col xs={4} className="p-2 ">
                        <Form.Group controlId="formAliasUsuario">
                            <Form.Label>Usuario:</Form.Label>
                            <Form.Control
                                type="text"
                                name="u_usuario"
                                value={formData.u_usuario}
                                onChange={handleChange}></Form.Control>
                        </Form.Group>
                </Col>
                    <Col xs={4} className="p-2 ">
                        <Form.Group controlId="formEmailUsuario">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="text"
                                name="u_email"
                                value={formData.u_email}
                                onChange={handleChange}></Form.Control>
                        </Form.Group>
                    </Col>
                    <hr />
                    <Col xs={6} className="p-2 ">
                        <Form.Group controlId="formPassword">
                            <Form.Label>Clave:</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                autoComplete="new-password"></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="p-2 ">
                        <Form.Group controlId="formConfirmPassword">
                            <Form.Label>Confirmar clave</Form.Label>
                            <Form.Control
                                type="password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                autoComplete="new-password"></Form.Control>
                        </Form.Group>
                    </Col>
                    <hr />
                    <Col xs={12} className="p-2 ">
                        <Form.Group controlId="formUserRole">
                            <Form.Label>Rol de Usuario:</Form.Label>
                            <Form.Control
                                as="select"
                                name="userRole"
                                value={formData.userRole }
                                onChange={handleChange}>
                                {roles?.map((role) => (
                                    <option key={role.id} value={role.id}>
                                        {role.descripcion}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    
                    <Col xs={12} className="p-2 ">
                        <Form.Group controlId="formequipo">
                            <Form.Label>Equipo</Form.Label>
                            <Form.Control
                                as="select"
                                name="ejec_eq"
                                value={eqSelect || formData.ejec_eq}
                                onChange={(e) => setEqSelect(e.target.value)}>
                                    <option value={''}>Seleccione Rol</option>
                                {equipos?.map((eq, index) => (
                                    <option key={index} value={eq.eq}>
                                        {eq.eq}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="p-2 ">
                        <Form.Group controlId="eje_por">
                            <Form.Label>Cotizaciones Usuario</Form.Label>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-carga`}>
                                        Define la frecuencia de asignación de cotizaciones. 
                                    </Tooltip>
                                }
                            >
                            <Form.Control
                                as="select"
                                name="eje_por"
                                value={porSelect || formData.eje_por}
                                onChange={(e) => setPorSelect(e.target.value)}>
                                    <option value={''}>Seleccione Carga</option>
                                
                                    <option value={10}>
                                        1 cotizacion
                                    </option>
                                    <option value={20}>
                                        2 cotizacion
                                    </option>
                                    <option value={30}>
                                        3 cotizacion
                                    </option>
                                
                            </Form.Control>
                            </OverlayTrigger>
                            
                        </Form.Group>
                    </Col>

                    
                
                </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                    Actualizar
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEditUsuarios;