import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import VentasVsObjetivoChart from './VentasVsObjetivoChart';
import CumplimientoChart from './CumplimimientoChart';


const VentasVsObjetivo = (args) => {
    const [data, setData] = useState([])
    const [obj, setObj] = useState([])
    const [objTotal, setObjTotal] = useState(100)
    const [dataTotal, setDataTotal] = useState(0)
    useEffect(() => {
  
        if(args.ventasObj !== undefined){
            const obj = [60,60,60,80,100,120,140,160,180,200,220,240];
            const sumObj = obj.reduce((a, b) => a + b, 0)
            const sumaVentas = args.ventasObj.data.reduce((a, b) => a + b, 0)
       
            setData(args.ventasObj.data)
            setObj(obj)
            setObjTotal(sumObj)
            setDataTotal(sumaVentas)
            
        }

    }, [])
    



    return (
        <Card className="mt-3">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Ventas vs objetivo</h6>
            </Card.Header>
            <Card.Body>
                <Row className="g-1 align-items-center">
                    <Col xxl={9} xl={9}>
                        {
                            args.ventasObj.allData?<VentasVsObjetivoChart data={args.ventasObj.allData} />:<h6 className="mb-0 mt-2">Cargando</h6>
                        }
                    
                    </Col>
                    <Col xxl={3} xl={3}>
                    <CumplimientoChart data={[
                        { id: 1, value: 100-Math.round(dataTotal*100/objTotal), name: 'Objetivo', color: 'gray-300' },
                        { id: 2, value: Math.round(dataTotal*100/objTotal), name: 'Ventas', color: 'warning' }
                        ]} radius={['100%', '80%']}
                    />
                        <h6 className='text-center py-2 text-700 fw-normal'>
                            Cumplimiento Anual
                        </h6>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default VentasVsObjetivo;
