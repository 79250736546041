import { utilAPI } from "api";

export const useUtilStore = () => {
    
    const startufDelDia = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await utilAPI.post('/ufDelDia');
            return data

        } catch (error) {
            console.log("error", error)
            return error
        }

    }

    const startObtener_ipc = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await utilAPI.post('/obtener_ipc');
            return data

        } catch (error) {
            console.log("error", error)
            return error
        }

    }
    

    const startGetEmbajador = async (u_id) => {
        try {

            const { data } = await utilAPI.post('/getUsuarioEmbajador',{u_id});
            return data

        } catch (error) {
            console.log("error", error)
            return error
        }

    }
    return {
        //Propiedades


        //*Metodos
        startufDelDia,
        startGetEmbajador,
        startObtener_ipc
    }
}