import React from 'react';
import { Card, Col } from 'react-bootstrap';
import Flex from '../../../components/common/Flex';

const Produccion = ({ data }) => {

    const format = (number) => {
        const parts = number.toString().split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        const decimalPart = parts[1] ? ',' + parts[1] : '';
        return integerPart + decimalPart;
    }

    let colorTasaDeAnulacion = '';
    const tasaDeAnulacion = data.tasaDeAnulacion;
    if (tasaDeAnulacion <= 10) {
        colorTasaDeAnulacion = 'danger';
    } else if (tasaDeAnulacion >= 10.1 && tasaDeAnulacion <= 13.9) {
        colorTasaDeAnulacion = 'warning';
    } else if (tasaDeAnulacion >= 14 && tasaDeAnulacion <= 18) {
        colorTasaDeAnulacion = 'success';
    } else if (tasaDeAnulacion >= 18.1) {
        colorTasaDeAnulacion = 'primary'
    }

    let colortasaDeCancelacion = '';
    const tasaDeCancelacion = data.tasaDeCancelacion;
    if (tasaDeCancelacion <= 10) {
        colortasaDeCancelacion = 'danger';
    } else if (tasaDeCancelacion >= 10.1 && tasaDeCancelacion <= 13.9) {
        colortasaDeCancelacion = 'warning';
    } else if (tasaDeCancelacion >= 14 && tasaDeCancelacion <= 18) {
        colortasaDeCancelacion = 'success';
    } else if (tasaDeCancelacion >= 18.1) {
        colortasaDeCancelacion = 'primary'
    }

    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Producción</h6>
            </Card.Header>
            <Card.Body as={Flex} >
                <Col xl={6} className='pe-3'>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{
                            data.primaNetaVendida ? (format(data.primaNetaVendida.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{
                            data.primaNetaPromedio ? (format(data.primaNetaPromedio.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{
                            data.primaNetaAnulada ? (format(data.primaNetaAnulada.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 lh-1">
                       <span className={`text-${colorTasaDeAnulacion}`}>{

                            data.tasaDeAnulacion ? (format(data.tasaDeAnulacion.toFixed(2))) : 0
                        }%</span>
                    </h6>
                    <h6 className="mb-1 lh-1">
                        <span >UF&nbsp;{
                             data.primaNetaCancelada ? (format(data.primaNetaCancelada.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 lh-1">
                    <span className={`text-${colortasaDeCancelacion}`}>{
                             data.tasaDeCancelacion ? (format(data.tasaDeCancelacion.toFixed(2))) : 0
                        }&nbsp;%</span>
                    </h6>
                </Col>
                <Col xl={6}>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta vendida
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta promedio
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta anulada
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        tasa de anulación
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta cancelada
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        tasa neta cancelada
                    </h6>
                </Col>
            </Card.Body>
        </Card>
    );
}

export default Produccion;
