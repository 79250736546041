import React from 'react'
import { Col, Row, Card } from 'react-bootstrap';

const MontosKPI = ({title, data}) => {
    const format = (number) => {
        if (number !== null) {
            const parts = number.toString().split('.');
            const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            let decimalPart = parts[1] ? ',' + parts[1] : '';
    
            if (decimalPart.length > 1) {
                // Si la longitud del decimal es mayor a 1, redondea a un decimal
                decimalPart = ',' + parseFloat('0.' + parts[1]).toFixed(1).split('.')[1];
            }
    
            return integerPart + decimalPart;
        }
    }
  return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body>
                <Row className="g-0 justify-content-between">
                    <Col md="12" className="mb-3 mb-md-0 text-center">

                    <h3 className="titulosGraf text-primary ">{title}</h3>
                    </Col>
                    <Col md="6" className="mb-3 mb-md-0 cajaKPIGrisResalte ">
                    <Card className="br-1">
                         <Card.Body>
                        <Row className="p-3 justify-content-between">
                            <Col md="6" className="mb-3 mb-md-0">
                        {   data?data.map((item,key) =>(
                                <h6 key={key} className='p-22 text-primary'>{item.nombre}</h6>
                            )):''
                        }
                            
                            </Col>
                            <Col md="6" className="mb-3 mb-md-0">
                                
                                {   data?data.map((item,key) =>(
                                        <p key={key} className='p-22 text-primary'>${format(item.monto)}</p>
                                    )):''
                                }
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col md="6" className="cajaKPIGrisResalte p-1">
                    <Card className="br-1">
                         <Card.Body>
                    <h3 className="text-primary p-22">${data?format(data.reduce((acumulador, item) => { return acumulador + item.monto;}, 0)):''} </h3>
                        </Card.Body>
                    </Card>
                    </Col>

                </Row>
            </Card.Body>
        </Card>
  )
}

export default MontosKPI