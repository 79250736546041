import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Col, Row, Card } from 'react-bootstrap';
import { useKPISiniestroStore } from 'hooks/useKPISiniestroStore';
import { BsFileMedical } from "react-icons/bs";
import { useSelector } from 'react-redux';
import MontosKPI from './MontosKPI';
import { useKPIDesgravamenStore } from 'hooks/useKPIDesgravamenStore';
const GrafCircle = () => {
  // Opciones del gráfico
  const options = {

    plugins: {
      legend: {
        labels: {
          usePointStyle: true, // Utilizar iconos cuadrados en lugar de muestras predeterminadas
        },
        position: 'top', // Puedes ajustar la posición si es necesario
      },
    },
    layout: {
      padding: {
        top: 15, // Ajustar según necesidad
        bottom: 30, // Ajustar según necesidad
      },
    },
  };


  // Opciones del gráfico
  const options2 = {

    plugins: {
/*       title: {
        display: true,      // Esto activa la visualización del título
        text: 'INDICADORES ETAPA 3 - 4',  // Aquí pones el texto de tu título
        font: {
          size: 16          // Puedes ajustar el tamaño de la fuente aquí
        },
      }, */
      legend: {
        labels: {
          usePointStyle: true, // Utilizar iconos cuadrados en lugar de muestras predeterminadas
        },
        position: 'top', // Puedes ajustar la posición si es necesario
      },
    },
    layout: {
      padding: {
        top: 15, // Ajustar según necesidad
        bottom: 30, // Ajustar según necesidad
      },
    },
  };
  

  const [tipoPoliza, setTipoPoliza] = useState()
  const [grafCircle, setGrafCircle] = useState(() => ({
    labels: ['Antecedentes Entregados', 'Antecedentes Pendientes'],
    datasets: [
      {
        data: [300, 50],
        backgroundColor: ['#2c7be5', '#fac859','#e66','#3ba272'],
        hoverBackgroundColor: ['#2550d0', '#ffbc29','#e66','#3ba272'],
      },
    ],
  }));

  const [grafCircle2, setGrafCircle2] = useState(() => ({
    labels: ['Antecedentes Entregados', 'Antecedentes Pendientes'],
    datasets: [
      {
        data: [150, 80],
        backgroundColor: ['#2c7be5', '#fac859','#e66','#3ba272'],
        hoverBackgroundColor: ['#2550d0', '#ffbc29','#e66','#3ba272'],
      },
    ],
  }));

  const [grafCircle3, setGrafCircle3] = useState(() => ({
    labels: ['En Proceso', 'Pendiente de Antecedentes', 'Rechazado', 'Pagado'],
    datasets: [
      {
        data: [150, 80, 80, 80],
        backgroundColor: ['#2c7be5', '#fac859','#e66','#3ba272'],
        hoverBackgroundColor: ['#2550d0', '#ffbc29','#e66','#3ba272'],
      },
    ],
  }));

  const {
      startObtenerCircleGrafDesgravamen,
      startObtenerCircleGraf2Desgravamen,
      startDiasAVGDesgravamen,
      startDiasAVGDenunciaPagoDesgravamen,
      startDiasAVGPagoVBTDesgravamen,
      startDiasAVGBTCierreDesgravamen,
      startMontoIndemnizadoDesgravamen,
      startmontoAportadoDesgravamen
  } = useKPIDesgravamenStore();


  const [aVGSiniestro, setAVGSiniestro] = useState('')
  const [denunciaVsPago, setDenunciaVsPago] = useState('')
  const [pagoVsVBT, setPagoVsVBT] = useState('')
  const [VBTVsCierre, setVBTVsCierre] = useState('')
  const { dataInicio, dataFinal } = useSelector(state => state.datapicker);

  const [op_tipo_poliza, setOp_tipo_poliza] = useState()
  const [op_aseguradora, setOp_aseguradora] = useState()
  const [op_est_obtencion_ant, setOp_est_obtencion_ant] = useState()
  const [op_est_operacion, setOp_est_operacion] = useState()


  const [tiposPoliza, setTiposPoliza] = useState()
  const [aseguradoras, setAseguradoras] = useState()
  const [estadosObtencion, setEstadosObtencion] = useState()
  const [estadosOperacion, setEstadosOperacion] = useState()


  const [montoIndemnizado, setMontoIndemnizado] = useState()
  const [montoAportado, setMontoAportado] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dataTemp3, dataTemp4, dataTemp5] = await Promise.all([
          startDiasAVGDenunciaPagoDesgravamen(dataInicio, dataFinal),
          startDiasAVGPagoVBTDesgravamen(dataInicio, dataFinal),
          startDiasAVGBTCierreDesgravamen(dataInicio, dataFinal),
        ]);
  
        setDenunciaVsPago(dataTemp3.data);
        setPagoVsVBT(dataTemp4.data);
        setVBTVsCierre(dataTemp5.data);
  
        const [dataTemp6, dataTemp7] = await Promise.all([
          startMontoIndemnizadoDesgravamen(dataInicio, dataFinal),
          startmontoAportadoDesgravamen(dataInicio, dataFinal),
        ]);
  
        setMontoIndemnizado(dataTemp6?.data);
        setMontoAportado(dataTemp7?.data);
  
        const dataTemp = await startObtenerCircleGrafDesgravamen(dataInicio, dataFinal);
  
        const tiposPolizaLower = [...new Set(dataTemp.data.map(item => item.op_tipo_poliza.toLowerCase()))];
        const aseguradorasLower = [...new Set(dataTemp.data.map(item => item.op_aseguradora.toLowerCase()))];
        const estadosObtencionLower = [...new Set(dataTemp.data.map(item => item.op_est_obtencion_ant.toLowerCase()))];
        const estadosOperacionLower = [...new Set(dataTemp.data.map(item => item.op_est_operacion.toLowerCase()))];
  
        setTiposPoliza([...new Set([ ...tiposPolizaLower])]);
        setAseguradoras([...new Set([ ...aseguradorasLower])]);
        setEstadosObtencion([...new Set([ ...estadosObtencionLower])]);
        setEstadosOperacion([...new Set([ ...estadosOperacionLower])]);
  
/*         const pagadoData = dataTemp.data.find(item => item.op_est_operacion === 'PENDIENTE DE ANTECEDENTES');
        const procesoData = dataTemp.data.find(item => item.op_est_operacion === 'EN PROCESO'); */
        const labelsData = [...new Set(dataTemp.data.map(item=>{
          item.op_est_operacion=='PENDIENTE DE ANTECEDENTES'?item.op_est_operacion='P. Antecedentes':''
          return item.op_est_operacion.toLowerCase()

        }))];
        const cantidadesData = [...new Set(dataTemp.data.map(item => item.cantidad))];
        
        // Paso 1: Sumar todos los elementos del array
        const sumaTotal = cantidadesData.reduce((acc, numero) => acc + numero, 0);

        // Paso 2: Calcular el porcentaje para cada elemento
        const porcentajes = cantidadesData.map((numero) =>( (numero / sumaTotal) * 100).toFixed(1));

        
        setGrafCircle(prevState => ({
          labels:labelsData,
          datasets: [
            {
              ...prevState.datasets[0],
              data: porcentajes,
              dataCompleta: dataTemp.data,
            },
          ],
        }));
  
        const dataTemp2 = await startObtenerCircleGraf2Desgravamen(dataInicio, dataFinal);
  
        const tiposPoliza2Lower = [...new Set(dataTemp2.data.map(item => item.op_tipo_poliza.toLowerCase()))];
        const aseguradoras2Lower = [...new Set(dataTemp2.data.map(item => item.op_aseguradora.toLowerCase()))];
        const estadosObtencion2Lower = [...new Set(dataTemp2.data.map(item => item.op_est_obtencion_ant.toLowerCase()))];
        const estadosOperacion2Lower = [...new Set(dataTemp2.data.map(item => item.op_est_operacion.toLowerCase()))];
  
        setTiposPoliza([...new Set([...tiposPolizaLower, ...tiposPoliza2Lower])]);
        setAseguradoras([...new Set([...aseguradorasLower, ...aseguradoras2Lower])]);
        setEstadosObtencion([...new Set([...estadosObtencionLower, ...estadosObtencion2Lower])]);
        setEstadosOperacion([...new Set([...estadosOperacionLower, ...estadosOperacion2Lower])]);
  
/*         const pagadoData2 = dataTemp2.data.find(item => item.op_est_operacion === 'EN PROCESO');
        const procesoData2 = dataTemp2.data.find(item => item.op_est_operacion === 'Pendiente de Antecedentes');
   */
        const labelsData2 = [...new Set(dataTemp2.data.map(item=>{
          item.op_est_operacion.toLowerCase()=='pendiente de antecedentes'?item.op_est_operacion='P. Antecedentes':item.op_est_operacion=item.op_est_operacion.toLowerCase()
          return item.op_est_operacion

        }))];
        const cantidadesData2 = [...new Set(dataTemp2.data.map(item => item.cantidad))];
        setGrafCircle2(prevState => ({
          labels:labelsData2,
          datasets: [
            {
              ...prevState.datasets[0],
              data: cantidadesData2,
              dataCompleta: dataTemp2.data,
            },
          ],
        }));
  
        const arrayTemp = dataTemp2.data.map(item => item.cantidad);
  
        setGrafCircle3(prevState => ({
          labels:labelsData2,
          datasets: [
            {
              ...prevState.datasets[0],
              data: arrayTemp,
            },
          ],
        }));
  
        const tempavg = await startDiasAVGDesgravamen(dataInicio, dataFinal);
        setAVGSiniestro(tempavg.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [dataInicio, dataFinal]);
  
  







  
  const filtros = async(valor, tipoPoliza) => {



switch(tipoPoliza){
  case 'op_tipo_poliza':
      //Setteo Arrays
        const estadosFiltrados = ['EN PROCESO', 'PENDIENTE DE ANTECEDENTES'];

        const dataSelects = grafCircle.datasets[0].dataCompleta.filter((item) => {
/*           //Debugear


          console.log("Esto viene de la data",          item.op_tipo_poliza.toLowerCase(),
          item.op_aseguradora.toLowerCase(),
          item.op_est_obtencion_ant.toLowerCase(),
          item.op_est_operacion.toLowerCase())

          console.log("esto tiene el filtro",valor,
          op_aseguradora,
          op_est_obtencion_ant,
          op_est_operacion) */



          // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
          const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
          const aseguradoraItem = item.op_aseguradora.toLowerCase();
          const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
          const estOperacionItem = item.op_est_operacion.toLowerCase();
  
          // Comparar con los valores de los filtros (usando directamente los estados)
          return (valor ? tipoPolizaItem === valor.toLowerCase() : true) &&
                 (op_aseguradora ? aseguradoraItem === op_aseguradora.toLowerCase() : true) &&
                 (op_est_obtencion_ant ? estObtencionAntItem === op_est_obtencion_ant.toLowerCase() : true) &&
                 (op_est_operacion ? estOperacionItem === op_est_operacion.toLowerCase() : true);
      });
      
      console.log('filtrado : ',dataSelects);
      
        const dataCompletaFiltrada = dataSelects

        const dataFiltrada = dataCompletaFiltrada.map(item => item.cantidad);
        const labelsData = [...new Set(dataCompletaFiltrada.map(item => item.op_est_operacion.toLowerCase()))];

        // Actualizar el estado utilizando setGrafCircle
        setGrafCircle(prevState => ({
        labels:labelsData,
        datasets: [
        {
        ...prevState.datasets[0],
        data: dataFiltrada
        }
        ]
        }))

        // Filtrar dataCompleta para setGrafCircle2

        const dataSelects2 = grafCircle2.datasets[0].dataCompleta.filter((item) => {
          
                    // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
                    const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
                    const aseguradoraItem = item.op_aseguradora.toLowerCase();
                    const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
                    const estOperacionItem = item.op_est_operacion.toLowerCase();
            
                    // Comparar con los valores de los filtros (usando directamente los estados)
                    return (valor ? tipoPolizaItem === valor.toLowerCase() : true) &&
                           (op_aseguradora ? aseguradoraItem === op_aseguradora.toLowerCase() : true) &&
                           (op_est_obtencion_ant ? estObtencionAntItem === op_est_obtencion_ant.toLowerCase() : true) &&
                           (op_est_operacion ? estOperacionItem === op_est_operacion.toLowerCase() : true);
                });


        const dataCompletaFiltrada2 = dataSelects2

        // Mapear datos filtrados para setGrafCircle2
        const dataFiltrada2 = dataCompletaFiltrada2.map(item => item.cantidad);
        const labelsData2 = [...new Set(dataCompletaFiltrada2.map(item => item.op_est_operacion.toLowerCase()))];

        // Actualizar el estado utilizando setGrafCircle2
        setGrafCircle2(prevState => ({
        labels:labelsData2,
        datasets: [
        {
        ...prevState.datasets[0],
        data: dataFiltrada2
        }
        ]
        }));

        setGrafCircle3(prevState => ({
        labels:labelsData2,
        datasets: [
        {
        ...prevState.datasets[0],
        data: dataFiltrada2
        }
        ]
        }));


      //cambia el valor del usestate
      setOp_tipo_poliza(valor)

      const dataTemp2 = await 
      startDiasAVGDesgravamen(dataInicio, dataFinal,valor,
      op_aseguradora,
      op_est_obtencion_ant,
      op_est_operacion);

      setAVGSiniestro(dataTemp2.data);
      


      const dataTemp1_3 = await 
      startDiasAVGDenunciaPagoDesgravamen(dataInicio, dataFinal,valor,
        op_aseguradora,
        op_est_obtencion_ant,
        op_est_operacion);
  
  
      setDenunciaVsPago(dataTemp1_3.data);


      const dataTemp1_4 = await 
      startDiasAVGPagoVBTDesgravamen(dataInicio, dataFinal,valor,
        op_aseguradora,
        op_est_obtencion_ant,
        op_est_operacion);
  
    
      setPagoVsVBT(dataTemp1_4.data);


      const dataTemp1_5 = await 
      startDiasAVGBTCierreDesgravamen(dataInicio, dataFinal,valor,
        op_aseguradora,
        op_est_obtencion_ant,
        op_est_operacion);
  
    
      setVBTVsCierre(dataTemp1_5.data);

      const dataTemp1_6 = await 
      startMontoIndemnizadoDesgravamen(dataInicio, dataFinal,valor,
        op_aseguradora,
        op_est_obtencion_ant,
        op_est_operacion);
  
      const dataTemp1_7 = await 
      startmontoAportadoDesgravamen(dataInicio, dataFinal,valor,
        op_aseguradora,
        op_est_obtencion_ant,
        op_est_operacion);
  

        setMontoIndemnizado(dataTemp1_6?.data);
        setMontoAportado(dataTemp1_7?.data);
      
  break;

  case 'op_aseguradora':
          //Setteo Arrays
          const estadosFiltrados21 = ['EN PROCESO', 'PENDIENTE DE ANTECEDENTES'];

          const dataSelects21 = grafCircle.datasets[0].dataCompleta.filter((item) => {

              // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
              const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
              const aseguradoraItem = item.op_aseguradora.toLowerCase();
              const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
              const estOperacionItem = item.op_est_operacion.toLowerCase();
      
              // Comparar con los valores de los filtros (usando directamente los estados)
              return (op_tipo_poliza ? tipoPolizaItem === op_tipo_poliza.toLowerCase() : true) &&
                      (valor ? aseguradoraItem === valor.toLowerCase() : true) &&
                      (op_est_obtencion_ant ? estObtencionAntItem === op_est_obtencion_ant.toLowerCase() : true) &&
                      (op_est_operacion ? estOperacionItem === op_est_operacion.toLowerCase() : true);
          });

          const dataCompletaFiltrada21 = dataSelects21
  
          const dataFiltrada21 = dataCompletaFiltrada21.map(item => item.cantidad);
          const labelsData21 = [...new Set(dataCompletaFiltrada21.map(item => item.op_est_operacion.toLowerCase()))];
  
          // Actualizar el estado utilizando setGrafCircle
          setGrafCircle(prevState => ({
          labels:labelsData21,
          datasets: [
          {
          ...prevState.datasets[0],
          data: dataFiltrada21
          }
          ]
          }))
  
          // Filtrar dataCompleta para setGrafCircle2
          console.log("ladaaata",grafCircle2.datasets[0].dataCompleta)
          const dataSelects22 = grafCircle2.datasets[0].dataCompleta.filter((item) => {
          
            // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
            const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
            const aseguradoraItem = item.op_aseguradora.toLowerCase();
            const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
            const estOperacionItem = item.op_est_operacion.toLowerCase();
    
            // Comparar con los valores de los filtros (usando directamente los estados)
            return (op_tipo_poliza ? tipoPolizaItem === op_tipo_poliza.toLowerCase() : true) &&
                   (valor ? aseguradoraItem === valor.toLowerCase() : true) &&
                   (op_est_obtencion_ant ? estObtencionAntItem === op_est_obtencion_ant.toLowerCase() : true) &&
                   (op_est_operacion ? estOperacionItem === op_est_operacion.toLowerCase() : true);
        });



          const dataCompletaFiltrada22 = dataSelects22
  
          // Mapear datos filtrados para setGrafCircle2
          const dataFiltrada22 = dataCompletaFiltrada22.map(item => item.cantidad);
          const labelsData22 = [...new Set(dataCompletaFiltrada22.map(item => item.op_est_operacion.toLowerCase()))];
  
          // Actualizar el estado utilizando setGrafCircle2
          setGrafCircle2(prevState => ({
          labels:labelsData22,
          datasets: [
          {
          ...prevState.datasets[0],
          data: dataFiltrada22
          }
          ]
          }));
  
          setGrafCircle3(prevState => ({
          labels:labelsData22,
          datasets: [
          {
          ...prevState.datasets[0],
          data: dataFiltrada22
          }
          ]
          }));

    //cambia el valor del usestate
    setOp_aseguradora(valor)

    const dataTemp2_1 = await 
    startDiasAVGDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      valor,
    op_est_obtencion_ant,
    op_est_operacion);

    setAVGSiniestro(dataTemp2_1.data);


    const dataTemp2_2 = await 
    startDiasAVGDenunciaPagoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      valor,
    op_est_obtencion_ant,
    op_est_operacion);

    setDenunciaVsPago(dataTemp2_2.data);

    const dataTemp2_3 = await 
    startDiasAVGPagoVBTDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      valor,
    op_est_obtencion_ant,
    op_est_operacion);
  
    setPagoVsVBT(dataTemp2_3.data);


    const dataTemp2_4 = await 
    startDiasAVGBTCierreDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      valor,
    op_est_obtencion_ant,
    op_est_operacion);
  
    setVBTVsCierre(dataTemp2_4.data);

    const dataTemp2_5 = await 
    startMontoIndemnizadoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      valor,
    op_est_obtencion_ant,
    op_est_operacion);

    const dataTemp2_6 = await 
    startmontoAportadoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      valor,
    op_est_obtencion_ant,
    op_est_operacion);

      setMontoIndemnizado(dataTemp2_5?.data);
      setMontoAportado(dataTemp2_6?.data);
break;

  case 'op_est_obtencion_ant':
              //Setteo Arrays
              const estadosFiltrados31 = ['EN PROCESO', 'PENDIENTE DE ANTECEDENTES'];

              const dataSelects31 = grafCircle.datasets[0].dataCompleta.filter((item) => {

                // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
                const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
                const aseguradoraItem = item.op_aseguradora.toLowerCase();
                const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
                const estOperacionItem = item.op_est_operacion.toLowerCase();
        
                // Comparar con los valores de los filtros (usando directamente los estados)
                return (op_tipo_poliza ? tipoPolizaItem === op_tipo_poliza.toLowerCase() : true) &&
                        (op_aseguradora ? aseguradoraItem === op_aseguradora.toLowerCase() : true) &&
                        (valor ? estObtencionAntItem === valor.toLowerCase() : true) &&
                        (op_est_operacion ? estOperacionItem === op_est_operacion.toLowerCase() : true);
            });
  
            const dataCompletaFiltrada31 = dataSelects31
      
              const dataFiltrada31 = dataCompletaFiltrada31.map(item => item.cantidad);
              const labelsData31 = [...new Set(dataCompletaFiltrada31.map(item => item.op_est_operacion.toLowerCase()))];
      
              // Actualizar el estado utilizando setGrafCircle
              setGrafCircle(prevState => ({
              labels:labelsData31,
              datasets: [
              {
              ...prevState.datasets[0],
              data: dataFiltrada31
              }
              ]
              }))
              // Filtrar dataCompleta para setGrafCircle2
              const dataSelects32 = grafCircle2.datasets[0].dataCompleta.filter((item) => {
                        
                // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
                const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
                const aseguradoraItem = item.op_aseguradora.toLowerCase();
                const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
                const estOperacionItem = item.op_est_operacion.toLowerCase();

                // Comparar con los valores de los filtros (usando directamente los estados)
                return (op_tipo_poliza ? tipoPolizaItem === op_tipo_poliza.toLowerCase() : true) &&
                      (op_aseguradora ? aseguradoraItem === op_aseguradora.toLowerCase() : true) &&
                      (valor ? estObtencionAntItem === valor.toLowerCase() : true) &&
                      (op_est_operacion ? estOperacionItem === op_est_operacion.toLowerCase() : true);
              });



              const dataCompletaFiltrada32 = dataSelects32
      
              // Mapear datos filtrados para setGrafCircle2
              const dataFiltrada32 = dataCompletaFiltrada32.map(item => item.cantidad);
              const labelsData32 = [...new Set(dataCompletaFiltrada32.map(item => item.op_est_operacion.toLowerCase()))];
      
              // Actualizar el estado utilizando setGrafCircle2
              setGrafCircle2(prevState => ({
              labels:labelsData32,
              datasets: [
              {
              ...prevState.datasets[0],
              data: dataFiltrada32
              }
              ]
              }));
      
              setGrafCircle3(prevState => ({
              labels:labelsData32,
              datasets: [
              {
              ...prevState.datasets[0],
              data: dataFiltrada32
              }
              ]
              }));


    setOp_est_obtencion_ant(valor)

    const dataTemp3_2 = await 
    startDiasAVGDenunciaPagoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      valor,
    op_est_operacion);

    setDenunciaVsPago(dataTemp3_2.data);

    const dataTemp3_3 = await 
    startDiasAVGPagoVBTDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      valor,
    op_est_operacion);
  
    setPagoVsVBT(dataTemp3_3.data);


    const dataTemp3_4 = await 
    startDiasAVGBTCierreDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      valor,
    op_est_operacion);
  
    setVBTVsCierre(dataTemp3_4.data);

    const dataTemp3_5 = await 
    startMontoIndemnizadoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      valor,
    op_est_operacion);

    const dataTemp3_6 = await 
    startmontoAportadoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      valor,
    op_est_operacion);

      setMontoIndemnizado(dataTemp3_5?.data);
      setMontoAportado(dataTemp3_6?.data);
  break;

  case 'op_est_operacion':
    valor=='p. antecedentes'?valor='PENDIENTE DE ANTECEDENTES':''
                  //Setteo Arrays
                  const estadosFiltrados41 = ['EN PROCESO', 'PENDIENTE DE ANTECEDENTES'];

                  const dataSelects41 = grafCircle.datasets[0].dataCompleta.filter((item) => {

                    // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
                    const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
                    const aseguradoraItem = item.op_aseguradora.toLowerCase();
                    const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
                    const estOperacionItem = item.op_est_operacion.toLowerCase();
            
                    // Comparar con los valores de los filtros (usando directamente los estados)
                    return (op_tipo_poliza ? tipoPolizaItem === op_tipo_poliza.toLowerCase() : true) &&
                            (op_aseguradora ? aseguradoraItem === op_aseguradora.toLowerCase() : true) &&
                            (op_est_obtencion_ant ? estObtencionAntItem === op_est_obtencion_ant.toLowerCase() : true) &&
                            (valor ? estOperacionItem === valor.toLowerCase() : true);
                });
      
                const dataCompletaFiltrada41 = dataSelects41
          
                  const dataFiltrada41 = dataCompletaFiltrada41.map(item => item.cantidad);
                  const labelsData41 = [...new Set(dataCompletaFiltrada41.map(item => item.op_est_operacion.toLowerCase()))];
          
                  // Actualizar el estado utilizando setGrafCircle
                  setGrafCircle(prevState => ({
                  labels:labelsData41,
                  datasets: [
                  {
                  ...prevState.datasets[0],
                  data: dataFiltrada41
                  }
                  ]
                  }))
          
                  // Filtrar dataCompleta para setGrafCircle2
                  const dataSelects42 = grafCircle2.datasets[0].dataCompleta.filter((item) => {
                            
                    // Convertir a minúsculas para comparación insensible a mayúsculas/minúsculas
                    const tipoPolizaItem = item.op_tipo_poliza.toLowerCase();
                    const aseguradoraItem = item.op_aseguradora.toLowerCase();
                    const estObtencionAntItem = item.op_est_obtencion_ant.toLowerCase();
                    const estOperacionItem = item.op_est_operacion.toLowerCase();

                    // Comparar con los valores de los filtros (usando directamente los estados)
                    return (op_tipo_poliza ? tipoPolizaItem === op_tipo_poliza.toLowerCase() : true) &&
                          (op_aseguradora ? aseguradoraItem === op_aseguradora.toLowerCase() : true) &&
                          (op_est_obtencion_ant ? estObtencionAntItem === op_est_obtencion_ant.toLowerCase() : true) &&
                          (valor ? estOperacionItem === valor.toLowerCase() : true);
                  });



                  const dataCompletaFiltrada42 = dataSelects42
          
                  // Mapear datos filtrados para setGrafCircle2
                  const dataFiltrada42 = dataCompletaFiltrada42.map(item => item.cantidad);
                  const labelsData42 = [...new Set(dataCompletaFiltrada42.map(item => item.op_est_operacion.toLowerCase()))];
          
                  // Actualizar el estado utilizando setGrafCircle2
                  setGrafCircle2(prevState => ({
                  labels:labelsData42,
                  datasets: [
                  {
                  ...prevState.datasets[0],
                  data: dataFiltrada42
                  }
                  ]
                  }));
          
                  setGrafCircle3(prevState => ({
                  labels:labelsData42,
                  datasets: [
                  {
                  ...prevState.datasets[0],
                  data: dataFiltrada42
                  }
                  ]
                  }));

                  

    setOp_est_operacion(valor)

    const dataTemp4_2 = await 
    startDiasAVGDenunciaPagoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      op_est_obtencion_ant,
      valor);

    setDenunciaVsPago(dataTemp4_2.data);

    const dataTemp4_3 = await 
    startDiasAVGPagoVBTDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      op_est_obtencion_ant,
      valor);
  
    setPagoVsVBT(dataTemp4_3.data);


    const dataTemp4_4 = await 
    startDiasAVGBTCierreDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      op_est_obtencion_ant,
      valor);
  
    setVBTVsCierre(dataTemp4_4.data);

    const dataTemp4_5 = await 
    startMontoIndemnizadoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      op_est_obtencion_ant,
      valor);

    const dataTemp4_6 = await 
    startmontoAportadoDesgravamen(dataInicio, dataFinal, op_tipo_poliza, 
      op_aseguradora,
      op_est_obtencion_ant,
      valor);

      setMontoIndemnizado(dataTemp4_5?.data);
      setMontoAportado(dataTemp4_6?.data);
  break;
  }

  }


  return (
    <>
            <Card className="bg-100 shadow-none border ">
                <Card.Body>
                    <Row className="g-0 justify-content-between">
                        <Col xs={12} className="" style={{ display:'flex' }}>
                            <select value={op_tipo_poliza} onChange={(e) => filtros(e.target.value, 'op_tipo_poliza')} className="form-control" id="tipoPoliza" name="tipoPoliza">
                                    <option value="">Tipo de Póliza</option>
                                      {tiposPoliza?tiposPoliza.map((item, key) => (
                                        <option key={key} value={item}>{item}</option>
                                      )):''}
                            </select>
                            <select value={op_aseguradora} onChange={(e) => filtros(e.target.value, 'op_aseguradora')} className="form-control" id="tipoPoliza" name="tipoPoliza">
                                    <option value="">Aseguradora</option>
                                      {aseguradoras?aseguradoras.map((item, key) => (
                                        <option key={key} value={item}>{item}</option>
                                      )):''}
                            </select>
                            <select value={op_est_obtencion_ant} onChange={(e) => filtros(e.target.value, 'op_est_obtencion_ant')} className="form-control" id="tipoPoliza" name="tipoPoliza">
                                    <option value="">E° de Obtención de Antecedentes</option>
                                      {estadosObtencion?estadosObtencion.map((item, key) => (
                                        <option key={key} value={item}>{item}</option>
                                      )):''}
                            </select>
                            <select value={op_est_operacion} onChange={(e) => filtros(e.target.value, 'op_est_operacion')} className="form-control" id="tipoPoliza" name="tipoPoliza">
                                    <option value="">E° de la Operación</option>
                                      {estadosOperacion?estadosOperacion.map((item, key) => (
                                        <option key={key} value={item}>{item}</option>
                                      )):''}
                            </select>
                        </Col>
                        <Col xs={12} className="">
                            <h3 className="titulosGraf text-primary ">INDICADORES ETAPA 1 - 2</h3>
                        </Col>
                        
                        <Col xs={6} className="">
                          <h6 className="text-center"> CALIDAD DE INGRESOS</h6>
                        </Col>
                        <Col xs={6} className="">
                          <h6 className="text-center"> DENUNCIA A LA ASEGURADORA</h6>
                        </Col>
                        <Col xs={6} className="flexMagic">
                            {
                               grafCircle.datasets[0].data.length>0?<Doughnut data={grafCircle} className='sizeDougGraf' options={options}  />:<p>no hay data</p>
                            }
                            
                        </Col>
                        
                        <Col xs={6} className="flexMagic">
                            {
                               grafCircle2.datasets[0].data.length>0?<Doughnut data={grafCircle2} className='sizeDougGraf' options={options}  />:<p>no hay data</p>
                            }
                        </Col>

                        <hr />
                        
                        <Col xs={6} className="">
                         
                          <p className='siniestroTEXT'>DÍAS PROMEDIO ENTRE INGRESO DEL SINIESTRO Y DENUNCIA EN LA ASEGURADORA</p>
                          <div className='iconBox'><BsFileMedical className='iconSvg'/></div>
                          <small>(considerando que los casos tardan más de un día)</small>
                          
                          

                        
                        </Col>
                        <Col xs={6} className="siniestroAVG p-3">
                         <p >
                         {aVGSiniestro.resultado?parseFloat(aVGSiniestro.resultado).toFixed(1):'0.0' } 
                         </p>
                  
                       
                       </Col>
                        <hr />


                        <Col xs={12} className="">
                            <h3 className="titulosGraf text-primary ">INDICADORES ETAPA 3 - 4</h3>
                        </Col>
                        
                        <Col xs={6} className="flexMagic">
               
                
                        {
                               grafCircle3.datasets[0].data.length>0?<Doughnut data={grafCircle3} className=' sizeDougGraf' options={options2}  />:<p>no hay data</p>
                            }
                    


                        </Col>
                        <Col xs={6} className="siniestroAVG Grid">
                        <h3 className="titulosGraf text-primary ">DENUNCIA A LA ASEGURADORA</h3>
                        <h6 className='m-2'>DÍAS PROMEDIO ENTRE DENUNCIA EN LA ASEGURADORA Y PAGO O RECHAZO</h6>
                         <p className='cajaGraf'>
                         {denunciaVsPago.resultado!==null?parseFloat(denunciaVsPago.resultado).toFixed(1):'0.0' } 
                         </p>
                        <h6 className='m-2'>DÍAS PROMEDIO ENTRE PAGO/RECHAZO Y VB TESORERÍA MC</h6>
                        <p className='cajaGraf'>
                         {pagoVsVBT.resultado!==null?parseFloat(pagoVsVBT.resultado).toFixed(1):'0.0' } 
                         </p>
                          <h6 className='m-2'>DÍAS PROMEDIO ENTRE VB TESORERÍA Y CIERRE (MC)</h6>
                          <p className='cajaGraf'>
                         {VBTVsCierre.resultado!==null?parseFloat(VBTVsCierre.resultado).toFixed(1):'0.0' } 
                         </p>
                  
                       
                   
                        </Col>



                    </Row>
                </Card.Body>
            </Card>




            <hr/>
                <Col xxl={12} xl={12}>
                   {montoIndemnizado? <MontosKPI data={montoIndemnizado} title={'MONTO TOTAL INDEMNIZADO A LA FECHA'} />:''}
                </Col>

                <Col xxl={12} xl={12}>
                    {montoAportado?<MontosKPI data={montoAportado} title={'MONTO TOTAL APORTADO POR CLIENTE'}/>:''}
                </Col>
                <hr/>
                    

        
    </> 
    
  )
}

export default GrafCircle