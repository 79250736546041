import { gestionAPI } from "api";
import { useSelector, useDispatch } from "react-redux";

export const useGestionStore = () => {

    const dispatch = useDispatch();

    const startObtenerCotizaciones = async (dataInicio, dataFinal, name) => {
        try {
            const { data } = await gestionAPI.post('/obtenerTabla', name? { dataInicio, dataFinal, name } :{ dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGeneralVariacion = async (fechaDesde, fechaHasta, userName) => {
        try {
            const requestData = userName ? (userName === "Supervisor" ? { fechaDesde, fechaHasta, userName, 'u_rol': '4' } : { fechaDesde, fechaHasta, userName }) : { fechaDesde, fechaHasta };
            
            const { data } = await gestionAPI.post('/rendimientoAuto', requestData);
            return data.data;
        } catch (error) {
            console.log(error.data);
            
        }
    }
    const startGeneralVariacionFV = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await gestionAPI.post('/rendimientoAutoFV',  { fechaDesde, fechaHasta } );
            return data.data;
        } catch (error) {
            console.log(error.data);
            
        }
    }
    

    const startEjecutivosList = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await gestionAPI.post('/ejecutivoList');
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startBitacora_status = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await gestionAPI.post('/bitacora_status');
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startBitacora_tipo_cotizante = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await gestionAPI.post('/bitacora_tipo_cotizante');
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startObterner_bitacora = async (cotiza_id) => {
        try {

            const { data } = await gestionAPI.post('/obterner_bitacora',{cotiza_id});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startObtener_ofertas = async (cotiza_id) => {
        try {

            const { data } = await gestionAPI.post('/obtener_ofertas',{cotiza_id});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startObtenerAuto = async (cotiza_auto) => {
        try {

            const { data } = await gestionAPI.post('/obtener_auto',{cotiza_auto});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startGuarfarBitacora = async (bitacora_cotiza_id,
        bitacora_reagendado,
        bitacora_tipo_id,
        bitacora_status_id,
        bitacora_observacion) => {
        try {

            const { data } = await gestionAPI.put('/ingresarBitcacora',{bitacora_cotiza_id,
                bitacora_reagendado,
                bitacora_tipo_id,
                bitacora_status_id,
                bitacora_observacion});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startObtenerDatos = async (cotiza_id) => {
        try {

            const { data } = await gestionAPI.post('/obtenerDatos',{cotiza_id});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startModificarEjecutivo = async (cotiza_id, cotiza_ejec_id,  cotiza_responsable) => {
        try {

            const { data } = await gestionAPI.put('/modificarEjecutivo',{cotiza_id, cotiza_ejec_id,  cotiza_responsable});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }


    const startModificarPagos= async (emision_id, pago_rut, pago_nombre, pago_paterno,
        pago_materno,
        pago_tipo,
        pago_banco,
        pago_cuotas,
        pago_dia,
        pago_prim_venc,
        pago_tarjeta_tipo,
        pago_tarjeta_vence,
        pago_cuenta_nro,
        pago_correo) => {
        try {

            const { data } = await gestionAPI.post('/editarPago',{emision_id, pago_rut, pago_nombre,
                pago_paterno,
                pago_materno,
                pago_tipo,
                pago_banco,
                pago_cuotas,
                pago_dia,
                pago_prim_venc,
                pago_tarjeta_tipo,
                pago_tarjeta_vence,
                pago_cuenta_nro,
                pago_correo});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startObtenerBancosPago = async () => {
        try {

            const { data } = await gestionAPI.post('/obtenerBancosPago',);
            return data

        } catch (error) {
            console.log("error", error)
        }
    }


    const startDarDeBaja = async (emision_id, detalle, motivo, usuario, fecha) => {
        try {
            // Crea un objeto con los datos a enviar
            let datosAEnviar = {
                emision_id,
                detalle,
                motivo,
                usuario
            };
            
            // Si fecha tiene un valor que no es vacío, agrégalo al objeto
            if (fecha) {
                datosAEnviar.fecha = fecha;
            }
    
            const { data } = await gestionAPI.post('/darDeBaja', datosAEnviar);
            return data;
    
        } catch (error) {
            console.log("error", error);
        }
    }
    


    const startObtenerDocumentosEmision = async (emision_id) => {
        try {

            const { data } = await gestionAPI.post('/obtenerDocumentosEmision',{emision_id});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startInsertDocumentosEmision = async (poliza_emision,
        cotizacion_emision,
        oferta_cotizacion,
        emision_cotizacion,
        tipo_documento,
        url) => {
        try {

            const { data } = await gestionAPI.post('/insertDocumentosEmision',{poliza_emision,
                cotizacion_emision,
                oferta_cotizacion,
                emision_cotizacion,
                tipo_documento,
                url});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }


    const startObtenerInspecciones = async (dataInicio, dataFinal, userName) => {
        try {
            const requestData = userName ? (userName === "Supervisor" ? { dataInicio, dataFinal, userName, 'u_rol': '4' } : { dataInicio, dataFinal, userName }) : { dataInicio, dataFinal };
            const { data } = await gestionAPI.post('/obtenerTablaInspeccion', requestData);
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startMetricasInspeccion = async (dataInicio, dataFinal, userName) => {
        try {
            const requestData = userName ? (userName === "Supervisor" ? { dataInicio, dataFinal, userName, 'u_rol': '4' } : { dataInicio, dataFinal, userName }) : { dataInicio, dataFinal };
            const { data } = await gestionAPI.post('/obtenerMetricasInspeccion',requestData);
            return data

        } catch (error) {
            console.log("error", error)
        }
    }
    
    
    
    const startSLAseguradoras = async (dataInicio, dataFinal) => {
        try {
            const { data } = await gestionAPI.post('/SLAseguradoras',{dataInicio, dataFinal});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }
    const startSLAseguradorasEmi = async (dataInicio, dataFinal) => {
        try {
            const { data } = await gestionAPI.post('/SLAseguradorasEmi',{dataInicio, dataFinal});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }
    
    const startSLAseguradorasIns = async (dataInicio, dataFinal) => {
        try {
            const { data } = await gestionAPI.post('/SLAseguradorasIns',{dataInicio, dataFinal});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startBuscarPoliza = async (nPoliza) => {
        try {
            const { data } = await gestionAPI.post('/buscarPoliza',{nPoliza});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    const startInfoPlanes = async (cotiza_id) => {
        try {
            const { data } = await gestionAPI.post('/infoPlanes',{cotiza_id});
            return data

        } catch (error) {
            console.log("error", error)
        }
    }

    
    
    
    

    
    return {
        //Propiedades


        //*Metodos
        startObtenerCotizaciones,
        startGeneralVariacion,
        startEjecutivosList,
        startBitacora_status,
        startBitacora_tipo_cotizante,
        startObterner_bitacora,
        startObtener_ofertas,
        startObtenerAuto,
        startGuarfarBitacora,
        startObtenerDatos,
        startModificarEjecutivo,
        startGeneralVariacionFV,
        startModificarPagos,
        startObtenerBancosPago,
        startDarDeBaja,
        startObtenerDocumentosEmision,
        startInsertDocumentosEmision,
        
        


        startObtenerInspecciones,
        startMetricasInspeccion,
        startSLAseguradoras,
        startSLAseguradorasEmi,
        startSLAseguradorasIns,
        startBuscarPoliza,
        startInfoPlanes
    }
}