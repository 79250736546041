import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Modal, Form, Card } from 'react-bootstrap';
import ecomBg from 'assets/img/illustrations/bg-wave.png';
import Background from '../../components/common/Background';
import GreetingCard from 'components/dashboards/fuerza-de-venta/GreetingCard';
import TablaUsuarios from 'components/usuarios/TablaUsuarios';
import { useSelector } from 'react-redux';
import { useUsuariosStore } from 'hooks/useUsuariosStore';
import Swal from 'sweetalert2';


const Usuarios = () => {
    const { user } = useSelector(state => state.login);

    const [u_nombre, setU_nombre] = useState('')
    const [u_usuario, setU_usuario] = useState('')
    const [u_email, setU_email] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [userRole, setUserRole] = useState('')
    const [ejec_eq, setEjec_eq] = useState('')
    const [show, setShow] = useState(false)
    const [onHide, setOnHide] = useState(false)
    const [dataRoles, setDataRoles] = useState([]);
    const [reload, setReload] = useState(false)
    const [nUsuario, setNUsuario] = useState()
    const {
        startGetRoles,
        startGetNUsuario,
        startCrearUsuarioYejecutivo
    } = useUsuariosStore();
    useEffect(async() => {
      
        
        let dataTemp = await startGetRoles()
        if(dataTemp){
            setDataRoles(dataTemp.data)
        }

        let dataTemp2 = await startGetNUsuario()
        if(dataTemp2){
       
            setNUsuario(dataTemp2.data[0].NUsuario)
            setU_usuario(dataTemp2.data[0].NUsuario)
        }

    }, [])

    const crearUsuario = async() => {
        if (u_nombre !== '' && u_usuario !== '' && u_email !== '' && password !== '' && ejec_eq !== '' && userRole !== '') {
            if(password === confirmPassword){
                let resp = await startCrearUsuarioYejecutivo(u_nombre, u_usuario, u_email, password, ejec_eq, userRole);
                if (resp) {
                    Swal.fire("Creado", "Usuario creado correctamente", "success");
                    setReload(prev => !prev);
                    setShow(false)
                } else {
                    // Aquí podrías manejar un error específico devuelto por startCrearUsuarioYejecutivo si fuera necesario
                    Swal.fire("Error", "No se pudo crear el usuario.", "error");
                }
            }else{
                Swal.fire("Ops", "Las claves no coinciden", "info");
            }
        } else {
            Swal.fire("Ops", "Todos los campos deben estar llenos.", "warning");
        }
        
        
    }

    const codigoEjecutivo = async(e) =>{
        setU_nombre(e)
        setU_usuario((e.split(' ').length > 1 ? e.split(' ')[0].slice(0, 1).toUpperCase() + e.split(' ')[1].slice(0, 1).toUpperCase() : e.slice(0, 2).toUpperCase()) + nUsuario) 
    }


    return (
        <>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <Row className="g-3">
                        <Col xs={6}>
                            <GreetingCard nombre={user.name} />
                        </Col>
                        <Col xs={6}>
                        <Card className="bg-transparent-50 overflow-hidden h-100">
      <Card.Header className="position-relative">
        <Background
          image={ecomBg}
          className="d-none d-md-block bg-card"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1'
          }}
        />
        <div className="position-relative py-3">
          <div className='text-center'>
            <Button  onClick={() => setShow(true)}>Crear Usuario</Button>
          </div>
        </div>
      </Card.Header>
    </Card>
                            
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                <Col md={12}>{
                                    dataRoles?<TablaUsuarios reload={reload} dataRoles={dataRoles} />:''
                                }
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
            show={show} onHide={()=> setShow(false)} size="lg"
            aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Crear Usuario
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
            <Row className="p-2 mb-1">
            <Col xs={4} className="p-2 ">
                        <Form.Group controlId="formAliasUsuario">
                            <Form.Label>Codigo Ejecutivo:</Form.Label>
                            <Form.Control
                                disabled
                                type="text"
                                name="u_usuario"
                                value={u_usuario}
                                ></Form.Control>
                        </Form.Group>
                </Col>

                <Col xs={4} className="p-2 ">
                    <Form.Group controlId="formNombreUsuario">
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Control
                                type="text"
                                name="u_nombre"
                                value={u_nombre}
                                onChange={(e) => codigoEjecutivo(e.target.value)}></Form.Control>
                        </Form.Group>
                </Col>

                

                    <Col xs={4} className="p-2 ">
                        <Form.Group controlId="formEmailUsuario">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="text"
                                name="u_email"
                                value={u_email}
                                onChange={(e) => setU_email(e.target.value)}></Form.Control>
                        </Form.Group>
                    </Col>
                    <hr />
                    <Col xs={6} className="p-2 ">
                        <Form.Group controlId="formPassword">
                            <Form.Label>Clave:</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="p-2 ">
                        <Form.Group controlId="formConfirmPassword">
                            <Form.Label>Confirmar clave</Form.Label>
                            <Form.Control
                                type="password"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="new-password"></Form.Control>
                        </Form.Group>
                    </Col>
                    <hr />
                    <Col xs={12} className="p-2 ">
                        <Form.Group controlId="formUserRole">
                            <Form.Label>Rol de Usuario:</Form.Label>
                            <Form.Control
                                as="select"
                                name="userRole"
                                value={userRole}
                                onChange={(e) => setUserRole(e.target.value)}>
                                    <option value={''}>Seleccione Rol</option>
                                {dataRoles?.map((role) => (
                                    <option key={role.id} value={role.id}>
                                        {role.descripcion}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="p-2 ">
                        <Form.Group controlId="Equipo">
                            <Form.Label>Equipo:</Form.Label>
                            <Form.Control
                                type="text"
                                name="ejec_eq"
                                value={ejec_eq}
                                onChange={(e) => setEjec_eq(e.target.value)}
                                autoComplete="Equipo"></Form.Control>
                        </Form.Group>
                    </Col>
                
                </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => crearUsuario()}>
                    Crear
                </Button>
                <Button variant="secondary" onClick={()=> setShow(false)}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};


export default Usuarios