import React from 'react';
import { Card } from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import CumplimientoChart from './CumplimimientoChart';

const Rendimiento = ({ data }) => {

    let conversionVenta = 0
    if (data.ventas !== 0 && data.cotizaciones !== 0) {
        conversionVenta = (parseInt(data.ventas) * 100 / parseInt(data.cotizaciones)).toFixed(1);
    }
    let color = '';
    if (conversionVenta < 5.1) {
        color = 'danger';
    } else if (conversionVenta > 5 && conversionVenta < 7) {
        color = 'warning';
    } else if (conversionVenta >= 7 && conversionVenta < 8.5) {
        color = 'success';
    } else if (conversionVenta >= 8.5) {
        color = 'primary'
    }

    return (
        <Card className="h-md-100">
            <Card.Body as={Flex} className='align-items-start justify-content-between'>
                <div className="mt-4">
                    <h6 className="mb-1 text-700 fw-normal lh-1">{data.ventas}&nbsp;Ventas</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">{data.cotizaciones}&nbsp;Cotizaciones</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1 mr-1">{(data.ventas*100/data.cotizaciones).toFixed(1)}%&nbsp;Conversión a venta</h6>
                </div>
                <div>
                <h6 className="mb-1 text-center ">
                        Rendimiento
                    </h6>
                <CumplimientoChart size="7rem" fontsize="25" data={[
                        { id: 1, value: 100-(data.ventas*100/data.cotizaciones).toFixed(1), name: 'Objetivo', color:'gray-300' },
                        { id: 2, value: (data.ventas*100/data.cotizaciones).toFixed(1), name: 'Ventas', color: 'success' }
                        ]} radius={['100%', '80%']}
                    />

                </div>
            </Card.Body>
        </Card>
    );
};

export default Rendimiento;
