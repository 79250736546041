import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table, Container } from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { BsChevronBarExpand, BsChevronBarDown, BsChevronBarUp } from "react-icons/bs";

const TablaReact = ({ columns, data, size = 'sm', Ncolum = 10, onRadioChange }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: Ncolum }, // Initial page index and page size
    },
    useGlobalFilter,
    useSortBy, // Habilita la ordenación
    usePagination
  );

  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleRadioChange = (rowId) => {
    setSelectedRadio(rowId);
    // Call the callback function with the selected value
    onRadioChange(rowId);
  };


  return (
    <Row className='tablaCaja'>
      <Container fluid>
        <Row>
          <Col xs='auto'>
            <Form.Label htmlFor='inlineFormInputGroup' visuallyHidden>
              Username
            </Form.Label>
            <InputGroup className='mb-2'>
              <InputGroup.Text>🔎</InputGroup.Text>
              <Form.Control
                id='inlineFormInputGroup'
                placeholder='Buscar...'
                value={globalFilter || ''}
                onChange={(e) => {
                  setGlobalFilter(e.target.value || undefined);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Table
          
            responsive
            hover
            size={size}
            className='compact-table'
            {...getTableProps()}
            style={{ tableLayout: 'auto', maxWidth: '100%' }}
          >
            <thead className='headerTabla'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())} // Agrega las propiedades de ordenación
                      style={{ whiteSpace: 'nowrap' }} // Evita que el texto se divida en varias líneas
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ?  <BsChevronBarUp className='ml-05' /> : <BsChevronBarDown className='ml-05' />) :  <BsChevronBarExpand className='ml-05' />}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} data-jau={cell.column.textTransform} style={cell.column.width !== 150? { width: cell.column.width, display: 'block', textTransform: cell.column.textTransform?cell.column.textTransform:'capitalize' } : { textTransform: cell.column.textTransform?cell.column.textTransform:'capitalize' }}>
                        {cell.column.id === 'Seleccione' ? (
                          <div className='d-grid text-center'>
                            <Form.Check
                              type={'radio'}
                              name={'radioGroup'}
                              value={row.original.ofcotiza_id}
                              checked={selectedRadio === row.original.ofcotiza_id}
                              onChange={() => handleRadioChange(row.original.ofcotiza_id)}
                            />
                          </div>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <Row className='btnTabla'>
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Anterior
              </Button>
            </Row>
            <Row>
              <span>
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageCount}
                </strong>{' '}
              </span>
            </Row>
            <Row className='btnTabla'>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Siguiente
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default TablaReact;
