import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from '../../../../helpers/utils';
import React from 'react';
import moment from 'moment';
import 'moment/locale/es'; // Importar el idioma español


echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  BarChart
]);

const tooltipFormatter = params => {

  return `<div class="card">
              <div class="card-header bg-light py-2">
                <h6 class="text-600 mb-0">${params[0].axisValue}</h6>
              </div>
            <div class="card-body py-2">
              <h6 class="text-600 fw-normal">
                <span class="dot me-1 d-inline-block bg-primary"></span>
                ${params[0].seriesName}:
                <span class="fw-medium">${params[0].data}</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-warning"></span>
                ${params[1].seriesName}:
                <span class="fw-medium">${params[1].data}</span>
              </h6>
            </div>
          </div>`;
          
};

const getOptions = (data1, data2, data3, data4) => ({
  color: [getColor('primary'), getColor('warning'), getColor('success')],
  tooltip: {
    trigger: 'axis',
    padding: 0,
    backgroundColor: 'transparent',
    borderWidth: 0,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: tooltipFormatter
  },
  xAxis: {
    type: 'category',
    data: data3.map(month => dayjs(month).format('YYYY-MM-DD')),
    axisLabel: {
      color: getColor('gray-600'),
      formatter: value => moment(value).locale('es').format('MMM YYYY'),
      align: 'left',
      fontSize: 11,
      padding: [0, 0, 0, -25],
      showMaxLabel: true
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    boundaryGap: false
  },
  yAxis: {
    type: 'value',
    name: '',
    axisLabel: {
      color: getColor('gray-600'),
      formatter: value => `${value}`,
      fontSize: 11,
      padding: [0, 20, 0, 0]
    },
    splitLine: {
      lineStyle: {
        color: getColor('gray-200'),
        type: 'dashed'
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    }
  },
  legend: {
    textStyle: {
      color: getColor('gray-600'),
      fontSize: 11
    },
    padding: [0, 0, 0, 0],
    itemGap: 20
  },
  series: [
    {
      name: data4[0],
      type: 'bar',
      data: data1,
      barWidth: '70%',
      itemStyle: {
        borderRadius: 5
      }
    },
    {
      name: data4[1],
      type: 'line',
      data: data2,
      yAxisIndex: 0,
    }
  ],
  grid: {
    top: 30,
    right:30,
    bottom: 20,
    left: 50
  }
});



const RevenueChart = ( data ) => {
  const data1 = data.data.map((item) => {
    return parseInt(item.total_ventas);
  });

  const data2 = data.data.map((item) => {
    return parseInt(item.objetivo);
  });

  const data3 = data.data.map((item) => {
    return item.fecha;
  });

  const data4 = data.tipo === undefined? ["Venta","Objetivo"] : ["Ventas "+moment().format('yyyy'),"Ventas " +moment().subtract(12, 'months').format('yyyy')]


  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(data1, data2, data3, data4)}
      style={{ height: '20rem' }}
    />
  );
};

export default RevenueChart;