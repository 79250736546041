import Flex from '../../../common/Flex';
import { Card, Col, Spinner, Table } from 'react-bootstrap';
import SoftBadge from '../../../common/SoftBadge';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';

const MembersRow = ({
    u_nombre,
    num_emisiones,
    num_cotizaciones,
    num_emisiones_uf,
    ufActual,
    emision_comision
}) => {
    let itemPercentage = 0
    if(num_emisiones !== 0 && num_cotizaciones !== 0){
        itemPercentage = (num_emisiones * 100 / num_cotizaciones).toFixed(1);
    }
    
    let color = 'danger';

        if (itemPercentage < 5) {
          color = 'danger';
        } else if (itemPercentage >= 5 && itemPercentage <= 6.9) {
          color = 'warning';
        } else if (itemPercentage >= 7 && itemPercentage <= 8.4) {
          color = 'success';
        }else if (itemPercentage >= 8.5) {
          color = 'primary';
        }
   
    return (
        
        <tr className='border-bottom border-200'>
            <td>
                <Flex alignItems="center" className="position-relative">
                    <div className="ms-3">
                        <h6 className="mb-0 fw-semi-bold">{u_nombre}</h6>
                    </div>
                </Flex>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <SoftBadge pill bg={color}>
                    {itemPercentage}%
                </SoftBadge>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <p className="fs--2 mb-0">{num_emisiones}</p>
                <p className="fs--2 mb-0">{ ufActual!==undefined && ufActual!==null?'$'+formatNumber(formatoMonedaChilena(num_emisiones_uf*quitarPuntos(ufActual))):''}</p>
                
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <p className="fs--2 mb-0">{ formatoMonedaChilena(Math.round(num_emisiones_uf))} UF</p>
                <p className="fs--2 mb-0">{ ufActual!==undefined && ufActual!==null?formatNumber(formatoMonedaChilena(emision_comision*quitarPuntos(ufActual))):''  } </p>
                
                
            </td>
        </tr>
    );
};
const quitarPuntos = (numero) => {

    if(numero !== undefined && numero !== null){
        return parseInt(numero.replace(/\./g, ""));
    }
    
}
 const formatoMonedaChilena = (numero) => {
    const opciones = { 
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2, 
      useGrouping: true 
    };
    const moneda = new Intl.NumberFormat('es-CL', opciones);
    return moneda.format(numero);
  };

  const formatNumber = (number) => {
    if (number !== undefined && number !== null) {

      const num = parseFloat(number.replace(/\./g, "").replace(",", "."));
      const suffixes = ["", "K", "M", "B", "T", "q", "Q", "s", "S", "o", "n", "d", "U", "D", "T", "qD", "qT", "sD", "sT", "oD", "oT", "nD", "nT", "v"];
      const suffixNum = Math.floor(("" + num).split(".")[0].length / 3);
      let shortNum = parseFloat((suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toFixed(1));
      if (shortNum % 1 !== 0) {
        shortNum = shortNum.toFixed(1).replace(/\.0$/, "");
      }

      return shortNum +' '+suffixes[suffixNum];
    }
  };
  
  
  

  
const RankingSimple = ( data ) => {
    if (data.data.length===0) {
        return <Col xxl={12} xl={12} className="text-center"><Spinner variant="primary" size='xl' animation="border" role="status">
 
      </Spinner></Col>
      ;
      }
    return (
        <Card className="h-100">
            <Card.Header className="pb-0">
                <h6 className="mb-5 mt-2">Ranking {data.nombre}</h6>
            </Card.Header>
            <Card.Body className="p-0">
                <SimpleBarReact>
                    <Table
                        borderless
                        className="mb-0 fs--1 border-200 rounded-3 table-dashboard"
                    >
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th>Nombre</th>
                                <th className="text-center">Rendimiento</th>
                                <th className="text-center">Ventas</th>
                                <th className="text-center">Ingresos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data?.map((ejecutivo, index) => (
                                <MembersRow key={index}
                                    {...ejecutivo}
                                    ufActual={data.ufActual}
                                    isLast={index === data.length - 1}
                                />
                            ))}
                        </tbody>
                    </Table>
                </SimpleBarReact>
            </Card.Body>
        </Card>
    );
};


export default RankingSimple;
