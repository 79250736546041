import Flex from '../../../components/common/Flex';
import { Card, Table } from 'react-bootstrap';
import SoftBadge from '../../../components/common/SoftBadge';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { useRenovaciones } from 'hooks';
import { useEffect, useState } from 'react';
import moment from 'moment';

const MembersRow = ({
    nombre,
    rendimiento,
    ventas,
    nombreTabla
}) => {
    let itemPercentage = 0
    if(rendimiento !== 0 && ventas !== 0 && rendimiento !== undefined){
        itemPercentage =rendimiento.toFixed(1);
    }
    
    let color = 'danger';
        if(nombreTabla === "Anulacion"){
            if (itemPercentage < 10) {
                color = 'success';
              } else if (itemPercentage >= 10 && itemPercentage <= 14) {
                color = 'warning';
              } else if (itemPercentage >= 15) {
                color = 'danger';
              }
        }else{
            if (itemPercentage < 15) {
                color = 'success';
              } else if (itemPercentage >= 15 && itemPercentage <= 24) {
                color = 'warning';
              } else if (itemPercentage >= 25) {
                color = 'danger';
              }
        }
        
    return (
        
        <tr className='border-bottom border-200'>
            <td>
                <Flex alignItems="center" className="position-relative">
                    <div className="ms-3">
                        <h6 className="mb-0 fw-semi-bold">{nombre}</h6>
                    </div>
                </Flex>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <SoftBadge pill bg={color}>
                    {itemPercentage}%
                </SoftBadge>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <p className="fs--2 mb-0">{ventas}</p>
            </td>
        </tr>
    );
};

  
const TablaVentas = ( args ) => {
    const [ejecutivoSeleccionado, setEjecutivoSeleccionado] = useState(moment().format("M"));

    const [data, setData] = useState([])

    useEffect(() => {
        setData(args);
     
      }, [args]);
      
    
    // Crear un array de objetos con los nombres de los meses
    const meses = [];

    for (let i = 0; i < 12; i++) {
    const mes = moment().month(i);
    meses.push({
        id: i + 1,
        nombre: mes.format('MMMM').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    });
    }

    const {
        startRankingAnulaciones,
        startRankingCancelaciones
    } = useRenovaciones();
    
    const handleChange = async(event) => {
        if(args.nombre === "Anulacion"){
            let selectedValue = event.target.value;
            setEjecutivoSeleccionado(selectedValue);
            let nuevoRanking = await startRankingAnulaciones(selectedValue)
   
            setData({
                data:nuevoRanking,
                nombre:args.nombre
            })
        }else{
            let selectedValue = event.target.value;
            setEjecutivoSeleccionado(selectedValue);
            let nuevoRanking = await startRankingCancelaciones(selectedValue)
            setData({
                data:nuevoRanking,
                nombre:args.nombre
            })
        }

    };
    return (
        <Card className="h-100">
            <Card.Header className="pb-0">
                <h6 className="mb-5 mt-2">{data.nombre}</h6>
                <select value={ejecutivoSeleccionado} onChange={handleChange}>
                    {meses.map((ejecutivo) => (
                    <option key={ejecutivo.id} value={ejecutivo.id}>
                        {ejecutivo.nombre}
                    </option>
                    ))}
                </select>
            </Card.Header>
            <Card.Body className="p-0">
                <SimpleBarReact>
                    <Table
                        borderless
                        className="mb-0 fs--1 border-200 rounded-3 table-dashboard"
                    >
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th>Nombre</th>
                                <th className="text-center">Rendimiento</th>
                                <th className="text-center">Ventas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data?.map((ejecutivo, index) => (
                                <MembersRow key={index}
                                    {...ejecutivo}
                                    nombreTabla={args.nombre}
                                    isLast={index === data.length - 1}
                                />
                            ))}
                        </tbody>
                    </Table>
                </SimpleBarReact>
            </Card.Body>
        </Card>
    );
};


export default TablaVentas;
