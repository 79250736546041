import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table, Container } from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { BsChevronBarExpand, BsChevronBarDown, BsChevronBarUp } from "react-icons/bs";
import { useEmisionStore } from 'hooks/useEmisionStore';

const TablaReact = ({ columns, data, size = 'sm', Ncolum = 10, onRadioChange, onPolizaSearch  }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: Ncolum }, // Initial page index and page size
    },
    useGlobalFilter,
    useSortBy, // Habilita la ordenación
    usePagination
  );

  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleRadioChange = (rowId) => {
    setSelectedRadio(rowId);
    // Call the callback function with the selected value
    onRadioChange(rowId);
  };

  const [nPoliza, setNPoliza] = useState('')

  const buscarPoliza = async() =>{

    onPolizaSearch(nPoliza)
  }


  return (
    <div className='tablaCaja'>
      <Container fluid>
        <Row>
          <Col xs='6'>
            <h6 className="mb-1 text-700 fw-normal lh-1">{data[0].ventas}&nbsp;Buscar en la tabla</h6>
            <InputGroup className='mb-2'>
              <InputGroup.Text>🔎</InputGroup.Text>
              <Form.Control
                id='inlineFormInputGroup'
                placeholder='Buscar...'
                value={globalFilter || ''}
                onChange={(e) => {
                  setGlobalFilter(e.target.value || undefined);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs='6'>
            <h6 className="mb-1 text-700 fw-normal lh-1">{data[0].ventas}&nbsp;Buscar póliza histórica</h6>
            <InputGroup className='mb-2'>
              
              <Form.Control
                id='inlineFormInputGroup'
                placeholder='Buscar Póliza...'
                value={nPoliza || ''}
                onChange={(e) => {
                  setNPoliza(e.target.value || undefined);
                }}
              />
             <Button variant='primary' onClick={buscarPoliza}>🔎</Button>
            </InputGroup>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Table
            responsive
            hover
            size={size}
            className='compact-table'
            {...getTableProps()}
            style={{ tableLayout: 'auto', maxWidth: '100%' }}
          >
            <thead className='headerTabla'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())} // Agrega las propiedades de ordenación
                      style={{ whiteSpace: 'nowrap' }} // Evita que el texto se divida en varias líneas
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ?  <BsChevronBarUp className='ml-05' /> : <BsChevronBarDown className='ml-05' />) :  <BsChevronBarExpand className='ml-05' />}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} style={{ width: cell.column.width, textTransform:'capitalize' }}>
                        {cell.column.id === 'Seleccione' ? (
                          <div className='d-grid text-center'>
                            <Form.Check
                              type={'radio'}
                              name={'radioGroup'}
                              value={row.original.ofcotiza_id}
                              checked={selectedRadio === row.original.ofcotiza_id}
                              onChange={() => handleRadioChange(row.original.ofcotiza_id)}
                            />
                          </div>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <div className='btnTabla'>
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Anterior
              </Button>
            </div>
            <div>
              <span>
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageCount}
                </strong>{' '}
              </span>
            </div>
            <div className='btnTabla'>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Siguiente
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TablaReact;
