import React, { useContext, useState, useEffect } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppContext from '../../../context/Context';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';

const CollapseItems = ({ route, openParents }) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isOpen = route.children.some(child => child.to === pathname);
    setOpen(isOpen || openParents.includes(route.name));
  }, [pathname, route.children, route.name, openParents]);

  const handleNavItemClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Nav.Item as="li">
        <Nav.Link
          onClick={handleNavItemClick}
          className={classNames('dropdown-indicator cursor-pointer', {
            'text-500': !route.active,
          })}
          aria-expanded={open}
        >
          <NavbarVerticalMenuItem route={route} />
        </Nav.Link>
      </Nav.Item>

      <Collapse in={open}>
        <Nav className="flex-column nav" as="ul">
          {route.children.map(childRoute => (
            <React.Fragment key={childRoute.name}>
              {childRoute.children ? (
                <CollapseItems route={childRoute} openParents={openParents} />
              ) : (
                <Nav.Item as="li">
                  {childRoute.to !== undefined && ( // Verifica si childRoute.to está definida
                    <NavLink
                      end={childRoute.exact}
                      to={childRoute.to}
                      state={{ open: childRoute.to === '/authentication-modal' }}
                      className={({ isActive }) =>
                        isActive && childRoute.to !== '#!' ? 'active nav-link' : 'nav-link'
                      }
                    >
                      <NavbarVerticalMenuItem route={childRoute} />
                    </NavLink>
                  )}
                </Nav.Item>
              )}
            </React.Fragment>
          ))}
        </Nav>
      </Collapse>
    </React.Fragment>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
    to: PropTypes.string, // La propiedad 'to' ahora es opcional
  }).isRequired,
  openParents: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const NavbarVerticalMenu = ({ routes }) => {
  const { config: { showBurgerMenu }, setConfig } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const { pathname } = useLocation();

  const findOpenParents = (routes, openParents) => {
    routes.forEach(route => {
      if (route.children) {
        if (route.children.some(child => child.to === pathname)) {
          openParents.push(route.name);
        }
        findOpenParents(route.children, openParents);
      }
    });
  };

  const openParents = [];
  findOpenParents(routes, openParents);

  return (
    <Nav as="ul" className="flex-column nav">
      {routes.map(route => (
        <CollapseItems route={route} key={route.name} openParents={openParents} onClick={handleNavItemClick} />
      ))}
    </Nav>
  );
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes)).isRequired,
};

export default NavbarVerticalMenu;
