import { useGestionStore } from 'hooks/useGestionStore';
import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Spinner, ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CountUp from 'react-countup';
import ecomBg from '../../../assets/img/illustrations/bg-wave.png';
import Background from '../../common/Background';
import { BsCircleFill } from 'react-icons/bs';
import { BsFileEarmark } from "react-icons/bs";

const SLAseguradoras = () => {
  const [cargar, setCargar] = useState(true)
  const { startSLAseguradoras } = useGestionStore();
  const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
  const [slaCotiz, setSlaCotiz] = useState();
  const [itemColors, setItemColors] = useState({});
  const [totalSLA, setTotalSLA] = useState(0)
  useEffect(() => {
    setSlaCotiz(null)
    const fetchData = async () => {
      try {
        let preData = await startSLAseguradoras(dataInicio, dataFinal);
        setSlaCotiz(preData.data);
        // Usando reduce para sumar la cantidad_inspecciones
        const totalInspecciones = preData.data.reduce((total, elemento) => {
          return total + elemento.cantidad_inspecciones;
        }, 0);
        setTotalSLA(totalInspecciones)
      } catch (error) {
        console.error("Error fetching data:", error);
        setCargar(false)
      }
    };

    fetchData();
  }, [dataInicio, dataFinal]);

  // Lista de colores primarios
  const primaryColors =  ['#003e6e', '#17a2b8', '#28a745', '#007bff', '#ffc107', '#dc3545', '#6c757d', '#f38008','#cd1cbec7','#dd4b8d','#a1b5d7', '#876754','#c9d85e'];

  useEffect(() => {
    const colors = {};
    slaCotiz?.forEach((item, index) => {
      colors[item.origen_nombre] = primaryColors[index % primaryColors.length];
    });
    setItemColors(colors);
  }, [slaCotiz]);

  return (
    <Card className="bg-transparent-50 overflow-hidden h-100">
              <Background
       
       image={ecomBg}
       className="d-none d-md-block bg-card"
       style={{
         backgroundSize: '230px',
         backgroundPosition: 'right bottom',
         zIndex: '-1',
         opacity: '0.8'
       }}
     />
      {/* ... (código existente) */}

      {/* ... (código existente) */}
      <Row>
      <Col xs={12}  className="m-1">
          <h6 className="m-1">Orígenes</h6>
      </Col>
      
        {slaCotiz?slaCotiz.map((item, key) =>(
          <Col xs={3} key={key}>
            <Row className="g-3">
              <Col xl xs={6} md={4} className="text-center">
                <h5 className="fw-medium text-700">

                  <BsCircleFill style={{ color: itemColors[item.origen_nombre], fontSize:'2.5rem', padding:'0.9rem' }} />
                  <CountUp style={{fontSize: '0.8rem'}} end={item.cantidad_inspecciones} duration={0.75} separator="," />
                </h5>
                <h6 className="mb-1 text-700 fw-normal lh-1">{item.origen_nombre==null?'Sin origen':item.origen_nombre}</h6>
              </Col>
            </Row>
          </Col>
        )):cargar?[1].map((item,key) =>(<Col key={key} xs={12}>
          <div className="g-3 row">
              <div className="text-center col-xl col-md-4 col-6 skeleton p-3">
                  
                      <Spinner className='Spinner' animation="border" role="status" ></Spinner>
                  
                  <h6>Cargando Origenes ...</h6>
              </div>
          </div>
      </Col>)):<Col className='text-center'>
                      <BsFileEarmark style={{fontSize:'8rem',padding:'1rem'}}/>
                      <h6>No hay registros</h6>
                </Col>}
      </Row>
      {
        cargar?      <ProgressBar className='m-2'>
        {slaCotiz?.map((item, key) => (
          <ProgressBar
            animated
            striped
            label={`${(item.cantidad_inspecciones*100/totalSLA).toFixed(1)} %`}
            style={{ backgroundColor: itemColors[item.origen_nombre] }}
            now={(item.cantidad_inspecciones*100/totalSLA).toFixed(1)}
            key={key + 1}
            
          />
        ))}
        {/* Agrega una barra de progreso adicional con un color diferente para contraste */}
    
      </ProgressBar>:''
      }

    </Card>
  );
};

export default SLAseguradoras;
