import React from 'react';
import { Card, Col } from 'react-bootstrap';
import Flex from '../../../components/common/Flex';

const Pendientes = ({ data }) => {
    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Pendientes</h6>
            </Card.Header>
            <Card.Body as={Flex} >
                <Col xl={2} className='pe-2'>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className='text-warning'>
                            {data[0].pendientesAcumuladoPropYdoc}&nbsp;
                        </span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className='text-warning'>
                            {data[1].pendientesAcumuladoPago}&nbsp;
                        </span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className='text-warning'>
                            {data[2].pendientesInspeccion}&nbsp;
                        </span>
                    </h6>
                </Col>
                <Col xl={10}>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Pendientes acumulado prop. y/o doc.
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Pendientes acumulado de pagos 1era cuota
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Pendiente de inspección
                    </h6>
                </Col>
            </Card.Body>
        </Card>
    );
}

export default Pendientes;
