import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../components/common/Flex';
import SoftBadge from '../../../components/common/SoftBadge';

import * as Icons from 'react-icons/bs'; // Importa todos los íconos de Bootstrap

// Esta función busca el ícono por su nombre dentro de los íconos importados de Bootstrap
const getIconComponentByName = (iconName) => {
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    // Aquí podrías retornar un ícono predeterminado o null si el ícono no se encuentra
    console.error(`No se encontró el ícono: ${iconName}`);
    return null;
  }

  return <IconComponent className='iconoNab' />;
};

const NavbarVerticalMenuItem = ({ route }) => {
  return (
    <Flex alignItems="center">
       {route.icon && getIconComponentByName(route.icon)}
      <span className="nav-link-text ps-1">{route.name}</span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
