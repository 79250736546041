import axios from 'axios';

const ventaNuevaAPI = axios.create({
   
    baseURL: process.env.REACT_APP_BASE_URL + '/venta-nueva'
});

ventaNuevaAPI.interceptors.request.use(config =>{
    config.headers = {
        ...config.headers,
        'x-token' : localStorage.getItem('token')
    }
    return config;
})

export default ventaNuevaAPI;