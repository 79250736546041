import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import VentasVsObjetivoChart from './VentasVsObjetivoChart';
import CumplimientoChart from './CumplimimientoChart';
import { ventasVsObjetivoChartData, cumplimiento } from '../../../../data/dashboard/ventasVsObjetivo';
import moment from 'moment';

const VentasVsAnios = (args) => {
    const [data, setData] = useState()
    const [obj, setObj] = useState()
    useEffect(() => {
     
        if(args.ventasObj !== undefined){

            const obj = args.ventasObj.data.map(item =>{
                return{
                    mes:item.mes,
                    objetivo:item.total_ventas,
                    ventas:item.total_ventas_ano_anterior
                }
            });
         
            setData(obj.map(item =>{return item.ventas}))
            setObj(obj.map(item =>{return item.objetivo}))
        }

    }, [])
    



    return (
        <Card className="mt-3">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">{`${moment().subtract(12, 'months').format('yyyy')} VS ${moment().format('yyyy')}`}</h6>
            </Card.Header>
            <Card.Body>

            {args.ventasObj.allData?<VentasVsObjetivoChart tipo={'año'} data={args.ventasObj.allData} />:<h6 className="mb-0 mt-2">Cargando</h6>}

            </Card.Body>
        </Card>
    );
};

export default VentasVsAnios;
