import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table, Container, Spinner } from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';

import { BsChevronBarExpand, BsChevronBarDown, BsChevronBarUp } from "react-icons/bs";
import { BsFileEarmark } from "react-icons/bs";
const TablaVacia = ({ columns, data, size = 'sm', Ncolum = 10, onRadioChange }) => {

  return (
    <div className='tablaCaja'>
      <Container fluid>
        <Row>
          <Col xs='auto'>
            <Form.Label htmlFor='inlineFormInputGroup' visuallyHidden>
              
            </Form.Label>
            <InputGroup className='mb-2'>
              <InputGroup.Text>🔎</InputGroup.Text>
              <Form.Control
                id='inlineFormInputGroup'
                placeholder='Buscar...'
              />
            </InputGroup>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
            <Table           
            responsive
            hover
            size={size}
            className=' compact-table '
            style={{ maxWidth: '100%' }} >
            <thead className="headerTabla" >
                <tr role="row">
                {columns.map((item,key) =>(
                    <th key={key}>{item.Header}</th>
                ))}
                </tr>
            </thead>
                <tbody role="rowgroup" className=' text-center spinerTabla'>
                  <tr  className='' style={{ width: '100%',display:'flex' }}>
                    <td style={{border:'none'}}>
                    <BsFileEarmark style={{fontSize:'8rem',padding:'1rem'}}/>
                      <h6>No hay registros</h6>    
                    </td>
                  </tr>
                
                </tbody>
            </Table>
        </Row>
      </Container>
    </div>
  );
};

export default TablaVacia;
