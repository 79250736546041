
import siniestrosAPI from "api/siniestrosAPI";
import { useSelector, useDispatch } from "react-redux";

export const useKPISiniestroStore = () => {

    const dispatch = useDispatch();

    const startObtenerSiniestrosKPI = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestrosKPI', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerSiniestrosKPIE3 = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestrosKPIE3', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerCircleGraf = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerCircleGraf', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }


    const startObtenerCircleGraf2 = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerCircleGraf2', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }


    const startDiasAVG = async (dataInicio, dataFinal,op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVG', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }
    


    const startDiasAVGDenunciaPago = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGDenunciaPago', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startDiasAVGPagoVBT = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGPagoVBT', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startDiasAVGBTCierre = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGBTCierre', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startMontoIndemnizado = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/montoIndemnizado', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startmontoAportado = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/montoAportado', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startSiniestroMesAño = async (anno) => {
        try {
            const { data } = await siniestrosAPI.post('/siniestroMesAnno',{anno} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startAnnosData = async (anno) => {
        try {
            const { data } = await siniestrosAPI.post('/annosData' );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    
    
        
    return {
        //Propiedades


        //*Metodos
        startObtenerSiniestrosKPI,
        startObtenerSiniestrosKPIE3,
        startObtenerCircleGraf,
        startObtenerCircleGraf2,
        startDiasAVG,
        startDiasAVGDenunciaPago,
        startDiasAVGPagoVBT,
        startDiasAVGBTCierre,
        startMontoIndemnizado,
        startmontoAportado,
        startSiniestroMesAño,
        startAnnosData

    }
}