import { Card } from 'react-bootstrap';
import Flex from '../../../../components/common/Flex';
import RendimientoChart from './RendimientoChart';

const Rendimiento = (args) => {
    let rendimiento = 0
    if(args.emisiones !== 0 && args.cotizaciones !== 0){
        rendimiento = (parseInt(args.emisiones) * 100 / parseInt(args.cotizaciones)).toFixed(2);
    }
    
    let color = '';
    if (rendimiento < 5.1) {
        color = 'danger';
    } else if (rendimiento > 5 && rendimiento < 7) {
        color = 'warning';
    } else if (rendimiento >= 7 && rendimiento < 8.5) {
        color = 'success';
    } else if (rendimiento >= 8.5) {
        color = 'primary'
    }
    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Rendimiento</h6>
            </Card.Header>

            <Card.Body as={Flex} className='align-items-start justify-content-between'>
                <div>
                    <h6 className="mb-1 text-700 fw-normal lh-1">{args.emisiones} Ventas</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">{args.cotizaciones} Cotizaciones</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-${color}`}>{rendimiento}%</span> Conversión a venta
                    </h6>
                </div>
                <div>
                    <RendimientoChart data={args} color={color} />
                    <p className="mb-0 mt-4 text-center fs--2 text-500">
                        Rendimiento: <span className="text-800"> {rendimiento}% </span>
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Rendimiento;