import React, { useEffect, useState } from 'react';
import { Card, Col, Spinner, Table } from 'react-bootstrap';
import SoftBadge from '../../../common/SoftBadge';
import SimpleBarReact from 'simplebar-react';
import Flex from '../../../common/Flex';
import { useDashboardStore } from 'hooks';
import moment from 'moment';


const MembersRow = ({
  color,
  rendimiento,
  emisiones_totales,
  numero_cotizaciones,
  numero_emisiones,
  numero,
  pendienteInspeccion,
  cantidadPagosPendientes,
  pagoPrimeraCuota,
  prom_cotizaciones_diarias
}) => {
  return (
    <tr className='border-bottom border-200'>
      <td>
        <Flex alignItems='center' className='position-relative'>
          <div className='ms-3'>
            <h6 className='mb-0 fw-semi-bold'>Semana {numero}</h6>
          </div>
        </Flex>
      </td>
      <td className='align-middle text-center fw-semi-bold'>
        <SoftBadge pill bg={color}>
          {rendimiento}%
        </SoftBadge>
      </td>
      <td className='align-middle text-center fw-semi-bold'>
        <p className='fs--2 mb-0'>{numero_emisiones}</p>
        <p className='fs--2 mb-0'>{Math.round(emisiones_totales)} UF </p>
      </td>
      <td className='align-middle text-center fw-semi-bold'>
        <p className='fs--2 mb-0'>{numero_cotizaciones}</p>
      </td>
      <td className='align-middle text-center fw-semi-bold'>
        <p className='fs--2 mb-0'>{(parseFloat(prom_cotizaciones_diarias).toFixed(1))}</p>
      </td>
      <td className='align-middle text-center fw-semi-bold'>
        <p className='fs--2 mb-0'>{pendienteInspeccion}</p>
      </td>
      <td className='align-middle text-center fw-semi-bold'>
        <p className='fs--2 mb-0'>{cantidadPagosPendientes}</p>
      </td>
      <td className='align-middle text-center fw-semi-bold'>
        <p className='fs--2 mb-0'>{pagoPrimeraCuota}</p>
      </td>
    </tr>
  );
};

const RankingComplejo = ({ data }) => {
  const { startEjecutivosList, startRankingSemanal } = useDashboardStore();
  const [ejecutivoSeleccionado, setEjecutivoSeleccionado] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [ejecutivos, setEjecutivos] = useState(null)
  const [idRefresh, setIdRefresh] = useState()
  const [leng, setLeng] = useState(1)

  useEffect(async () => {
    setFilteredData(data);
    let ejecutivos = await startEjecutivosList()
    setEjecutivos(ejecutivos)
    setLeng(ejecutivos?.data.length)
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let nuevoRanking = await startRankingSemanal(idRefresh, mes);
      
      setFilteredData(nuevoRanking.data);
      if(ejecutivos){
        setLeng(ejecutivos?.data.length)
      }

    };
    
    fetchData();
  }, [idRefresh,mes]);
  
  useEffect(() => {
    if (ejecutivoSeleccionado !== "") {
      setIdRefresh(ejecutivoSeleccionado);
    }
  }, [ejecutivoSeleccionado]);
  
  const handleChange = async(event) => {
    const selectedId = event.target.value;
    
    if (selectedId !== ejecutivoSeleccionado) {
      setEjecutivoSeleccionado(selectedId)
      setIdRefresh(selectedId)
      setLeng(1)
    } else {
      setIdRefresh(''); 
     
    }
  };
  const [mes, setMeses] = useState(moment().format("M"))
  const handleChangeMeses = async (event) => {
    const selectedValue = event.target.value;
   
    let nuevoRanking;
   
    if (selectedValue !== "") {
      setMeses(selectedValue);

      nuevoRanking = await startRankingSemanal(ejecutivoSeleccionado, selectedValue);
      setLeng(1)
    } else {
      setLeng(ejecutivos?.data.length)
      setMeses(moment().format("M"));
      nuevoRanking = await startRankingSemanal("", mes);
 
    }
  
    setFilteredData(nuevoRanking.data);
  };
  

      // Crear un array de objetos con los nombres de los meses
      const meses = [];

      for (let i = 0; i < 12; i++) {
      const mes = moment().month(i);
      meses.push({
          id: i + 1,
          nombre: mes.format('MMMM').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      });
      }


  if (data.length===0) {
    return <Col xxl={12} xl={12} className="text-center"><Spinner variant="primary" size='xl' animation="border" role="status">
   
  </Spinner></Col>
  ;
  }

  return (
    <Card className='h-100'>
      <Card.Header className='pb-0 mb-4'>
        <h6 className='mb-2 mt-2'>Ranking Semanal</h6>

        <select value={ejecutivoSeleccionado} onChange={handleChange}>
          <option value=''>Todos</option>
          {ejecutivos?.data.map((ejecutivo, index) => (
            <option key={index} value={ejecutivo.u_nombre}>
              {ejecutivo.u_nombre}
            </option>
          ))}
        </select>
        <select value={mes} onChange={handleChangeMeses}>
                    {meses.map((ejecutivo) => (
                    <option key={ejecutivo.id} value={ejecutivo.id}>
                        {ejecutivo.nombre}
                    </option>
                    ))}
                </select>
           
      </Card.Header>
      
      <Card.Body className='p-0'>
        
        <SimpleBarReact>
          
          <Table borderless className='mb-0 fs--1 border-200 rounded-3 table-dashboard'>
            <thead className='bg-light'>
              <tr className='text-900'>
                <th>Semana</th>
                <th className='text-center'>Rendimiento</th>
                <th className='text-center'>Ventas</th>
                <th className='text-center'>Cotizaciones</th>
                <th className='text-center'>Cot.diarias</th>
                <th className='text-center'>Pen.insp.</th>
                <th className='text-center'>Pen.prop y/o doc.</th>
                <th className='text-center'>Pen.pago cuota 1</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((ejecutivo, index) => (
                <MembersRow
                  key={index}
                  numero={index + 1}
                  semana_anio={ejecutivo.semana_anio}
                  color={ejecutivo.color}
                  rendimiento={ejecutivo.rendimiento}
                  emisiones_totales={ejecutivo.num_emisiones_uf}
                  numero_cotizaciones={ejecutivo.num_cotizaciones}
                  numero_emisiones={ejecutivo.num_emisiones}
                  pendienteInspeccion={ejecutivo.pendienteInspeccion}
                  cantidadPagosPendientes={ejecutivo.cantidadPagosPendientes}
                  pagoPrimeraCuota={ejecutivo.pagoPrimeraCuota}
                  prom_cotizaciones_diarias={ejecutivo.promedioCotizacionesDiarias/leng}
                />
              ))}
            </tbody>
          </Table>
        </SimpleBarReact>
      </Card.Body>
    </Card>
  );
};

export default RankingComplejo;