import React from 'react';
import { Card, Col } from 'react-bootstrap';
import Flex from '../../../components/common/Flex';

const Produccion = ({ data }) => {

    const format = (number) => {
        const parts = number.toString().split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        const decimalPart = parts[1] ? ',' + parts[1] : '';
        return integerPart + decimalPart;
    }

    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Producción</h6>
            </Card.Header>
            <Card.Body as={Flex} >
                <Col xl={6} className='pe-3'>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{
                            data.PrimaNetaARenovar ? (format(data.PrimaNetaARenovar.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{
                            data.PrimaNetaRenovada ? (format(data.PrimaNetaRenovada.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{
                            data.PrimaPromedio ? (format(data.PrimaPromedio.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-danger`}>UF&nbsp;{
                             data.PrimaNetaAnulada ? (format(data.PrimaNetaAnulada.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-danger`}>UF&nbsp;{
                             data.PrimaNetaCancelada ? (format(data.PrimaNetaCancelada.toFixed(2))) : 0
                        }</span>
                    </h6>
                </Col>
                <Col xl={6}>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta a renovar
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta renovada
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta promedio
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta anulada
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta cancelada
                    </h6>
                </Col>
            </Card.Body>
        </Card>
    );
}

export default Produccion;
