import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useRenovaciones } from 'hooks';


import Variacion from '../../../components/dashboards/renovaciones/Variacion';
import Rendimiento from '../../../components/dashboards/renovaciones/Rendimiento';

import Produccion from '../../../components/dashboards/renovaciones/Produccion';
import Pendientes from '../../../components/dashboards/renovaciones/Pendientes';
import Ingresos from '../../../components/dashboards/renovaciones/Ingresos';
import GreetingsDate from '../../../components/dashboards/crm/greetings/GreetingsDate';
import GreetingCard from '../../../components/dashboards/fuerza-de-venta/GreetingCard';
import GraficoVentas from 'components/dashboards/renovaciones/GraficoVentas';
import TablaVentas from 'components/dashboards/renovaciones/TablaVentas';


const Renovaciones = () => {
    const [variacionPromise, setVariacionPromise] = useState([
        { id: 1, value: 0, name: `Emisiones año anterior`, color: 'gray-300' },
        { id: 2, value: 0, name: `Emisiones año actual`, color: 'primary' }
    ]);
    const [rendimientoPromise, setRendimientoPromise] = useState([
        { ventas: 0 }, { cotizaciones: 0 }
    ]);
    const [pendientesPromise, setPendientesPromise] = useState([
        { pendientesAcumuladoPropYdoc: 0 },
        { pendientesAcumuladoPago: 0 },
        { pendientesInspeccion: 0 }
    ])
    const [produccionPromise, setProduccionPromise] = useState([
        { primaNetaVendida: 0 },
        { primaNetaPromedio: 0 },
        { primaNetaAnulada: 0 },
        { tasaDeAnulacion: 0 }
    ])
    const [ingresosPromise, setIngresosPromise] = useState([
        { primaNetaVigente: 0 },
        { tasaComisionPromedio: 0 },
        { ingresosNetos: 0 },
        { ingresosNetosEnCLP: 0 },
        { ingresoPromedio: 0 }
    ])
    const {
        startRenovacionVariacion,
        startRenovacionRendimientoAdmin,
        startRenovacionPendientesAdmin,
        startRenovacionProduccionAdmin,
        startRenovacionIngresosAdmin,

        startGraficoVentas,
        startRankingAnulaciones,
        startRankingCancelaciones
    } = useRenovaciones();


    const [graficoVentas, setGraficoVentas] = useState()
    const [rankingAnulacion, setRankingAnulacion] = useState()
    const [rankingCancelaciones, setRankingCancelaciones] = useState()
    //States
    const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
    const { user } = useSelector(state => state.login);


    useEffect(async () => {
        let variacionPromise = await startRenovacionVariacion(dataInicio, dataFinal);
        setVariacionPromise(variacionPromise);
        let rendimientoPromise = await startRenovacionRendimientoAdmin(dataInicio, dataFinal);
        setRendimientoPromise(rendimientoPromise);
        let produccionPromise = await startRenovacionProduccionAdmin(dataInicio, dataFinal);
        setProduccionPromise(produccionPromise);
        let ingresosPromise = await startRenovacionIngresosAdmin(dataInicio, dataFinal);
        setIngresosPromise(ingresosPromise);
    }, [dataInicio, dataFinal]);

    useEffect(async () => {
        let pendientesPromise = await startRenovacionPendientesAdmin();
        setPendientesPromise(pendientesPromise);

        let GraficoVentas = await startGraficoVentas();
        setGraficoVentas(GraficoVentas)

        let RankingAnulacion = await startRankingAnulaciones(); 
        setRankingAnulacion(RankingAnulacion)

        let RankingCancelaciones = await startRankingCancelaciones();
        setRankingCancelaciones(RankingCancelaciones)
        
        
    }, []);

    return (
        <>
            <Card className="bg-100 shadow-none border mb-3">
                <Card.Body>
                    <Row className="g-0 justify-content-between">
                        <Col md="auto" className="mb-3 mb-md-0">
                            <Row className="g-3 gy-md-0 h-100 align-items-center">
                                <Col xs="auto">
                                    <h6 className="text-700 mb-0 text-nowrap">Mostrando información para las fechas:</h6>
                                </Col>
                                <Col md="auto">
                                    <GreetingsDate />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <Row className="g-3">
                        <Col xs={6}>
                            <GreetingCard nombre={user.name} />
                        </Col>
                        <Col xs={6}>
                            <Variacion data={variacionPromise} radius={['100%', '80%']} />
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                <Col md={6}>
                                    <Rendimiento data={rendimientoPromise} />
                                </Col>
                                <Col md={6}>
                                    <Pendientes data={pendientesPromise} />
                                </Col>
                                <Col md={6}>
                                    <Produccion data={produccionPromise} />
                                </Col>
                                <Col md={6}>
                                    <Ingresos data={ingresosPromise} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                {
                                    graficoVentas?<GraficoVentas data={graficoVentas} />:'Cargando'
                                }
                                
                            </Row>
                        </Col>
                        <Row className='g-3'>
                            <Col lg={6} >
                                <TablaVentas nombre="Anulacion" data={rankingAnulacion} />
                            </Col>
                            <Col lg={6}>
                                <TablaVentas nombre="Cancelacion" data={rankingCancelaciones} />
                            </Col>
                        </Row>

                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Renovaciones;
