import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useSelector } from "react-redux";

import Variacion from '../../../components/dashboards/general/Variacion';
import GreetingsDate from '../../../components/dashboards/crm/greetings/GreetingsDate';
import GreetingCard from '../../../components/dashboards/fuerza-de-venta/GreetingCard';
import RetornoInversion from 'components/gestion-venta/cotizacion/TablaCotizaciones';
import { useGeneralStore } from 'hooks/useGeneralStore';
import { useGestionStore } from 'hooks/useGestionStore';
import TablaCotizaciones from 'components/gestion-venta/cotizacion/TablaCotizaciones';
import Rendimiento from 'components/gestion-venta/cotizacion/Rendimiento';
import { useDashboardStore } from 'hooks';
import SLAseguradoras from 'components/gestion-venta/cotizacion/SLAseguradoras';
import { useUtilStore } from 'hooks/useUtilStore';


const Cotizacion = () => {
    //Demos
    const [variacionPromise, setVariacionPromise] = useState([
        { id: 1, value: 0, name: `Emisiones año anterior`, color: 'gray-300' },
        { id: 2, value: 0, name: `Emisiones año actual`, color: 'primary' }
    ]);

    const [rendimientoPromise, setRendimientoPromise] = useState([
        { ventas: 0 }, { cotizaciones: 0 }
    ]);


    //States
    const { user } = useSelector(state => state.login);
    const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
    const {

        startufDelDia
        // Otros métodos del useGestionStore
      } = useUtilStore();

    const [UFDeldia, setUFDeldia] = useState()
    const [tablaRI, setTablaRI] = useState()

    const [ejecutivos, setEjecutivos] = useState(null)
    const [bitacora_status, setBitacora_status] = useState()
    const [bitacora_tipo_cotizante, setBitacora_tipo_cotizante] = useState()

    const {
        startGeneralVariacion,
        startObtenerCotizaciones,
        startEjecutivosList,
        startBitacora_status,
        startBitacora_tipo_cotizante,
        startGeneralVariacionFV
    } = useGestionStore();

    const {
        startDashboardRendimientoAdmin,
    } = useDashboardStore();


    useEffect(async () => {
        if(user.rol === '3'){
            let rendimientoPromise = await startGeneralVariacion(dataInicio, dataFinal, user.name);
            setRendimientoPromise(rendimientoPromise);
        }else if(user.rol === '1') {
            let rendimientoPromise = await startGeneralVariacionFV(dataInicio, dataFinal);
            setRendimientoPromise(rendimientoPromise);
        }else{
            let rendimientoPromise = await startGeneralVariacion(dataInicio, dataFinal);
            setRendimientoPromise(rendimientoPromise);
        }


    }, [dataInicio, dataFinal]);

    useEffect(async () => {

        let ejecutivos = await startEjecutivosList()
        setEjecutivos(ejecutivos.data)

        let Bitacora_statu = await startBitacora_status()
        setBitacora_status(Bitacora_statu.data)

        let Bitacora_tipo = await startBitacora_tipo_cotizante()
        setBitacora_tipo_cotizante(Bitacora_tipo.data)

        let tempUF = await startufDelDia();
        if (tempUF.uf) {
      
          const ufString =Math.floor(parseFloat(tempUF.uf.replace(/\./g, '').replace(',', '.')));

          setUFDeldia(ufString);
        } 
        
        
    }, []);
  
    



    return (
        <>
            <Card className="bg-100 shadow-none border mb-3">
                <Card.Body>
                    <Row className="g-0 justify-content-between">
                        <Col md="auto" className="mb-3 mb-md-0">
                            <Row className="g-3 gy-md-0 h-100 align-items-center">
                                <Col xs="auto">
                                    <h6 className="text-700 mb-0 text-nowrap">Mostrando información para las fechas:</h6>
                                </Col>
                                <Col md="auto">
                                    <GreetingsDate />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <Row className="g-3">
                        <Col xs={12} xl={6}>
                            <GreetingCard nombre={user.name} />
                        </Col>
                        <Col xs={12} xl={6}>
                            {rendimientoPromise?<Rendimiento data={rendimientoPromise} />:''}
                        </Col>
                        {
                            user.rol === '1'?<Col lg={12}><SLAseguradoras /></Col>:''
                        }
                        
                        <Col lg={12}>
                            <Row className="g-3">
                                <Col md={12}>
                                    {
                                        UFDeldia?<TablaCotizaciones UFDeldia={UFDeldia} ejecutivos={ejecutivos} bitacora_status={bitacora_status} bitacora_tipo_cotizante={bitacora_tipo_cotizante} nombre="Cotizaciones"  /> : ''
                                    }
                                        
                                    
                                </Col>
                            </Row>


                        </Col>

                        
                    </Row>
                </Col>
            </Row>

        </>
    );
};

export default Cotizacion;
