import { generalAPI } from "api";
import { useSelector, useDispatch } from "react-redux";

export const useGeneralStore = () => {

    const dispatch = useDispatch();

    const startGeneralVariacion = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await generalAPI.post('/variacionAutoGeneral', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGeneralProduccionAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await generalAPI.post('/produccionAutoGeneral', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGeneralIngresosAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await generalAPI.post('/ingresosAutoGeneral', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGraficoVentas = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await generalAPI.post('/graficoVentas', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGraficoRenovaciones = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await generalAPI.post('/graficoRenovaciones', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGraficoStock = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await generalAPI.post('/graficoStock', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRankingAnulaciones = async (mes) => {
        try {
            const { data } = await generalAPI.post('/dataAnulaciones',mes ? { mes } : {});
            return data.formato;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRankingCancelaciones = async (mes) => {
        try {
            const { data } = await generalAPI.post('/dataCancelaciones',mes ? { mes } : {});
            return data.formato;
        } catch (error) {
            console.log(error.data);
        }
    }

    
    const startTablaRI = async (mes) => {
        try {
            const { data } = await generalAPI.post('/tablaRI', mes ? { mes } : {});
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }
    

    
    
    

    return {
        //Propiedades


        //*Metodos
        startGeneralVariacion,
        startGeneralProduccionAdmin,
        startGeneralIngresosAdmin,
        startGraficoVentas,
        startGraficoRenovaciones,
        startGraficoStock,
        startRankingAnulaciones,
        startRankingCancelaciones,
        startTablaRI
    }
}