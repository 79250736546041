import React from 'react';
import { Card, Col } from 'react-bootstrap';
import Flex from '../../../common/Flex';

const Produccion = ({ data }) => {
    const format = (number) => {
        const parts = number.toString().split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        const decimalPart = parts[1] ? ',' + parts[1] : '';
        return integerPart + decimalPart;
    }
    let colorPrimaNetaPromedio = '';
    const primaNetaPromedio = data[1].primaNetaPromedio;
    if (primaNetaPromedio <= 10) {
        colorPrimaNetaPromedio = 'danger';
    } else if (primaNetaPromedio >= 10.1 && primaNetaPromedio <= 13.9) {
        colorPrimaNetaPromedio = 'warning';
    } else if (primaNetaPromedio >= 14 && primaNetaPromedio <= 18) {
        colorPrimaNetaPromedio = 'success';
    } else if (primaNetaPromedio >= 18.1) {
        colorPrimaNetaPromedio = 'primary'
    }
    let colorTasaDeAnulacion = '';
    const tasaDeAnulacion = data[3].tasaDeAnulacion;
    if (tasaDeAnulacion <= 5) {
        colorTasaDeAnulacion = 'success';
    } else if (tasaDeAnulacion >= 5.1 && tasaDeAnulacion < 10) {
        colorTasaDeAnulacion = 'warning';
    } else if (tasaDeAnulacion >= 10) {
        colorTasaDeAnulacion = 'danger';
    }
    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Producción</h6>
            </Card.Header>
            <Card.Body as={Flex} >
                <Col xl={6} className='pe-3'>
                    <h6 className="mb-1 lh-1">
                        <span>UF&nbsp;{
                            data[0].primaNetaVendida ? (format(data[0].primaNetaVendida.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-${colorPrimaNetaPromedio}`}>UF&nbsp;{
                            data[1].primaNetaPromedio ? (format(data[1].primaNetaPromedio.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span>UF&nbsp;{
                            data[2].primaNetaAnulada ? (format(data[2].primaNetaAnulada.toFixed(2))) : 0
                        }</span>
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-${colorTasaDeAnulacion}`}>{
                            data[3].tasaDeAnulacion ? (format(data[3].tasaDeAnulacion.toFixed(2))) : 0
                        }%</span>
                    </h6>
                </Col>
                <Col xl={6}>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta vendida
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta promedio
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Prima neta anulada
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        Tasa de anulación
                    </h6>
                </Col>
            </Card.Body>
        </Card>
    );
}

export default Produccion;
