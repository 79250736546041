import React, { useState, useEffect, forwardRef } from 'react';
import { Modal, ButtonGroup, Button, Col, Form, InputGroup, Row, Table, Container } from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { BsChevronBarExpand, BsChevronBarDown, BsChevronBarUp } from "react-icons/bs";
import { useEmisionStore } from 'hooks/useEmisionStore';
import { useSiniestrosStore } from 'hooks/useSiniestrosStore';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TablaVacia from './TablaVacia';
import TablaReact2 from './TablaReact2';

import { BsCheckCircleFill } from "react-icons/bs";
import { BsFillXCircleFill  } from "react-icons/bs";
const TablaReact = ({ columns, data, size = 'sm', Ncolum = 10, onRadioChange, onPolizaSearch  }) => {
  const [laData, setLaData] = useState(data || [])
  useEffect(() => {
    setLaData(data || []);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = useTable(
    {
      columns,
      data:laData,
      initialState: { pageIndex: 0, pageSize: Ncolum }, // Initial page index and page size
    },
    useGlobalFilter,
    useSortBy, // Habilita la ordenación
    usePagination
  );

  const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Form.Control
        
        ref={ref}
        onClick={onClick}
        value={value}
        className="ps-4"
        onChange={(e) => {
      
        }}
      />
      <FontAwesomeIcon
        icon="calendar-alt"
        className="text-primary position-absolute top-50 translate-middle-y ms-2"
      />
    </div>
  ));


  
  const columnsTabla = React.useMemo(
    () => [
      {
        Header: 'Estado Cuota',
        accessor: 'e_operacion',
      },
      {
        Header: 'N° Cuota',
        accessor: 'n_cuota',
      },
      {
        
        Header: 'Valor Cuota',
        accessor: 'deducible',
        Cell: ({ row }) => (
          "$"+formatMoneda(row.original.deducible)
        ),
      },
      {
        Header: 'Observaciones',
        accessor: 'observaciones',
      },
      
      {
        Header: 'Acción',
        Cell: ({ row }) => (
          <div className="flexMagic jc-l">
            {row.original.e_operacion == 'En Proceso'?(<>
              <BsCheckCircleFill className='btn-tabla-noButton cg' onClick={() => cambioEstado(row.original.id, 'Aprobado', row.original.op_num)}/>
              <BsFillXCircleFill  className='btn-tabla-noButton cr' onClick={() => cambioEstado(row.original.id, 'Rechazado', row.original.op_num)} />
            </>):('')}

          </div>
        ),
      },
      // ... Add more columns as needed
    ],
    []
  );

  const [selectedRadio, setSelectedRadio] = useState(null);
  const [modalShow, setModalShow] = useState(false)


  const [operación, setOperación]  = useState('')
  const [rut, setRut]  = useState('')
  const [nacimiento, setNacimiento]  = useState('')
  const [nombre, setNombre]  = useState('')
  const [paterno, setPaterno]  = useState('')
  const [materno, setMaterno]  = useState('')
  const [poliza, setPoliza]  = useState('')
  const [aseguradora, setAseguradora]  = useState('')
  const [tpoliza, setTPoliza]  = useState('')

  const [startDate, setStartDate] = useState('');

  const [listaAseguradora, setListaAseguradora] = useState([])
  const [listaPoliza, setListaPoliza] = useState([])
  const [pasos, setPasos] = useState(1)

  const [rsiniestro, setRSiniestro] = useState('')
  const [eOAntecedentes, setEOAntecedentes] = useState('')
  const [mTIndemnizar, setMTIndemnizar] = useState('')
  const [fDAseguradora, setFDAseguradora] = useState('')
  const [Observaciones, setObservaciones] = useState('')

  const [oEOperacion, setOEOperacion] = useState([])

  const [edOperacion, setEdOperacion] = useState('')
  const [ndSiniestro, setNdSiniestro] = useState('')
  const [ndCuota, setNdCuota] = useState('')
  const [Deducible, setDeducible] = useState('')
  const [fPagoRechazo, setFPagoRechazo] = useState('')
  const [Observaciones2, setObservaciones2] = useState('')


  const [obtenerCuotas, setObtenerCuotas] = useState([])
  const [valorCuota, setValorCuota] = useState(0)
  const [obsCuota, setObsCuota] = useState('')

  
  const [recargar, setRecargar] = useState(false)

  const [op_num, setOp_num] = useState('')

  const [isValid, setIsValid] = useState(null); // null, true o false.



  const [montoIndemnizado, setMontoIndemnizado] = useState(0)
  const [montoAportadoCliente, setMontoAportadoCliente] = useState(0)
  const [fechaVBTesoreria, setFechaVBTesoreria] = useState('')
  const [fechaCierreSiniestro, setFechaCierreSiniestro] = useState('')


const {
  startObtenerSiniestroAseg,
  startObtenerTpoPoliza,
  startObtenerEstadoOperacion,
  startObtenerSiniestros,

  startInsertarSiniestros,
  startInsertarSiniestros2,
  startInsertarSiniestros3,
  startInsertarSiniestros4,


  startObtenerCuotas,
  startInsertarCuota,
  startCambiarEstadoCuota
} = useSiniestrosStore();

  const handleClose = async() => {
    setModalShow(false)
    setPasos(1)
    let TempdataSiniestros = await startObtenerSiniestros()
    if(TempdataSiniestros){
      setLaData(TempdataSiniestros.data)
    }
  }


  const handleOpen = async() =>{
    setModalShow(true)


  }
  const guardarSiniestro = async(paso) =>{

    switch(paso){
      case 1:
 

      if(operación !== " " && operación && 
      rut !== " " && rut && 
      tpoliza !== " " && tpoliza && 
      aseguradora !== " " && aseguradora && 
      poliza !== " " && poliza && 
      nacimiento !== " " && nacimiento && 
      rsiniestro !== " " && rsiniestro && 
      eOAntecedentes !== " " && eOAntecedentes && 
     
      fDAseguradora !== " " && fDAseguradora && 
      Observaciones !== " " && Observaciones && 
      nombre !== " " && nombre && 
      paterno !== " " && paterno && 
      materno !== " " && materno) {
       // Tu código aquí si todas las condiciones son verdaderas
   
   
            if(isValid){
              let aseguradoraNombre = listaAseguradora.find(aseg => aseg.aseg_id === parseInt(poliza)).aseg_nombre;
              let polizaNombre = listaPoliza.find(index => index.tipo_pol_numpol===aseguradora).tipo_pol_estado
           
              //op_fecha_recepcion, op_est_obtencion_ant, op_total_indemnizar, op_fecha_obtencion_antecedentes, op_observaciones
            let tempInsert = await startInsertarSiniestros(operación, rut, polizaNombre, aseguradoraNombre, aseguradora, rsiniestro, eOAntecedentes , fDAseguradora,  Observaciones, mTIndemnizar,nacimiento, nombre, paterno, materno)
            if(aseguradora == '11733902'){
              let tempInsert2 = await startInsertarCuota(tempInsert.id, 'En Proceso', 1 , mTIndemnizar , 'Cuota 1')
            }
            if(tempInsert.ok){
              Swal.fire({
                title: "Siniestro creado correctamente?",
                text: "Desea ingresar al paso 2 Validación EstoySeguro?",
                showCancelButton: true,
                icon: "success",
                confirmButtonText: "Paso 2"
              }).then(async(result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setPasos(2)
                    setOp_num(tempInsert.id)
         
                    

  
                    let tempData4 = await startObtenerCuotas(tempInsert.id);
                    if(tempData4){
                      setObtenerCuotas(tempData4.data)
                    }else{
                      setObtenerCuotas([])
                    }
   
  
                } else {
                  handleClose()
                }
              });
            }
            }else{
              Swal.fire("Rut Invalido","Debe ingresar un rut valido","info")
            }
          
    
        }else{
          Swal.fire("Faltan Campos","Complete los datos antes de guardar","info")
        }
      break;
      case 2:
    

        if(op_num, edOperacion, Deducible, Observaciones2){
        let tempInsert2 = await startInsertarCuota(op_num, 'En Proceso', obtenerCuotas.length+1 , valorCuota, obsCuota)
   
        if(tempInsert2.ok){
          Swal.fire({
            title: "Cuota creada correctamente",
            text: "¿Desea ingresar otra cuota?",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Crear otra cuota"
          }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
          
              let tempData4 = await startObtenerCuotas(op_num);
              if(tempData4){
                setObtenerCuotas(tempData4.data)
              }else{
                setObtenerCuotas([])
              }
            } else {
              handleClose()
            }
          });
        }
  
      }


      break;


      case 3:

        if(op_num, edOperacion,
          ndSiniestro,
          Deducible,
          obtenerCuotas.length+1,
          Observaciones2){


        let tempInsert3 = await startInsertarSiniestros2(String(op_num), edOperacion,
          ndSiniestro,
          Deducible,
          String(obtenerCuotas.length+1),
          Observaciones2)
        
   

        if(tempInsert3.ok){
          if(aseguradora !== '11733902'){
            Swal.fire({
              title: "Siniestro Ingresado Correctamente",
              text: "",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Salir"
            }).then(async(result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
        
                handleClose()
              }
            });
          }else{
            Swal.fire({
              title: "Actualizado Correctamente",
              text: "¿Desea ingresar al paso 3?",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Paso 3"
            }).then(async(result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                setPasos(3)
              } else {
                handleClose()
              }
            });
          }

        }
  
      }


      break;

      case 4:


        if(op_num, montoIndemnizado, montoAportadoCliente, fechaVBTesoreria, fechaCierreSiniestro){
        let tempInsert2 = await startInsertarSiniestros3(op_num, montoIndemnizado, montoAportadoCliente, fechaVBTesoreria, fechaCierreSiniestro)

        if(tempInsert2.ok){
          Swal.fire({
            title: "Siniestro completado correctamente",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Salir"
          }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

              handleClose()
            } 
          });
        }
  
      }


      break;
    }


  }

  const handleRadioChange = (rowId) => {
    setSelectedRadio(rowId);
    // Call the callback function with the selected value
    onRadioChange(rowId);
  };

  const [nPoliza, setNPoliza] = useState('')

  const buscarPoliza = async() =>{

    onPolizaSearch(nPoliza)
  }

  const cambiarPoliza = (valor) =>{

    setPoliza(valor)
    
    
    if(valor=='3'){
      setAseguradora('11733902')
      setTPoliza('Consorcio Vida')
    }else if(valor=='4'){
      setAseguradora('22495')
      setTPoliza('Consorcio Generales')
    }

  }

  useEffect(async() => {
    let tempData = await startObtenerSiniestroAseg();
    if(tempData){
      setListaAseguradora(tempData.data)
    }else{
      setListaAseguradora([])
    }

    let tempData2 = await startObtenerTpoPoliza();
    if(tempData2){
      setListaPoliza(tempData2.data)
    }else{
      setListaPoliza([])
    }


    let tempData3 = await startObtenerEstadoOperacion();
    if(tempData3){
      setOEOperacion(tempData3.data)
    }else{
      setOEOperacion([])
    }


    let tempData4 = await startObtenerCuotas(op_num);
    if(tempData4){
      setObtenerCuotas(tempData4.data)
    }else{
      setObtenerCuotas([])
    }

    
    

    
  }, [])
  
  const formatRut = (rut) => {
    let value = rut.replace(/[^0-9kK]+/g, '').toUpperCase();
    if (value.length <= 1) return value;

    let result = value.slice(-4, -1) + "-" + value.substr(value.length - 1);
    for (let i = 4; i < value.length; i += 3) {
      result = value.slice(-3 - i, -i) + "." + result;
    }
    return result;
  };

  const validateRut = (rut) => {
    let value = rut.replace(/\./g, '').replace('-', '');
    if (value.length < 9) return false;

    let cuerpo = value.slice(0, -1);
    let dv = value.slice(-1).toUpperCase();
    let suma = 0;
    let multiplo = 2;

    for (let i = 1; i <= cuerpo.length; i++) {
      let index = multiplo * cuerpo.charAt(cuerpo.length - i);
      suma += index;
      multiplo = multiplo < 7 ? multiplo + 1 : 2;
    }

    let dvEsperado = 11 - (suma % 11);
    if (dvEsperado === 10) dvEsperado = 'K';
    if (dvEsperado === 11) dvEsperado = '0';

    return dv.toString() === dvEsperado.toString();
  };

  const handleChange = (e) => {
    const formattedValue = formatRut(e.target.value);
    setRut(formattedValue);
    setIsValid(validateRut(formattedValue));
  };
  const cambioEstado = (id, estado, idPoliza) =>{
    Swal.fire({
      title: "¿Esta seguro que desea cambiar el estado?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Cambiar Estado"
    }).then(async(result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let temp0 = await startCambiarEstadoCuota(id,estado)
        let temp1 = await startObtenerCuotas(idPoliza)
        if(temp1){
          setObtenerCuotas(temp1.data)
        }else{
          setObtenerCuotas([])
        }
      } else {
       
      }
    });
  }
  
  const formatMoneda = (number) => {
    if (number !== undefined) {
        const parts = number.toString().split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        let decimalPart = parts[1] ? ',' + parts[1] : '';

        if (decimalPart.length > 1) {
            // Si la longitud del decimal es mayor a 1, redondea a un decimal
            decimalPart = ',' + parseFloat('0.' + parts[1]).toFixed(1).split('.')[1];
        }

        return integerPart + decimalPart;
    }
}
  return (
    <div className='tablaCaja'>
      <Container fluid>
        <Row>
          <Col xs='6'>
            <h6 className="mb-1 text-700 fw-normal lh-1">{data[0].ventas}&nbsp;Buscar en la tabla</h6>
            <InputGroup className='mb-2'>
              <InputGroup.Text>🔎</InputGroup.Text>
              <Form.Control
                id='inlineFormInputGroup'
                placeholder='Buscar...'
                value={globalFilter || ''}
                onChange={(e) => {
                  setGlobalFilter(e.target.value || undefined);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs='6 flexMagic'>
              <Button onClick={handleOpen} className='me-2 mb-1 btn btn-primary btn-sm'>Ingreso</Button>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Table
            responsive
            hover
            size={size}
            className='compact-table'
            {...getTableProps()}
            style={{ tableLayout: 'auto', maxWidth: '100%' }}
          >
            <thead className='headerTabla'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())} // Agrega las propiedades de ordenación
                      style={{ whiteSpace: 'nowrap' }} // Evita que el texto se divida en varias líneas
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ?  <BsChevronBarUp className='ml-05' /> : <BsChevronBarDown className='ml-05' />) :  <BsChevronBarExpand className='ml-05' />}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} style={{ width: cell.column.width, textTransform:'capitalize' }}>
                        {cell.column.id === 'Seleccione' ? (
                          <div className='d-grid text-center'>
                            <Form.Check
                              type={'radio'}
                              name={'radioGroup'}
                              value={row.original.ofcotiza_id}
                              checked={selectedRadio === row.original.ofcotiza_id}
                              onChange={() => handleRadioChange(row.original.ofcotiza_id)}
                            />
                          </div>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className='d-flex justify-content-center align-items-center'>
            <div className='btnTabla'>
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Anterior
              </Button>
            </div>
            <div>
              <span>
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageCount}
                </strong>{' '}
              </span>
            </div>
            <div className='btnTabla'>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Siguiente
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
    show={modalShow}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered

  >
    <Modal.Header  style={{display:'block'}} >
      <Button style={{float:'right'}} closeButton onClick={handleClose}>X</Button>
      <Modal.Title id="contained-modal-title-vcenter">INGRESAR SINIESTRO</Modal.Title>
    </Modal.Header>
{    pasos===1 ?(<Modal.Body key={1}>
      <Row className="p-2 mb-1">
        <Col xs={12} className='text-center'>
          <h1 className='transicion'>1</h1>
          <h5 className=''>ANTECEDENTES DEL ASEGURADO</h5>  
        </Col>
        
     
          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Operación">
                <Form.Label>N° de Operación</Form.Label>
                <Form.Control size="sm" type="text" value={(operación)} onChange={(e) => setOperación(e.target.value)} />
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2">
      <Form.Group className={`mb-3 cajasInfo cajaInputModal`} controlId="Rut Asegurado">
        <Form.Label>Rut Asegurado</Form.Label>
        <Form.Control
          size="sm"
          type="text"
          value={rut}
          onChange={handleChange}
          style={{ borderColor: isValid ? 'green' : isValid === false ? 'red' : '#ced4da', borderWidth: '1px' }}
        />
      </Form.Group>
    </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Fecha de Nacimiento">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <DatePicker
                  selected={moment(nacimiento!==null?nacimiento:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                  onChange={setNacimiento} // Handle date selection
                  startDate={startDate}
                  dateFormat="dd / MM / yy" // Format for the displayed date
                  customInput={<GreetingsDateInput />}
                />
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Nombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control size="sm" type="text" value={(nombre)} onChange={(e) => setNombre(e.target.value)} />
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Apellido Paterno">
                <Form.Label>Apellido Paterno</Form.Label>
                <Form.Control size="sm" type="text" value={(paterno)} onChange={(e) => setPaterno(e.target.value)} />
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Apellido Materno">
                <Form.Label>Apellido Materno</Form.Label>
                <Form.Control size="sm" type="text" value={(materno)} onChange={(e) => setMaterno(e.target.value)} />
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal pSelect" controlId="Tipo de Póliza">
                <Form.Label>Aseguradora</Form.Label>
                <select value={poliza} onChange={(e) => cambiarPoliza(e.target.value  )} className="form-control fixSelectControl" id="TPoliza" name="TPoliza">
                        <option value="">Seleccione</option>
                        {
                            listaAseguradora.length>0?listaAseguradora.map((item, key) => (

                         
                            <option key={key} value={item.aseg_id}>{item.aseg_nombre}</option>
                            )):''
                          
                          
                        }
                  </select>
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal pSelect" controlId="Aseguradora">
                <Form.Label>Tipo de Póliza</Form.Label>
                <select disabled value={aseguradora} onChange={(e) => {
                                                              setAseguradora(e.target.value)
                                                              setTPoliza(aseguradora)
                                                              }
                  } className="form-control fixSelectControl" id="TPoliza" name="TPoliza">
                        <option value="">Seleccione</option>
                        {
                            listaPoliza.length>0?listaPoliza.map((item, key) => (

                              <option key={key} value={item.tipo_pol_numpol}>{item.tipo_pol_estado}</option>
                            )):''
                          
                          
                        }
                  </select>
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Póliza">
                <Form.Label>N° de Póliza</Form.Label>
                <Form.Control size="sm" type="text" value={(aseguradora)}   disabled />
              </Form.Group>
              
              
          </Col>
      </Row>
      <Row className="p-2 mb-1">
      <Col xs={12} className='text-center'>   
          <h5 className=''>VALIDACIÓN ESTOYSEGURO.CL</h5>
        </Col>
  
          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Fecha de Recepción del Siniestro">
                <Form.Label>Fecha de Recepción del Siniestro</Form.Label>
                <DatePicker
                  selected={moment(rsiniestro!==null?rsiniestro:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                  onChange={setRSiniestro} // Handle date selection
                  startDate={startDate}
                  dateFormat="dd / MM / yy" // Format for the displayed date
                  customInput={<GreetingsDateInput />}
                />
              </Form.Group>
          </Col>
          
          
          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal pSelect" controlId="E° de Obtención de Antecedentes">
                <Form.Label>E° de Obtención de Antecedentes</Form.Label>
                  <select value={eOAntecedentes} onChange={(e) => setEOAntecedentes(e.target.value)} class="form-control" id="auto_motivo" name="auto_motivo">
                      <option value="">Seleccione</option>

                      <option value={'Entregados'}>Entregados</option>
                      <option value={'Pendiente'}>Pendiente</option>
                  </select>
              </Form.Group>
          </Col>
{
  aseguradora=='11733902'?(
    <Col xs={3} className="p-2 ">
    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Monto Total a Indemnizar">
      <Form.Label>Monto Total a Indemnizar</Form.Label>
      <Form.Control size="sm" type="text" value={(mTIndemnizar)} onChange={(e) => setMTIndemnizar(e.target.value)} />
    </Form.Group>
</Col>
  ):''
}


          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Fecha de denuncia en la Aseguradora">
                <Form.Label>Fecha de denuncia en la Aseguradora</Form.Label>
                <DatePicker
                  selected={moment(fDAseguradora!==null?fDAseguradora:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                  onChange={setFDAseguradora} // Handle date selection
                  startDate={startDate}
                  dateFormat="dd / MM / yy" // Format for the displayed date
                  customInput={<GreetingsDateInput />}
                />
              </Form.Group>
          </Col>

          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Observaciones">
                <Form.Label>Observaciones</Form.Label>
                <Form.Control size="sm" type="text" value={(Observaciones)} onChange={(e) => setObservaciones(e.target.value)} />
              </Form.Group>
          </Col>

        
          <Col xs={12} className="p-2 ">
                <Button onClick={() => guardarSiniestro(1)} color='primary' style={{float:'right', margin:'1rem'}}>Guardar</Button>
            </Col>
      </Row>
    </Modal.Body>):''
    
  }
  {
    
    pasos===2?(<Modal.Body key={1}>
      <Row className="p-2 mb-1">
      <Col xs={12} className='text-center'>
          <h1 className='transicion'>2</h1>
          <h5 className=''>GESTION DE CUOTAS</h5>
        </Col>
  
          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="E° de la Operación">
                <Form.Label>E° de la Operación</Form.Label>
                
                <select value={edOperacion} onChange={(e) => setEdOperacion(e.target.value)} class="form-control" id="auto_motivo" name="auto_motivo">
                        <option value="">Seleccione</option>
                        {
                            oEOperacion.length>0?oEOperacion.map((item, key) => (

                            <option value={item.est_op_estado}>{item.est_op_estado}</option>
                            )):''
                          
                          
                        }
                  </select>

              </Form.Group>
          </Col>
          <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Siniestro">
                <Form.Label>N° de Siniestro</Form.Label>
                <Form.Control size="sm" type="text" value={(ndSiniestro)} onChange={(e) => setNdSiniestro(e.target.value)} />
              </Form.Group>
          </Col>
          {
            aseguradora!='11733902'?(
          <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Deducible">
                <Form.Label>Deducible</Form.Label>
                <Form.Control size="sm" type="text" value={(Deducible)} onChange={(e) => setDeducible(e.target.value)} />
                </Form.Group>
          </Col>):''
          }
                <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Observaciones">
                <Form.Label>Observaciones</Form.Label>
                <Form.Control size="sm" type="text" value={(Observaciones2)} onChange={(e) => setObservaciones2(e.target.value)} />
              </Form.Group>
          </Col>

         

<hr/>

{
            aseguradora!='11733902'?( 
            <>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Cuota">
                        <Form.Label>N° de Cuota</Form.Label>
                            <p>{obtenerCuotas.length<=0?'No tiene cuotas':obtenerCuotas.length+1}</p>
                        </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Cuota">
                        <Form.Label>Valor Cuota</Form.Label>
                        <Form.Control size="sm" type="text" value={(valorCuota)} onChange={(e) => setValorCuota(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Cuota">
                        <Form.Label>Observaciones de la Cuota</Form.Label>
                        <Form.Control size="sm" type="text" value={(obsCuota)} onChange={(e) => setObsCuota(e.target.value)} />
                    </Form.Group>
                </Col>

                
                
                </>
            ):(
            <>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Cuota">
                        <Form.Label>N° de Cuota</Form.Label>
                        <p>1</p>
                    </Form.Group>
                </Col>
                



            </>
            )
        }


        {
          aseguradora!='11733902'?( 
            <Col xs={12} className="p-2 ">
                  <Button onClick={() => guardarSiniestro(2)} color='primary' style={{float:'right', margin:'1rem'}}>Agregar Cuota</Button>
            </Col>
          ):''}

<Col xs={12} className="p-2 ">
{
  obtenerCuotas.length>0?
  <TablaReact2 columns={columnsTabla} data={obtenerCuotas}/>
  :
  <TablaVacia columns={columnsTabla} />
  
}
</Col>
        
          <Col xs={12} className="p-2 ">
                <Button onClick={() => guardarSiniestro(3)} color='primary' style={{float:'right', margin:'1rem'}}>Guardar</Button>
            </Col>
      </Row>
    </Modal.Body>):''
    }


    {
       pasos===3?(<Modal.Body key={1}>
        <Row className="p-2 mb-1">
        <Col xs={12} className='text-center'>
            <h1 className='transicion'>3</h1>
            <h5 className=''>Cierre Siniestro</h5>
        </Col>
  
  {
              aseguradora=='11733902'?( <>
  
  <Col xs={3} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Observaciones">
                        <Form.Label>Monto Indemnizado</Form.Label>
                        <Form.Control size="sm" type="text" value={(montoIndemnizado)} onChange={(e) => setMontoIndemnizado(e.target.value)} />
                      </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Observaciones">
                        <Form.Label>Monto aportado por el Cliente</Form.Label>
                        <Form.Control size="sm" type="text" value={(montoAportadoCliente)} onChange={(e) => setMontoAportadoCliente(e.target.value)} />
                      </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Observaciones">
                        <Form.Label>Fecha vb tesoreria</Form.Label>
                        <DatePicker
                          selected={moment(fechaVBTesoreria!==null?fechaVBTesoreria:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                          onChange={setFechaVBTesoreria} // Handle date selection
                          startDate={startDate}
                          dateFormat="dd / MM / yy" // Format for the displayed date
                          customInput={<GreetingsDateInput />}
                          />
                      </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Observaciones">
                        <Form.Label>Fecha cierre Siniestro</Form.Label>
                        <DatePicker
                          selected={moment(fechaCierreSiniestro!==null?fechaCierreSiniestro:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                          onChange={setFechaCierreSiniestro} // Handle date selection
                          startDate={startDate}
                          dateFormat="dd / MM / yy" // Format for the displayed date
                          customInput={<GreetingsDateInput />}
                          />
                      </Form.Group>
                  </Col>
  
              </>):''
  }
          
            <Col xs={12} className="p-2 ">
                  <Button onClick={() => guardarSiniestro(4)} color='primary' style={{float:'right', margin:'1rem'}}>Guardar</Button>
              </Col>
        </Row>
      </Modal.Body>):''
    
    }

    </Modal> 
    </div>
  );
};

export default TablaReact;
