import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/common/Flex';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor } from '../../../helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import BasicECharts from '../../../components/common/BasicEChart';
import {
    GridComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import moment from 'moment';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer
]);

const getOptions = (data, radius, total) => ({
    color: [
        getColor(data[0].color),
        getColor(data[1].color)
    ],

    tooltip: {
        padding: [7, 10],
        transitionDuration: 0,
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        formatter: params =>
            `<strong>${params.data.name}:</strong> ${params.data.emisiones}`
    },
    series: [
        {
            name: total + "%",
            type: 'pie',
            radius,
            avoidLabelOverlap: false,
            emphasis: {
                scale: false
            },
            itemStyle: {
                borderWidth: 2,
                borderColor: getColor('gray-100')
            },
            label: {
                show: true,
                position: 'center',
                formatter: '{a}',
                fontSize: 23,
                color: getColor('dark')
            },
            data: [
                {
                    value: data[0].value,
                    emisiones: data[0].value,
                    name: 'Emisiones ' + moment().subtract(1, 'year').format('YYYY')
                },
                {
                    value: data[1].value,
                    emisiones: data[1].value,
                    name: 'Emisiones ' + moment().format('YYYY')
                }
            ]
        }
    ]
});

const VariacionItem = ({ item, index }) => {
    
    const { name, color, value } = item;

    return (
        <Flex
            alignItems="center"
            justifyContent="between"
            className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
        >
            <p className="mb-1">
                <FontAwesomeIcon
                    icon="circle"
                    className={`me-2 text-${color.split('-')[1] || color || 'text-info'}`}
                />
                {name}
            </p>
            <div className="">{value}</div>
        </Flex>
    );
};

const Variacion = ({ data, radius }) => {
    let total = 0
    
    if(data[0].value !== 0 && data[1].value !== 0){
        total = parseInt(((((data[1].value) - (data[0].value)) / (data[0].value)) * 100).toFixed(0));
    }
    
    const anyoActual = moment().format('YY');
    const anyoAnterior = moment().subtract(1, 'year').format('YY');
   
    return (
        <Card className="h-md-100">
            <Card.Body>
                <Row className="justify-content-between g-0">
                    <Col xs={12} sm={6} xxl={6} className="pe-2">
                        <h6 className="mt-1">Variación {anyoAnterior} - {anyoActual}</h6>

                        {data.map((item, index) => (
                            <VariacionItem
                                item={item}
                                index={index}
                                key={index}
                                total={total}
                            />
                        ))}
                    </Col>
                    <Col xs="auto">
                        <div className="ps-0">
                            <BasicECharts
                                echarts={echarts}
                                options={getOptions(data, radius, total)}
                                style={{ width: '6.625rem', height: '6.625rem' }}
                            />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

VariacionItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    }),
    index: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
};

Variacion.propTypes = {
    data: PropTypes.array.isRequired,
    radius: PropTypes.array.isRequired
};

export default Variacion;
