import Flex from '../../../components/common/Flex';
import { Card, Table } from 'react-bootstrap';
import SoftBadge from '../../../components/common/SoftBadge';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { useRenovaciones } from 'hooks';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useGeneralStore } from 'hooks/useGeneralStore';

const MembersRow = ({
  canal,
  conversion,
  ventas,
  cotizaciones,
  ingresos,
  costo_mktg,
  costo_fv,
  resultado,
  roi,
  ufActual
  
}) => {
  let color = 'danger';
  if (conversion <= 5) {
    color = 'danger';
  } else if (conversion >= 6 && conversion <= 8) {
    color = 'warning';
  } else if (conversion >= 9) {
    color = 'success';
  }
  const quitarPuntos = (numero) => {

    if(numero !== undefined && numero !== null){
        return parseInt(numero.replace(/\./g, ""));
    }
    
}
 const formatoMonedaChilena = (numero) => {
    const opciones = { 
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2, 
      useGrouping: true 
    };
    const moneda = new Intl.NumberFormat('es-CL', opciones);
    return moneda.format(numero);
  };

  const formatNumber = (number) => {
    if (number !== undefined && number !== null) {

      const num = parseFloat(number.replace(/\./g, "").replace(",", "."));
      const suffixes = ["", "K", "M", "B", "T", "q", "Q", "s", "S", "o", "n", "d", "U", "D", "T", "qD", "qT", "sD", "sT", "oD", "oT", "nD", "nT", "v"];
      const suffixNum = Math.floor(("" + num).split(".")[0].length / 3);
      let shortNum = parseFloat((suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toFixed(1));
      if (shortNum % 1 !== 0) {
        shortNum = shortNum.toFixed(1).replace(/\.0$/, "");
      }

      return shortNum +' '+suffixes[suffixNum];
    }
  };
  return (
    <tr className='border-bottom border-200'>
      <td>
        <Flex alignItems="center" className="position-relative">
          <div className="ms-3">
            <h6 className="mb-0 fw-semi-bold">{canal}</h6>
          </div>
        </Flex>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <SoftBadge pill bg={color}>
          {conversion?.toFixed(1)}%
        </SoftBadge>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <p className="fs--2 mb-0">{ventas}</p>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <p className="fs--2 mb-0">{cotizaciones}</p>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <p className="fs--2 mb-0">UF {ingresos?.toFixed(2)}</p>
        <p className="fs--2 mb-0">{ufActual!==undefined && ingresos!==undefined && ufActual!==null?formatNumber(formatoMonedaChilena(ingresos*quitarPuntos(ufActual))):''}</p>
        
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <p className="fs--2 mb-0">$ {formatNumber(formatoMonedaChilena(costo_mktg))}</p>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <p className="fs--2 mb-0">$ {formatNumber(formatoMonedaChilena(costo_fv))}</p>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <p className="fs--2 mb-0">$ {resultado>=0?'':'-'}{formatNumber(formatoMonedaChilena(resultado>0?resultado:resultado*-1))}</p>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        <p className="fs--2 mb-0">{roi}</p>
      </td>
    </tr>
  );
};

const RetornoInversion = (args) => {
  const [ejecutivoSeleccionado, setEjecutivoSeleccionado] = useState(moment().format("M"));

  const [data, setData] = useState([])

  useEffect(() => {
    setData(args);

  }, [args]);


  // Crear un array de objetos con los nombres de los meses
  const meses = [];

  for (let i = 0; i < 12; i++) {
    const mes = moment().month(i);
    meses.push({
      id: i + 1,
      nombre: mes.format('MMMM').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    });
  }

  const {
    startTablaRI,
  } = useGeneralStore();

  const handleChange = async (event) => {
    if (args.nombre === "Anulacion") {
      let selectedValue = event.target.value;
      setEjecutivoSeleccionado(selectedValue);
      let nuevoRanking = await startTablaRI(selectedValue)

      setData(nuevoRanking)
    } else {
      let selectedValue = event.target.value;
      setEjecutivoSeleccionado(selectedValue);
      let nuevoRanking = await startTablaRI(selectedValue)
      setData(nuevoRanking)
    }

  };




  return (
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-5 mt-2">{data.nombre}</h6>
        <select value={ejecutivoSeleccionado} onChange={handleChange}>
          {meses.map((ejecutivo) => (
            <option key={ejecutivo.id} value={ejecutivo.id}>
              {ejecutivo.nombre}
            </option>
          ))}
        </select>
      </Card.Header>
      <Card.Body className="p-0">
        <SimpleBarReact>
          <Table
            borderless
            className="mb-0 fs--1 border-200 rounded-3 table-dashboard"
          >
            <thead className="bg-light">
              <tr className="text-900">
                <th>Canal</th>
                <th className="text-center">Conversion</th>
                <th className="text-center">Ventas</th>
                <th className="text-center">Cotizaciones</th>
                <th className="text-center">Ingresos</th>
                <th className="text-center">Costo Mktg</th>
                <th className="text-center">Costo F. Venta</th>
                <th className="text-center">Resultado</th>
                <th className="text-center">ROI</th>
              </tr>
            </thead>
            <tbody>
              {data.data?.map((ejecutivo, index) => (
                <MembersRow key={index}
                  {...ejecutivo}
                  nombreTabla={args.nombre}
                  ufActual = {data.ufActual}
                  isLast={index === data.length - 1}
                />
              ))}
            </tbody>
          </Table>
        </SimpleBarReact>
      </Card.Body>
    </Card>
  );
};

export default RetornoInversion;
