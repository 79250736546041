import usuariosAPI from "api/usuariosAPI";

export const useUsuariosStore = () => {
    const startGetUsuarios = async () => {
        try {
            const { data } = await usuariosAPI.post('/getUsuarios');
            return data;
        } catch (error) {
            console.log(error.data);
            return error.data;
        }
    }

    const startUpdateUsuario = async (formData) => {
        try {
            const { data } = await usuariosAPI.put(
                `/updateUsuario/${formData.u_id}`, {
                u_usuario: formData.u_usuario,
                u_nombre: formData.u_nombre,
                u_email: formData.u_email,
                password: formData.password,
                userRole: formData.userRole,
                ejec_eq: formData.ejec_eq,
                id_ejec: formData.id_ejec,
                eje_por: parseInt(formData.eje_por)
                
            });
            return data;
        } catch (error) {
            console.log(error.data);
            return error.data;
        }
    }

    const startDeleteUsuario = async (userId) => {
        try {
            const { data } = await usuariosAPI.delete(
                `/deleteUsuario/${userId}`);
            return data;
        } catch (error) {
            console.error(error.data);
            return error.data;
        }
    }

    const startGetRoles = async () => {
        try {
            const { data } = await usuariosAPI.post('/getRoles');
            return data;
        } catch (error) {
            console.log(error.data);
            return error.data;
        }
    }

    const startGetNUsuario = async () => {
        try {
            const { data } = await usuariosAPI.post('/getNUsuario');
            return data;
        } catch (error) {
            console.log(error.data);
            return error.data;
        }
    }

    const startCrearUsuarioYejecutivo = async (u_nombre, u_usuario, u_email, u_clave, ejec_eq, u_rol) => {
        try {
            const { data } = await usuariosAPI.post('/CrearUsuarioYejecutivo',{u_nombre, u_usuario, u_email, u_clave, ejec_eq, u_rol});
            return data;
        } catch (error) {
            console.log(error.data);
            return error.data;
        }
    }

    const startUpdateEstado = async (u_nombre, estado) => {
        try {
            const { data } = await usuariosAPI.post('/updateEstado',{u_nombre, estado});
            return data;
        } catch (error) {
            console.log(error.data);
            return error.data;
        }
    }

    const startGetEquipo = async () => {
        try {
            const { data } = await usuariosAPI.post('/getEquipo');
            return data;
        } catch (error) {
            console.log(error.data);
            return error.data;
        }
    }

    

    


    
    

    return { 
        startGetUsuarios,
        startUpdateUsuario,
        startDeleteUsuario,
        startGetRoles,
        startCrearUsuarioYejecutivo,
        startGetNUsuario,
        startUpdateEstado,
        startGetEquipo 
        }
}