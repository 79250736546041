
import siniestrosAPI from "api/siniestrosAPI";
import { useSelector, useDispatch } from "react-redux";

export const useKPICesantiaStore = () => {

    const dispatch = useDispatch();

    const startObtenerSiniestrosKPICesantia = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestrosKPICesantia', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerSiniestrosKPIE3Cesantia = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestrosKPIE3Cesantia', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerCircleGrafCesantia = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerCircleGrafCesantia', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }


    const startObtenerCircleGraf2Cesantia = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerCircleGraf2Cesantia', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }


    const startDiasAVGCesantia = async (dataInicio, dataFinal,op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGCesantia', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }
    


    const startDiasAVGDenunciaPagoCesantia = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGDenunciaPagoCesantia', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startDiasAVGPagoVBTCesantia = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGPagoVBTCesantia', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startDiasAVGBTCierreCesantia = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGBTCierreCesantia', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startMontoIndemnizadoCesantia = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/montoIndemnizadoCesantia', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startmontoAportadoCesantia = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/montoAportadoCesantia', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startSiniestroMesAñoCesantia = async (anno) => {
        try {
            const { data } = await siniestrosAPI.post('/siniestroMesAnnoCesantia',{anno} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startAnnosDataCesantia = async (anno) => {
        try {
            const { data } = await siniestrosAPI.post('/annosDataCesantia' );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    
    
        
    return {
        //Propiedades


        //*Metodos
        startObtenerSiniestrosKPICesantia,
        startObtenerSiniestrosKPIE3Cesantia,
        startObtenerCircleGrafCesantia,
        startObtenerCircleGraf2Cesantia,
        startDiasAVGCesantia,
        startDiasAVGDenunciaPagoCesantia,
        startDiasAVGPagoVBTCesantia,
        startDiasAVGBTCierreCesantia,
        startMontoIndemnizadoCesantia,
        startmontoAportadoCesantia,
        startSiniestroMesAñoCesantia,
        startAnnosDataCesantia

    }
}