import classNames from 'classnames';
import Avatar from '../../../../components/common/Avatar';
import Flex from '../../../../components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import SoftBadge from '../../../../components/common/SoftBadge';
import SimpleBarReact from 'simplebar-react';
import moment from 'moment';
import { useDashboardStore } from 'hooks';
import { useSelector } from 'react-redux';

const MembersRow = ({
    isLast,
    u_nombre,
    num_emisiones,
    num_cotizaciones,
}) => {
    let itemPercentage = 0
    if(num_emisiones !== 0 && num_cotizaciones !== 0){
        itemPercentage = (num_emisiones * 100 / num_cotizaciones).toFixed(1);
    }
    let color = 'danger';

    if (itemPercentage < 5) {
        color = 'danger';
    } else if (itemPercentage >= 5 && itemPercentage <= 6.9) {
        color = 'warning';
    } else if (itemPercentage >= 7 && itemPercentage <= 8.4) {
        color = 'success';
    } else if (itemPercentage >= 8.5) {
        color = 'primary';
    }
    return (
        <tr className={classNames({ 'border-bottom border-200': !isLast })}>
            <td>
                <Flex alignItems="center" className="position-relative">
                    {/* <Avatar
                        className={`status-${status}`}
                        size="2xl"
                        src={img}
                        width="30"
                        alt={name}
                    /> */}
                    <div className="ms-3">
                        <h6 className="mb-0 fw-semi-bold">
                            {u_nombre}
                        </h6>
                        {/* <p className="fs--2 mb-0 text-500">{role}</p> */}
                    </div>
                </Flex>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <SoftBadge pill bg={color}>
                    {itemPercentage}%
                </SoftBadge>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <p className="fs--2 mb-0">{num_cotizaciones}</p>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <p className="fs--2 mb-0">{num_emisiones}</p>
            </td>
        </tr>
    );
};

const RankingMensual = (args) => {
    const [ejecutivoSeleccionado, setEjecutivoSeleccionado] = useState(moment().format("M"));

    const [data, setData] = useState([])

    useEffect(() => {
        setData(args);
      }, [args]);
      
    
    // Crear un array de objetos con los nombres de los meses
    const meses = [];

    for (let i = 0; i < 12; i++) {
    const mes = moment().month(i);
    meses.push({
        id: i + 1,
        nombre: mes.format('MMMM').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    });
    }

    const {
        startRankingMensual,
    } = useDashboardStore();
    const { user } = useSelector(state => state.login);
    const handleChange = async(event) => {
      const selectedValue = event.target.value;
      setEjecutivoSeleccionado(selectedValue);
      const nuevoRanking = await startRankingMensual(selectedValue,user.uid)
  
        if(nuevoRanking){
            setData(nuevoRanking)
        }else{
            setData([])
        }
    };
  
    return (
        <Card className="h-100">
            <Card.Header className="pb-0">
                <h6>Ranking Mensual</h6>
                <select value={ejecutivoSeleccionado} onChange={handleChange}>
                    {meses.map((ejecutivo) => (
                    <option key={ejecutivo.id} value={ejecutivo.id}>
                        {ejecutivo.nombre}
                    </option>
                    ))}
                </select>
            </Card.Header>
            <Card.Body className="p-0">
                <SimpleBarReact>
                    <Table
                        borderless
                        className="mb-0 fs--1 border-200 rounded-3 table-dashboard"
                        size='sm'
                    >
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th>Nombre</th>
                                <th className="text-center">Rendimiento</th>
                                <th className="text-center">Cotizaciones</th>
                                <th className="text-center">Ventas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data?.map((ejecutivo, index) => (
                                <MembersRow
                                    key={index}
                                    {...ejecutivo}
                                    isLast={index === data.length - 1}
                                />
                            ))}
                        </tbody>
                    </Table>
                </SimpleBarReact>
            </Card.Body>
        </Card>
    );
};

export default RankingMensual;