import { chartJsDefaultTooltip } from '../../../../helpers/chartjs-utils';
import { getColor } from '../../../../helpers/utils';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const RendimientoChart = (args) => {
    const options = {
        rotation: -90,
        circumference: '180',
        cutout: '80%',
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.label + ' : ' + context.parsed;
                    },
                },
            },
        }
    };
    const data = {
        labels: ['Ventas', 'Cotizaciones'],
        datasets: [
            {
                data: [args.data.emisiones, args.data.cotizaciones],
                backgroundColor: [getColor(args.color), getColor('gray-300')],
                borderColor: [getColor(args.color), getColor('gray-300')]
            }
        ]
    };
    return (
        <Doughnut data={data} options={options} width="133" className="my-n5" />
    );
};

export default RendimientoChart;