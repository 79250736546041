import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import CumplimientoChart from './CumplimimientoChart';

const Rendimiento = ({ data }) => {
  
    let conversionVenta = 0
    if(data.totalRenovadas !== 0 && data.A_Renovar !== 0){
   
        conversionVenta = 100-( parseInt(data.totalRenovadas)*100 / (parseInt(data.A_Renovar)+parseInt(data.totalRenovadas))).toFixed(2);
    }


    return (
        <Card className="h-md-100">
            <Card.Body as={Flex} className='align-items-start justify-content-between'>
                <div className="mt-4">
                    <h6 className="mb-1 text-700 fw-normal lh-1">{data.A_Renovar}&nbsp;A renovar</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">{data.totalRenovadas}&nbsp;Total renovadas</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1 mr-1">{data.RenovadaActiva}&nbsp;Poliza activa</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1 mr-1">{data.renovadasCambio}&nbsp;Cliente Renovado</h6>
                </div>
                <div>
                <h6 className="mb-1 text-center ">
                        Tasa de conversion
                    </h6>
                <CumplimientoChart size="7rem" fontsize="25" data={[
                        { id: 1, value: parseFloat(conversionVenta), name: 'Objetivo', color:'gray-300' },
                        { id: 2, value: (parseFloat(conversionVenta)).toFixed(1), name: 'Ventas', color: 'success' }
                        ]} radius={['100%', '80%']}
                    />
                   
                </div>
            </Card.Body>
        </Card>
    );
};

export default Rendimiento;
