import { getColor } from '../../../helpers/utils';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const RendimientoChart = ({ color, conversionVenta }) => {
    const options = {
        rotation: -90,
        circumference: '180',
        cutout: '80%',
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if (context.label === 'Conversion') {
                            return context.parsed + "%";
                        } else {
                            return (20 - context.dataset.data[0]).toFixed(2) + "%";
                        }
                    }
                }
            }
        }
    };
    const data = {
        labels: ['Conversion'],
        datasets: [
            {
                data: [conversionVenta, 20],
                backgroundColor: [getColor(color), getColor('gray-300')],
                borderColor: [getColor(color), getColor('gray-300')]
            }
        ]
    };
    return (
        <Doughnut data={data} options={options} width="133" className="my-n5" />
    );
};

export default RendimientoChart;
