
import siniestrosAPI from "api/siniestrosAPI";
import { useSelector, useDispatch } from "react-redux";

export const useSiniestrosStore = () => {

    const dispatch = useDispatch();

    const startObtenerSiniestros = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestros', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerSiniestrosByID = async (op_id) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestrosID', { op_id } );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    

    const startObtenerMetricasInspeccion = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerMetricasInspeccion', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startEstadosOperacion = async () => {
        try {
            const { data } = await siniestrosAPI.post('/estadosOperacion',  );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }


    const startGuardarValEs = async (op_id, op_fecha_recepcion, op_est_obtencion_ant, op_total_indemnizar, op_ingresado, op_observaciones) => {
        try {
            const { data } = await siniestrosAPI.post('/guardarValEs', { op_id, op_fecha_recepcion,
                op_est_obtencion_ant,
                op_total_indemnizar,
                op_ingresado,
                op_observaciones} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startGuardarValAseg = async (op_id, op_est_operacion,op_num_siniestro, op_fecha_pago_rechazo, op_num_cuota, op_deducible, op_motivo_rechazo) => {
        try {
            const { data } = await siniestrosAPI.post('/guardarValAseg', { op_id, op_est_operacion,op_num_siniestro,
                op_fecha_pago_rechazo,
                op_num_cuota,
                op_deducible,
                op_motivo_rechazo} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    
    const startGuardarCierreMC = async (op_id, op_monto_aportado_cliente, op_fecha_vb_tesoreria, op_fecha_cierre_siniestro) => {
        try {
            const { data } = await siniestrosAPI.post('/guardarCierreMC', { op_id, op_monto_aportado_cliente, op_fecha_vb_tesoreria, op_fecha_cierre_siniestro} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startObtenerSiniestroAseg = async () => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestroAseg',  );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startObtenerTpoPoliza = async () => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerTpoPoliza',  );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startObtenerEstadoOperacion = async () => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerEstadoOperacion',  );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    
    
    const startObtenerCuotas = async (op_num) => {
        try {
            const { data } = await siniestrosAPI.post('/ObtenerCuotas', {op_num} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }  


    const startInsertarCuota = async (op_num, e_operacion, n_cuota, deducible, observaciones) => {
        try {
            const { data } = await siniestrosAPI.post('/insertarCuota', {op_num, e_operacion, n_cuota, deducible, observaciones} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }  

    const startCambiarEstadoCuota = async (id, estado) => {
        try {
            const { data } = await siniestrosAPI.post('/cambiarEstadoCuota', {id, estado} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }  



    
    


    const startInsertarSiniestros = async (op_num, op_aseg_rut, op_tipo_poliza, op_aseguradora, op_num_poliza, op_fecha_recepcion, op_est_obtencion_ant, op_fecha_obtencion_antecedentes, op_observaciones,op_total_indemnizar, nacimiento, nombre, paterno, materno) => {
        op_total_indemnizar = op_total_indemnizar!=='' || op_total_indemnizar?op_total_indemnizar:''
        try {
            const { data } = await siniestrosAPI.post('/insertarSiniestros', {op_num, op_aseg_rut, op_tipo_poliza, op_aseguradora, op_num_poliza, op_fecha_recepcion, op_est_obtencion_ant, op_fecha_obtencion_antecedentes, op_observaciones,op_total_indemnizar, nacimiento, nombre, paterno, materno} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }   

    const startInsertarSiniestros2 = async (op_id, op_est_operacion, op_num_siniestro, op_deducible, op_num_cuota, op_motivo_rechazo) => {
        try {
            const { data } = await siniestrosAPI.post('/insertarSiniestros2', {op_id, op_est_operacion, op_num_siniestro, op_deducible, op_num_cuota, op_motivo_rechazo} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }   


    const startInsertarSiniestros3 = async (op_id, op_indemnizado, op_monto_aportado_cliente, op_fecha_vb_tesoreria, op_fecha_cierre_siniestro) => {
        try {
            const { data } = await siniestrosAPI.post('/insertarSiniestros3', {op_id, op_indemnizado, op_monto_aportado_cliente, op_fecha_vb_tesoreria, op_fecha_cierre_siniestro} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }   

    
    
    

    

    
    return {
        //Propiedades


        //*Metodos
        startObtenerSiniestros,
        startObtenerSiniestrosByID,
        startObtenerMetricasInspeccion,

        startGuardarValEs,
        startGuardarValAseg,
        startEstadosOperacion,
        startGuardarCierreMC,

        startObtenerSiniestroAseg,
        startObtenerTpoPoliza,

        startObtenerEstadoOperacion,


        startInsertarSiniestros,
        startInsertarSiniestros2,
        startInsertarSiniestros3,




        startObtenerCuotas,
        startInsertarCuota,
        startCambiarEstadoCuota


    }
}