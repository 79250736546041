import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Form } from 'react-bootstrap';
import { useKPISiniestroStore } from 'hooks/useKPISiniestroStore';
import { useKPICesantiaStore } from 'hooks/useKPICesantiaStore';

const GrafBarraAnno = () => {
  const options = {
    indexAxis: 'x',
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Siniestros mensuales por mes',
      },
    },
  };

  const [selectedYear, setSelectedYear] = useState('2023');
  const { startSiniestroMesAñoCesantia, startAnnosDataCesantia } = useKPICesantiaStore();
  const [arrayAnnos, setarrayAnnos] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await startSiniestroMesAñoCesantia(selectedYear);
        const siniestrosPorMes = response?.data ?? [];

        const dataFormateada = {
          labels: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
          ],
          datasets: [
            {
              label: 'Siniestros mensuales',
              data: siniestrosPorMes.map(item => item.num_siniestros || 0), // Establecer 0 si no hay datos
              backgroundColor: [
                '#2c7be5',
              ],
              borderColor: [
                'black',
              ],
              borderWidth: 1,
            },
          ],
        };
        const response2 = await startAnnosDataCesantia()
        setarrayAnnos(response2.data)

        return dataFormateada;
      } catch (error) {
        console.error('Error al obtener los datos de siniestros mensuales', error);
        return null;
      }
    };

    fetchData().then(data => {
      if (data) {
        setDataFormateada(data);
      }
    });
  }, [selectedYear]); // Actualizar cuando cambie el año seleccionado o el servicio

  const [dataFormateada, setDataFormateada] = useState(null);

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
  };

  return (
    <div>
      <Form.Select aria-label="Default select example" onChange={handleYearChange} value={selectedYear}>
        <option value={''}>Seleccione Año</option>
      {arrayAnnos?arrayAnnos.map((item,key) => (
        <option key={key} value={item.anno}>{item.anno}</option>
      )):''}
      </Form.Select>
      <h3 className="text-primary p-5 gridCenter">EVOLUCIÓN DE SINIESTROS <small>(Q) Total</small></h3>
      {dataFormateada && (
        <Bar data={dataFormateada} options={options} />
      )}
    </div>
  );
};

export default GrafBarraAnno;
