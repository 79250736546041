import { dashboardAPI } from "api";
import { useSelector, useDispatch } from "react-redux";
import { onCotizacionesDash, onEmisionesDash, onCheckingDash, clearErrorMessageDash, errorPost } from '../store/slices/dashboard'

export const useDashboardStore = () => {

    const { status, data, errorMessage, dataEmis } = useSelector(state => state.dashboard)
    const dispatch = useDispatch();


    const startDashboardCotiz = async (id, fechaInicio, fechaFin) => {

        dispatch(onCheckingDash());
        try {

            const { data } = await dashboardAPI.post('/fuerza-de-venta-vendedor/cotizacionesAuto', { id_u: id, fechaInicio, fechaFin });

            dispatch(onCotizacionesDash(data.data[0].numeroCotizaciones));

        } catch (error) {

            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }
    }

    const startDashboardEmis = async (id, fechaInicio, fechaFin) => {

        dispatch(onCheckingDash());
        try {

            const { data } = await dashboardAPI.post('/fuerza-de-venta-vendedor/ventasAuto', { id_u: id, fechaInicio, fechaFin });

            dispatch(onEmisionesDash(data.data));

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }
    }

    const startDashboardRendimientoAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/rendimientoAuto', { fechaDesde, fechaHasta });
            console.log("ejau",data.data)
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startDashboardPendientesAdmin = async () => {
        try {
            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/pendientesAuto');
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startDashboardPendientesEjecutivo = async (id_ejec) => {
        try {
            const { data } = await dashboardAPI.post('/fuerza-de-venta-vendedor/pendientesAutoEjec',{id_ejec});
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startDashboardProduccionAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/produccionAuto', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startDashboardIngresosAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/ingresosAuto', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startEjecutivos = async (fechaInicio, fechaFin) => {
        try {

            const { data } = await dashboardAPI.post('/ejecutivos', { fechaInicio, fechaFin });

            return data.data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }
    }

    const startPInspeccion = async (fechaInicio, fechaFin) => {


        try {

            const { data } = await dashboardAPI.post('/fuerza-de-venta-vendedor/inspeccionAuto', { fechaInicio, fechaFin });

            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }
    }

    const startDashboardVariacion = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/variacionAuto', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startVentasAnio = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/numeroVentasAnio');
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startVentaNuevaEjec = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/ejecutivos12Meses');
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }
    const startEjecutivosList = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await dashboardAPI.get('/fuerza-de-venta-vendedor/ejecutivoList');
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }
    }

    const startRankingAnual = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/rankingAnual');
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startRankingMensual = async (mes, uid) => {
        try {
            const { data } = await dashboardAPI.post('/fuerza-de-venta-admin/rankingMensual',mes ? { mes, uid } : {});
            return data

        } catch (error) {
            console.log("error", error)
        }

    }

    const startRankingSemanal = async (u_nombre, mes) => {
        try {
            const params = {};
            if (u_nombre && mes) {
              params.u_nombre = u_nombre;
              params.mes = mes;
            } else if (u_nombre) {
              params.u_nombre = u_nombre;
            } else if (mes) {
              params.mes = mes;
            }
            const { data } = await dashboardAPI.post(
                '/fuerza-de-venta-admin/rankingSemanal',
                params
            );
            return data;
        } catch (error) {
            console.log("error", error);
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10);
        }
    };





    return {
        //Propiedades
        status,
        errorMessage,
        dataEmis,
        data,

        //*Metodos
        startVentasAnio,
        startPInspeccion,
        startEjecutivos,
        startDashboardVariacion,
        startDashboardRendimientoAdmin,
        startDashboardPendientesAdmin,
        startDashboardEmis,
        startDashboardCotiz,
        startVentaNuevaEjec,
        startRankingAnual,
        startRankingMensual,
        startRankingSemanal,
        startEjecutivosList,
        startDashboardProduccionAdmin,
        startDashboardIngresosAdmin,
        startDashboardPendientesEjecutivo
    }
}