    import React, { useEffect, useState } from 'react';
    import { Col, Row, Card } from 'react-bootstrap';
    import { useSelector } from "react-redux";
    
    import GreetingsDate from '../../../components/dashboards/crm/greetings/GreetingsDate';
    import { useEmisionStore } from 'hooks/useEmisionStore';
    import { useGestionStore } from 'hooks/useGestionStore';
    import TablaSiniestros from 'components/siniestros/home/TablaSiniestros';

import Rendimiento from 'components/siniestros/home/Rendimiento';
import GreetingCard from 'components/dashboards/fuerza-de-venta/GreetingCard';
import SLAseguradoras from 'components/siniestros/home/SLAseguradoras';
import { useSiniestrosStore } from 'hooks/useSiniestrosStore';
    
    
    const Siniestros = () => {
        //Demos
        const [variacionPromise, setVariacionPromise] = useState([
            { id: 1, value: 0, name: `Emisiones año anterior`, color: 'gray-300' },
            { id: 2, value: 0, name: `Emisiones año actual`, color: 'primary' }
        ]);
    
        const [rendimientoPromise, setRendimientoPromise] = useState([
            { ventas: 0 }, { cotizaciones: 0 }
        ]);
    
    
        //States
        const { user } = useSelector(state => state.login);
        const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
        const [tablaRI, setTablaRI] = useState()
    
        const [ejecutivos, setEjecutivos] = useState(null)
        const [bitacora_status, setBitacora_status] = useState()
        const [bitacora_tipo_cotizante, setBitacora_tipo_cotizante] = useState()
    
        const {
            startGeneralVariacion,
            startObtenerCotizaciones,
            startEjecutivosList,
            startBitacora_status,
            startBitacora_tipo_cotizante,
            startGeneralVariacionFV
        } = useGestionStore();
        
        const {
            startObtenerTablaEmision
        } = useEmisionStore();

        const {
            startObtenerMetricasInspeccion
        } = useSiniestrosStore();
        
    
    
        useEffect(async () => {
            if(user.rol === 'admin'){
                let rendimientoPromise = await startObtenerMetricasInspeccion(dataInicio, dataFinal);
                setRendimientoPromise(rendimientoPromise.data);
            }else if(user.rol === '4'){
                let rendimientoPromise = await startObtenerMetricasInspeccion(dataInicio, dataFinal);
                setRendimientoPromise(rendimientoPromise.data);
            }else{
                let rendimientoPromise = await startObtenerMetricasInspeccion(dataInicio, dataFinal,  user.name);
                setRendimientoPromise(rendimientoPromise.data);
            }
    
    
        }, [dataInicio, dataFinal]);
    
        useEffect(async () => {
    
            let ejecutivos = await startEjecutivosList()
            setEjecutivos(ejecutivos.data)
    
            let Bitacora_statu = await startBitacora_status()
            setBitacora_status(Bitacora_statu.data)
    
            let Bitacora_tipo = await startBitacora_tipo_cotizante()
            setBitacora_tipo_cotizante(Bitacora_tipo.data)
    
            
            
            
        }, []);
      
        
    
    
    
        return (
            <>
                <Card className="bg-100 shadow-none border mb-3">
                    <Card.Body>
                        <Row className="g-0 justify-content-between">
                            <Col md="auto" className="mb-3 mb-md-0">
                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                    <Col xs="auto">
                                        <h6 className="text-700 mb-0 text-nowrap">Mostrando información para las fechas:</h6>
                                    </Col>
                                    <Col md="auto">
                                        <GreetingsDate />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row className="g-3 mb-3">
                    <Col xxl={12} xl={12}>
                        <Row className="g-3">
                            <Col xs={6}>
                                <GreetingCard nombre={user.name} />
                            </Col>
                            <Col md={6}>
                                        {rendimientoPromise?<Rendimiento data={rendimientoPromise} />:''}
                            </Col>
                            <Col lg={12}>
                                <Row className="g-3">
                                    <Col md={12}>
                                        
                                            <TablaSiniestros nombre="Emisiones"  />'
                                        
                                    </Col>
                                </Row>
    
    
                            </Col>
    
                            
                        </Row>
                    </Col>
                </Row>
    
            </>
        );
    };

    
export default Siniestros