export const dashboardRoutes = {
    label: 'Dashboard',
    labelDisable: true,
    children: [
      {
        name: 'Dashboard',
        active: true,
        icon: 'BsPieChartFill',
        
        children: [
          {
            name: 'Fuerza de Venta',
            to: '/fuerza-de-venta',
            active: true
          }
        ]
  
      },{
        name: 'Gestión Ventas',
        active: true,
        icon: 'BsCardChecklist',
        
        children: [
          {
            name: 'Cotización',
            to: '/cotizacion',
            exact: true,
            active: true
          },
          {
            name: 'Emisión',
            to: '/emision',
            exact: true,
            active: true
          },
          {
            name: 'Inspección',
            to: '/inspeccion',
            exact: true,
            active: true
          },
        ],
      }
    ]
  };
  
  export default [
    dashboardRoutes,

  ];
  