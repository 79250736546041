import ecomBg from '../../../assets/img/illustrations/bg-wave.png';
import Background from '../../common/Background';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

const GreetingCard = ({ nombre }) => {
  return (
    <Card className="bg-transparent-50 overflow-hidden h-100">
      <Card.Header className="position-relative">
        <Background
          image={ecomBg}
          className="d-none d-md-block bg-card"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1'
          }}
        />
        <div className="position-relative py-3">
          <div>
            <h3 className="text-primary">Hola {nombre}</h3>
            <p className="text-700">
              Bienvenid@ de vuelta
            </p>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};

GreetingCard.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object)
};

export default GreetingCard;
