
import siniestrosAPI from "api/siniestrosAPI";
import { useSelector, useDispatch } from "react-redux";

export const useKPIDesgravamenStore = () => {

    const dispatch = useDispatch();

    const startObtenerSiniestrosKPIDesgravamen = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestrosKPIDesgravamen', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerSiniestrosKPIE3Desgravamen = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerSiniestrosKPIE3Desgravamen', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerCircleGrafDesgravamen = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerCircleGrafDesgravamen', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }


    const startObtenerCircleGraf2Desgravamen = async (dataInicio, dataFinal) => {
        try {
            const { data } = await siniestrosAPI.post('/obtenerCircleGraf2Desgravamen', { dataInicio, dataFinal} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }


    const startDiasAVGDesgravamen = async (dataInicio, dataFinal,op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGDesgravamen', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }
    


    const startDiasAVGDenunciaPagoDesgravamen = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGDenunciaPagoDesgravamen', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startDiasAVGPagoVBTDesgravamen = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGPagoVBTDesgravamen', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startDiasAVGBTCierreDesgravamen = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/diasAVGBTCierreDesgravamen', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startMontoIndemnizadoDesgravamen = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/montoIndemnizadoDesgravamen', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }


    const startmontoAportadoDesgravamen = async (dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion) => {
        try {
            const { data } = await siniestrosAPI.post('/montoAportadoDesgravamen', { dataInicio, dataFinal, op_tipo_poliza, op_aseguradora, op_est_obtencion_ant, op_est_operacion} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startSiniestroMesAñoDesgravamen = async (anno) => {
        try {
            const { data } = await siniestrosAPI.post('/siniestroMesAnnoDesgravamen',{anno} );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    const startAnnosDataDesgravamen = async (anno) => {
        try {
            const { data } = await siniestrosAPI.post('/annosDataDesgravamen' );
            
            return data;
        } catch (error) {
            console.log(error.data);
        }

    }

    
    
        
    return {
        //Propiedades


        //*Metodos
        startObtenerSiniestrosKPIDesgravamen,
        startObtenerSiniestrosKPIE3Desgravamen,
        startObtenerCircleGrafDesgravamen,
        startObtenerCircleGraf2Desgravamen,
        startDiasAVGDesgravamen,
        startDiasAVGDenunciaPagoDesgravamen,
        startDiasAVGPagoVBTDesgravamen,
        startDiasAVGBTCierreDesgravamen,
        startMontoIndemnizadoDesgravamen,
        startmontoAportadoDesgravamen,
        startSiniestroMesAñoDesgravamen,
        startAnnosDataDesgravamen

    }
}