import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { store } from './store'
import { Provider } from 'react-redux'
import { HashRouter } from "react-router-dom";

const App = () => {
  return (
    <Provider store={store}>
      <HashRouter basename={process.env.PUBLIC_URL}>
        <Layout />
      </HashRouter>
    </Provider>

  );
};

export default App;
