import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from '../../common/Flex';
import RendimientoChart from './RendimientoChart';

const Rendimiento = ({ data, titulo = 'titulo' }) => {
    

    if (!data || data.length === 0 || !data[0]) {
        return (
            <Card className="h-md-100">
                <Card.Header className="pb-0">
                    <h6 className="mb-0 mt-2"> {titulo}</h6>
                </Card.Header>
                <Card.Body as={Flex} className='align-items-start justify-content-between'>
                    <div>No hay datos disponibles</div>
                </Card.Body>
            </Card>
        );
    }

    const items = Object.entries(data[0]).map(([key, item], index) => {
  
        if (index !== Object.entries(data[0]).length - 1) {
            return (
                <Row key={index}>
                    <Col xs={2}>
                        <h6 className={`mb-1 text-700  lh-1 `}>
                            {item}
                        </h6>
                    </Col>
                    <Col xs={10}>
                        <h6 className={`mb-1 text-700 fw-normal lh-1 `}>
                            {key}
                        </h6>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row key={index}>
                    <Col xs={2}>
                        <hr />
                        <h6 className={`mb-1 text-700  lh-1 `}>
                            {item}
                        </h6>
                    </Col>
                    <Col xs={10}>
                        <hr />
                        <h6 className={`mb-1 text-700 fw-normal lh-1 `}>
                            {key}
                        </h6>
                    </Col>
                </Row>
            );
        }
    });

    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2"> {titulo}</h6>
            </Card.Header>
            <Card.Body as={Flex} className='align-items-start justify-content-between'>
                <Row>
                    {items}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default Rendimiento;
