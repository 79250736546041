import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import GreetingCard from '../GreetingCard';
import Variacion from './Variacion';
import GreetingsDate from '../../crm/greetings/GreetingsDate';
import Rendimiento from './Rendimiento';
import Pendientes from './Pendientes';
import Produccion from './Produccion';
import Ingresos from './Ingresos';
import VentasVsObjetivo from './VentasVsObjetivo';
import VentasVsEjecutivo from './VentasVsEjecutivo';
import VentasVsAnios from './VentasVsAnios';

import { useSelector } from "react-redux";
import { useDashboardStore, useGrafStore } from 'hooks';
import RankingSimple from './RankingSimple';
import RankingComplejo from './RankingComplejo';
import RankingMensualSimple from './RankingMensualSimple';

const FuerzaDeVenta = () => {
    const [variacionPromise, setVariacionPromise] = useState([
        { id: 1, value: 0, name: `Emisiones año anterior`, color: 'gray-300' },
        { id: 2, value: 0, name: `Emisiones año actual`, color: 'primary' }
    ]);
    const [rendimientoPromise, setRendimientoPromise] = useState([
        { ventas: 0 }, { cotizaciones: 0 }
    ]);
    const [pendientesPromise, setPendientesPromise] = useState([
        { pendientesAcumuladoPropYdoc: 0 },
        { pendientesAcumuladoPago: 0 },
        { pendientesInspeccion: 0 }
    ])
    const [produccionPromise, setProduccionPromise] = useState([
        { primaNetaVendida: 0 },
        { primaNetaPromedio: 0 },
        { primaNetaAnulada: 0 },
        { tasaDeAnulacion: 0 }
    ])
    const [ingresosPromise, setIngresosPromise] = useState([
        { primaNetaVigente: 0 },
        { tasaComisionPromedio: 0 },
        { ingresosNetos: 0 },
        { ingresosNetosEnCLP: 0 },
        { ingresoPromedio: 0 }
    ])
    const {
        startDashboardVariacion,
        startDashboardRendimientoAdmin,
        startDashboardPendientesAdmin,
        startDashboardProduccionAdmin,
        startDashboardIngresosAdmin,
        startVentasAnio,
        startVentaNuevaEjec,
        startRankingAnual,
        startRankingMensual,
        startRankingSemanal
    } = useDashboardStore();
    const { startVentasAnioVsAnterior } = useGrafStore();
    const [ventasObj, setVentasObj] = useState()
    const [actualVsAnteriorGraf, setActualVsAnteriorGraf] = useState()
    const [ventaNuevaEjecutivo, setVentaNuevaEjecutivo] = useState('')
    //States
    const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
 
    const { user } = useSelector(state => state.login);
    const [rankingAnual, setRankingAnual] = useState([])
    const [rankingMensual, setRankingMensual] = useState([])
    const [rankingSemanal, setRankingSemanal] = useState([])
    const [ufActual, setUfActual] = useState()

    useEffect(async () => {
        let variacionPromise = await startDashboardVariacion(dataInicio, dataFinal);
        setVariacionPromise(variacionPromise);
        let rendimientoPromise = await startDashboardRendimientoAdmin(dataInicio, dataFinal);
        setRendimientoPromise(rendimientoPromise);
        let produccionPromise = await startDashboardProduccionAdmin(dataInicio, dataFinal);
        setProduccionPromise(produccionPromise);
        let ingresosPromise = await startDashboardIngresosAdmin(dataInicio, dataFinal);
        setIngresosPromise(ingresosPromise);
    }, [dataInicio, dataFinal]);

    useEffect(async () => {
        let pendientesPromise = await startDashboardPendientesAdmin();
        setPendientesPromise(pendientesPromise);
    }, []);


    //useEffects que solo se activa una vez
    useEffect(() => {
        async function fetchData() {
            const ventasObj = await startVentasAnio();
            setVentasObj(ventasObj);


            const VentasAnioVsAnterior = await startVentasAnioVsAnterior();
            setActualVsAnteriorGraf(VentasAnioVsAnterior)

            const ventaNEject = await startVentaNuevaEjec();


            const groupedResults = ventaNEject.data.reduce((acc, curr) => {
                const { mes, u_nombre, numero_emisiones } = curr;
                const existing = acc.find(item => item.mes === mes);
                if (existing) {
                    existing[u_nombre] = numero_emisiones;
                } else {
                    acc.push({ mes, [u_nombre]: numero_emisiones });
                }
                return acc;
            }, []);

            const finalResults = Object.values(groupedResults.reduce((acc, curr) => {
                const { mes, ...rest } = curr;
                const fecha = new Date(`${mes}-01`);
                const mesAnio = fecha.toLocaleString('default', { month: 'long', year: 'numeric' });
                acc[mes] = { date: mesAnio, ...rest };
                return acc;
            }, {}));

            setVentaNuevaEjecutivo(finalResults)

        }
        fetchData();
    }, []);

    useEffect(async () => {
        let rankingAnual = await startRankingAnual()

        let rankingMensual = await startRankingMensual()

        let rankingSemanal = await startRankingSemanal()
        
        if(rankingAnual){
            setRankingAnual(rankingAnual.data)
            setUfActual(rankingAnual.ufActual)
        }else{
            setRankingAnual(false)
        }
        if(rankingMensual ){
            setRankingMensual(rankingMensual.data)
        }else{
            setRankingMensual(false)
        }
        if(rankingSemanal.data.length !== 0 ){
            setRankingSemanal(rankingSemanal?.data)
        }else{
            setRankingSemanal(false)   
        }

        
        
      
        
        
    }, [dataInicio, dataFinal]);



    return (
        <>
            <Card className="bg-100 shadow-none border mb-3">
                <Card.Body>
                    <Row className="g-0 justify-content-between">
                        <Col md="auto" className="mb-3 mb-md-0">
                            <Row className="g-3 gy-md-0 h-100 align-items-center">
                                <Col xs="auto">
                                    <h6 className="text-700 mb-0 text-nowrap">Mostrando información para las fechas:</h6>
                                </Col>
                                <Col md="auto">
                                    <GreetingsDate />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <Row className="g-3">
                        <Col xs={6}>
                            <GreetingCard nombre={user.name} />
                        </Col>
                        <Col xs={6}>
                            <Variacion data={variacionPromise} radius={['100%', '80%']} />
                        </Col>
                        <Col lg={12}>
                            <Row className="g-3">
                                <Col md={6}>
                                    <Rendimiento data={rendimientoPromise} />
                                </Col>
                                <Col md={6}>
                                    <Pendientes data={pendientesPromise} />
                                </Col>
                                <Col md={6}>
                                    <Produccion data={produccionPromise} />
                                </Col>
                                <Col md={6}>
                                    <Ingresos data={ingresosPromise} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={12} xl={12}>
                    {ventasObj ? (
                        <VentasVsObjetivo ventasObj={ventasObj} />
                    ) : (
                        <p>Cargando ventas vs objetivo...</p>
                    )}
                </Col>
                <Col xxl={6} xl={6}>
                    {actualVsAnteriorGraf ? (
                        <VentasVsAnios ventasObj={actualVsAnteriorGraf} />
                    ) : (
                        <p>Cargando ventas vs años anteriores...</p>
                    )}
                </Col>
                <Col xxl={6} xl={6}>
                    <Row className="g-3">
                        <Col xl={12}>
                            {ventaNuevaEjecutivo ? (
                                <VentasVsEjecutivo data={ventaNuevaEjecutivo} />
                            ) : (
                                <p>Cargando ventas vs ejecutivo...</p>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col xxl={6} xl={6}>
                    {rankingAnual ? (
                        <RankingSimple data={rankingAnual} nombre="Anual" ufActual={ufActual} />
                    ) : (
                        <p>Cargando Ranking Anual...</p>
                    )}

                </Col>

                <Col xxl={6} xl={6}>
                    {rankingMensual? (
                        <RankingMensualSimple data={rankingMensual} nombre="Mensual" ufActual={ufActual} />
                    ) : (
                        <p>No hay data para este mes</p>
                    )}

                </Col>

                <Col xxl={12} xl={12}>
                    {rankingSemanal? (
                        <RankingComplejo data={rankingSemanal} nombre="Semanal" />
                    ) : (
                        <p>No hay data para esta semana</p>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default FuerzaDeVenta;
