import { getDates } from '../../helpers/utils';

export const ventasVsObjetivoChartData = {
    dates: getDates(
      new Date('5-6-2019'),
      new Date('5-6-2021'),
      1000 * 60 * 60 * 24 * 30
    ),
    dataset: {
      revenue: [
        [
          645, 500, 550, 550, 473, 405, 286, 601, 743, 450, 604, 815, 855, 722,
          700, 896, 866, 952, 719, 558, 737, 885, 972, 650, 600
        ],
        [
          440, 250, 270, 400, 175, 180, 200, 400, 600, 380, 340, 550, 650, 450,
          400, 688, 650, 721, 500, 300, 445, 680, 568, 400, 371
        ]
      ],
      users: [
        [
          545, 500, 650, 727, 773, 705, 686, 501, 643, 580, 604, 615, 755, 722,
          727, 816, 836, 952, 719, 758, 937, 785, 872, 850, 800
        ],
        [
          340, 360, 230, 250, 410, 430, 450, 200, 220, 540, 500, 250, 355, 320,
          500, 630, 680, 500, 520, 550, 750, 720, 700, 780, 750
        ]
      ],
      deals: [
        [
          545, 400, 450, 627, 473, 450, 460, 780, 770, 800, 504, 550, 500, 530,
          727, 716, 736, 820, 719, 758, 737, 885, 872, 850, 800
        ],
        [
          245, 300, 450, 427, 273, 250, 260, 580, 570, 500, 402, 450, 400, 330,
          527, 516, 536, 620, 519, 558, 537, 483, 472, 250, 300
        ]
      ],
      profit: [
        [
          545, 400, 450, 627, 673, 605, 686, 501, 843, 518, 504, 715, 955, 622,
          627, 716, 736, 952, 619, 558, 937, 785, 872, 550, 400
        ],
        [
          340, 360, 330, 300, 410, 380, 450, 400, 420, 240, 200, 250, 355, 320,
          500, 630, 680, 400, 420, 450, 650, 620, 700, 450, 340
        ]
      ]
    }
  };

  export const cumplimiento = [
    { id: 1, value: 220, name: 'Objetivo', color: 'gray-300' },
    { id: 2, value: 410, name: 'Ventas', color: 'warning' }
  ]