import { cincoAPI } from "api";


export const useCincoStore = () => {

    const startInsertarFoto = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadMANDATO.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startSubirFactura = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadFACTURA.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startSubirEndoso = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadENDOSO.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startSubirOtro = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadOTROS.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }


    const startPolizaSura = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadSURA.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startPolizaSuraClick = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadSURACLICK.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startPolizaReale = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadREALE.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startPolizaMapfre = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadMAPFRE.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }

    const startPolizaLiberty = async (formData) => {
        try {

            const { data } = await cincoAPI.post('/uploadLIBERTY.php',formData,
             { headers: {
                'Content-Type': 'multipart/form-data'
              }});
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }


    

    

    return {
        //Propiedades
        
        //*Metodos
        startInsertarFoto,
        startSubirFactura,
        startSubirEndoso,
        startSubirOtro,
        startPolizaSura,
        startPolizaSuraClick,
        startPolizaMapfre,
        startPolizaLiberty,
        startPolizaReale
    }
}