import { ventaNuevaAPI } from "api";
import { useSelector, useDispatch } from "react-redux";

export const useVentaNueva = () => {

    const dispatch = useDispatch();

    const startVentaNuevaVariacion = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await ventaNuevaAPI.post('/variacionAutoVentaNueva', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startVentaNuevaRendimientoAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await ventaNuevaAPI.post('/rendimientoAutoVentaNueva', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }
    const startVentaNuevaPendientesAdmin = async () => {
        try {
            const { data } = await ventaNuevaAPI.post('/pendientesAutoVentaNueva');
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startVentaNuevaProduccionAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await ventaNuevaAPI.post('/produccionAutoVentaNueva', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startVentaNuevaIngresosAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await ventaNuevaAPI.post('/ingresosAutoVentaNueva', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGraficoVentas = async () => {
        try {
            const { data } = await ventaNuevaAPI.post('/graficoVentas');
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRankingAnulaciones = async (mes) => {
        try {
            const { data } = await ventaNuevaAPI.post('/dataAnulaciones',mes ? { mes } : {});
            return data.formato;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRankingCancelaciones = async (mes) => {
        try {
            const { data } = await ventaNuevaAPI.post('/dataCancelaciones',mes ? { mes } : {});
            return data.formato;
        } catch (error) {
            console.log(error.data);
        }
    }
    
    const startVentasAnio = async (fechaDesde, fechaHasta) => {
        try {

            const { data } = await ventaNuevaAPI.post('/ventasVSObjetivos');
            return data

        } catch (error) {
            console.log("error", error)
            dispatch(errorPost('Sin data'));
            setTimeout(() => {
                dispatch(clearErrorMessageDash());
            }, 10)

        }

    }
    return {
        //Propiedades


        //*Metodos
        startVentaNuevaVariacion,
        startVentaNuevaRendimientoAdmin,
        startVentaNuevaPendientesAdmin,
        startVentaNuevaProduccionAdmin,
        startVentaNuevaIngresosAdmin,

        startGraficoVentas,
        startRankingAnulaciones,
        startRankingCancelaciones,
        startVentasAnio
    }
}