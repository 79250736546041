import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import merge from 'lodash.merge';
import { getColor } from '../../../../helpers/utils';
import moment from 'moment';

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer]);

const dayjs = require('dayjs');

const getLastYearMonths = () => {
  const currentDate = moment();
  const lastYearStartDate = currentDate.subtract(1, 'years');
  const lastYearMonths = [];
  for (let i = 0; i < 12; i++) {
    const monthName = lastYearStartDate.add(1, 'months').format('MMM YYYY');
    lastYearMonths.push(monthName);
  }
  return lastYearMonths;
};

const getOption = (data) => {
  const lastYearMonths = getLastYearMonths();
  const names = Object.keys(data[0]).filter((key) => key !== 'date');

  const series = names.map((name, index) => ({
    name: name,
    type: 'line',
    smooth: false,
    symbol: 'none',
    lineStyle: {
      width: 3, // se aumentó el ancho de línea a 4
      lineCap: 'butt',
      lineEnd: 'arrow'
    },
    itemStyle: {
      borderWidth: 1,
      borderColor: '#fff',
      color: getColor(`series.${index}`),
    },
    data: data.map((item) => ({
      value: item[name] !== undefined ? item[name] : 0,
    })),
    yAxisIndex: 0,
  }));

  return {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: lastYearMonths,
    },
    yAxis: [
      {
        type: 'value',
        min: 0,
        splitLine: {
          show: true,
        },
      },
    ],
    series,
  };
};


const LineChartComponent = ({ data }) => {
  const chartRef = useRef(null);

  const renderChart = () => {
    if (chartRef.current) {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (chartInstance) {
        chartInstance.dispose(); // Destruye la instancia del gráfico
      }
      const newChartInstance = echarts.init(chartRef.current);

      newChartInstance.setOption(merge(getOption(data))); // Se utiliza merge para combinar la configuración del gráfico con la nueva data
    }
  };

  useEffect(() => {
    renderChart();
    window.addEventListener("resize", renderChart);
    return () => window.removeEventListener("resize", renderChart);
  }, [data]);

  return <div ref={chartRef} style={{ width: '100%', height: '19.5rem' }} />;
};

export default LineChartComponent;
