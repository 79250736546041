import React from 'react';
import { Card } from 'react-bootstrap';
import Flex from '../../common/Flex';
import RendimientoChart from './RendimientoChart';

const Rendimiento = ({ data }) => {


    let total = 0;
    let aprobadas = 0;

    data.forEach((item) => {
        total += item.cantidad_inspecciones;
        if (item.estado_normalizado === "Pagado") {
            aprobadas += item.cantidad_inspecciones;
        }
    });

    let conversionVenta = ((aprobadas * 100) / total).toFixed(1);
    let color = 'primary';

    if (conversionVenta < 5.1) {
        color = 'danger';
    } else if (conversionVenta > 5 && conversionVenta < 7) {
        color = 'warning';
    } else if (conversionVenta >= 7 && conversionVenta < 8.5) {
        color = 'success';
    }
  
    const items = data.map((item, index) => {
        return (
            <div key={index}>
                <h6 className={`mb-1 text-700 fw-normal lh-1 text-${color}`}>
                    {item.cantidad_inspecciones}&nbsp;{item.estado_normalizado}
                </h6>
            </div>
        );
    });

    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2"> Inspecciones</h6>
            </Card.Header>

            <Card.Body as={Flex} className='align-items-start justify-content-between'>
                <div>
                    {items}
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                       {total}&nbsp;Total
                    </h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-${color}`}>{conversionVenta}%</span>&nbsp;Conversión a venta
                    </h6>

                </div>
                <div>
                    <RendimientoChart color={color} conversionVenta={conversionVenta} />
                    <p className="mb-0 mt-4 text-center fs--2 text-500">
                        Rendimiento: <span className="text-800">{conversionVenta}%</span>
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Rendimiento;
