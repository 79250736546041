import { useSelector, useDispatch } from "react-redux";
import { onDataPicker } from '../store/slices/datapicker';
import moment from "moment";

export const useDateStore = () => {
  const dispatch = useDispatch();
  const { fechaInicio, fechaFin } = useSelector(state => state.datapicker);

  const startDataPicker = ({ fechaInicio, fechaFin }) => {
    try {
      const formattedFechaInicio = moment(fechaInicio).format('YYYY-MM-DD')
      const formattedFechaFin = moment(fechaFin).format('YYYY-MM-DD')

      dispatch(onDataPicker({ fechaInicio: formattedFechaInicio, fechaFin: formattedFechaFin }));
    } catch (error) {
      console.log("error date", error);
    }
  };

  return {
    fechaInicio,
    fechaFin,
    startDataPicker,
  };
};
