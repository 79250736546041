import React, { useState, useEffect } from "react";
import { BsFillPencilFill, BsTrashFill, BsToggleOff, BsToggleOn } from "react-icons/bs";

import { Card, Button, Row, Col, Tooltip, OverlayTrigger, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import CargandoTabla from "components/gestion-venta/cotizacion/CargandoTabla";
import TablaReact from "./TablaReact";
import ModalEditUsuarios from "./ModalEditUsuarios";
const { useUsuariosStore } = require("hooks/useUsuariosStore");

const TablaUsuarios = React.memo(({ reload, dataRoles }) => {


    
    const {
        startGetUsuarios, startUpdateUsuario, startDeleteUsuario, startUpdateEstado
    } = useUsuariosStore();
    const memoizedStartGetUsuarios = React.useMemo(
        () => startGetUsuarios, []);
        const [usuariosList, setUsuariosList] = useState([]);
        const [usuariosOriginal, setUsuariosOriginal] = useState([]); // Copia de la lista original
        const [roles, setRoles] = useState([]);
        const [modalRow, setModalRow] = useState(null);
        const [actualizar, setActualizar] = useState(false);
        const [estadoFiltro, setEstadoFiltro] = useState(2); // Estado predeterminado

        
    const handleOpenModalUsuarios = async (row) => {
        setModalRow(row);
    }
    const handleCloseModalUsuarios = async() => {
        setModalRow(null);

    }
// En el useEffect, llamar a fetchAllUsuarios para obtener todos los usuarios al montar el componente
useEffect(() => {
    let isMounted = true;
    if (isMounted) {
        fetchAllUsuarios();
        handleRadioChange(1); // Establecer filtro para mostrar usuarios activos al cargar la página
    }
    return () => {
        isMounted = false;
    };
}, [memoizedStartGetUsuarios, reload, actualizar]);

const fetchAllUsuarios = async () => {
    try {
        const response = await memoizedStartGetUsuarios();
        if (response.ok) {
            const usuarios = response.data;
            const usuariosActivos = filterUsuariosByEstado(usuarios, 1); // Filtrar usuarios activos

            setUsuariosList(usuariosActivos);
            setUsuariosOriginal(usuarios); // Actualizar la lista original
            setRoles(response.roles);
        } else {
            console.log("Error al obtener usuarios", response.msg);
        }
    } catch (error) {
        console.log("Error al obtener usuarios", error);
    }
};




    const handleSaveData = async (formData) => {
     
        try {
            const resp = await startUpdateUsuario(formData);

            if (resp.mensaje) {
                Swal.fire('Listo!', 'Usuario actualizado', 'success');
                setUsuariosList((prevUsuarios) => {
                    if (Array.isArray(prevUsuarios)) {
                        return prevUsuarios.map((user) =>
                            user.u_id === formData.u_id ?
                                { ...user, formData } : user);
                    }
                    return prevUsuarios;
                })
                setActualizar(prev => !prev);

                handleCloseModalUsuarios();
            }
        } catch (error) {
            console.error('Error al actualizar usuario:', error);
        }
    }
    const handleDeleteUser = async (userId) => {
        const confirmed = await Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, bórralo'
        });
        if (confirmed.isConfirmed) {
            try {
                const resp = await startDeleteUsuario(userId);
                if (resp.mensaje) {
                    Swal.fire(
                        '¡Borrado!',
                        'El usuario ha sido eliminado',
                        'success'
                    );
                    setActualizar(prev => !prev);
                    

                }
            } catch (error) {
                console.error('Error al emiminar usuario: ', error);
            }
        }
    }


    const cambiarEstado = async (u_nombre, estado) => {
  
        let temp = await startUpdateEstado(u_nombre, estado)
        if(temp){
            Swal.fire("Usuario Actualizado Correctamente","","success")
            setActualizar(prev => !prev);
        }
    }
    


    const columns = React.useMemo(
        () => [
            {
                Header: 'Nº',
                accessor: 'row_counter'
            },
            {
                Header: 'Codigo',
                accessor: 'ejec_codigo'
            },
            {
                Header: 'Nombre',
                accessor: 'u_nombre'
            },
            {
                Header: 'Email',
                accessor: 'u_email'
            },
            {
                Header: 'Rol',
                accessor: 'u_rol',
                Cell: ({ value }) => {
                    const roleName = dataRoles.find(role => String(role.id) === value)?.descripcion || 'Sin rol';
                    return roleName;
                }
            },
            
            {
                Header: 'Equipo',
                accessor: 'ejec_eq',
            },
            
            {
                Header: 'Acción',
                accessor: 'u_id',
                Cell: ({ row }) => (
                    <Row className="m-0">

                        <Col xs={6}>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={
                                        `tooltip-editar-${row.original.u_id}`
                                    }>Editar
                                    </Tooltip>
                                }>
                                <Button
                                    variant={'primary'}
                                    className='mb-1'
                                    size="sm"
                                    onClick={
                                        () => handleOpenModalUsuarios(row)
                                    }>
                                    <BsFillPencilFill />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={6}>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={
                                        `tooltip-borrar-${row.original.u_id}`
                                    }>Eliminar
                                    </Tooltip>
                                }>
                                <Button
                                    variant={'danger'}
                                    className='mb-1'
                                    size="sm"
                                    onClick={
                                        () => handleDeleteUser(row.original.u_id)
                                    }>
                                    <BsTrashFill />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                ),
            },
            {
                Header: 'Estado',
                accessor: 'ejec_estado',
                textAlign:'center',
                Cell: ({ row }) => {
                    return row.original.ejec_estado=='Activado'?<Button className='mb-1'
                    size="sm" onClick={() => cambiarEstado(row.original.u_nombre, 'Desactivado')} variant={'success'}><BsToggleOn  /></Button>:<Button className='mb-1'
                    size="sm" onClick={() => cambiarEstado(row.original.u_nombre, 'Activado')} variant={'danger'}><BsToggleOff  /></Button>
                }
                
            }
        ]
    );


// Función para filtrar usuarios
const filterUsuariosByEstado = (usuarios, estado) => {
    if (estado === 1) {
        return usuarios.filter((user) => user.ejec_estado === "Activado");
    } else if (estado === 2) {
        return usuariosOriginal; // Devolver la lista original sin filtrar
    }
    // Si el estado no es ni 1 (Activos) ni 2 (Todos), devolvemos una lista vacía
    return [];
};

const handleRadioChange = (estado) => {

    setEstadoFiltro(estado);
    // Filtrar la copia de la lista original según el estado seleccionado
    const filteredUsuarios = filterUsuariosByEstado(usuariosOriginal, estado);

    setUsuariosList(filteredUsuarios);
};

    return (
        <>
            <Card className="h-100">
                <Card.Header className="pb-0">
                    <h6 className="mb-5 mt-2">Usuarios</h6>
                </Card.Header>
                {
                    Object.keys(usuariosList).length > 0 ?
                        <TablaReact
                            Ncolum={20}
                            columns={columns}
                            data={usuariosList}
                            handleOpenModalUsuarios={handleOpenModalUsuarios}
                            onRadioChange={handleRadioChange}
                        /> :
                        <CargandoTabla columns={columns} />
                }
            </Card>
            {modalRow && (
                <ModalEditUsuarios
                    show={true}
                    onHide={handleCloseModalUsuarios}
                    initialData={modalRow.original}
                    onSave={handleSaveData}
                    roles={roles}
                />
            )}


        </>
    );
});

export default TablaUsuarios;