import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import GraficoVentasChart from './GraficoVentasChart';
import CumplimientoChart from './CumplimimientoChart';

const GraficoVentas = (props) => {

  const [ejecutivoSeleccionado, setEjecutivoSeleccionado] = useState('');
  const [dataFiltrada, setDataFiltrada] = useState(props.data);
  const [sumaData, setSumaData] = useState({ [props.nombreVal]: 0, Anuladas: 0, Canceladas: 0 });

  useEffect(() => {
    let sumaTotal = dataFiltrada.reduce((acumulador, objeto) => {
      acumulador[props.nombreVal] += objeto[props.nombreVal];
      acumulador.Anuladas += objeto.Anuladas;
      acumulador.Canceladas += objeto.Canceladas;
      return acumulador;
    }, { [props.nombreVal]: 0, Anuladas: 0, Canceladas: 0 });

    setSumaData(sumaTotal);
  }, [dataFiltrada, props.nombreVal]);

  useEffect(() => {
    if (!ejecutivoSeleccionado) {
      // Si no hay selección en el select, utiliza la data inicial
      setDataFiltrada(props.data);
    } else {
      // Si hay selección en el select, filtra la data correspondiente
      const filteredObjects = props.data.map((item) => ({
        [ejecutivoSeleccionado]: item[ejecutivoSeleccionado],
        mes: item['mes']
      }));
      setDataFiltrada(filteredObjects);
    }
  }, [ejecutivoSeleccionado, props.data]);

  const ejecutivos = Object.keys(props.data[0]).slice(1);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setEjecutivoSeleccionado(selectedValue);
  };

  return (
    <Card className='mt-3'>
      <Card.Header className='pb-0'>
        <div style={{ display: 'flex' }}>
          <h6 className='mb-0 mt-2' style={{ paddingRight: '1rem' }}>
            {props.nombreVal} - Anulaciones - Cancelaciones
          </h6>
          <select value={ejecutivoSeleccionado} onChange={handleChange}>
            <option value=''>Todos</option>
            {ejecutivos.map((ejecutivo) => (
              <option key={ejecutivo} value={ejecutivo}>
                {ejecutivo}
              </option>
            ))}
          </select>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className='g-1 align-items-center'>
          <Col xxl={8} xl={8}>
            <GraficoVentasChart data={dataFiltrada} />
          </Col>
          <Col xxl={2} xl={2}>
            <h6 className='mb-5 mt-2 text-center'>Anulación Acumulada Anual</h6>
            <CumplimientoChart
              data={[
                { id: 1, value: 100 - (sumaData.Anuladas * 100 / sumaData[props.nombreVal]).toFixed(1), name: 'Objetivo', color: 'warning' },
                { id: 2, value: (sumaData.Anuladas * 100 / sumaData[props.nombreVal]).toFixed(1), name: 'Ventas', color: 'gray-300' }
              ]}
              radius={['100%', '80%']}
            />
          </Col>
          <Col xxl={2} xl={2}>
            <h6 className='mb-5 mt-2 text-center'>Cancelación Acumulada Anual</h6>
            <CumplimientoChart
              data={[
                { id: 1, value: 100 - (sumaData.Canceladas * 100 / sumaData[props.nombreVal]).toFixed(1), name: 'Objetivo', color: 'warning' },
                { id: 2, value: (sumaData.Canceladas * 100 / sumaData[props.nombreVal]).toFixed(1), name: 'Ventas', color: 'gray-300' }
              ]}
              radius={['100%', '80%']}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GraficoVentas;
