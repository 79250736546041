import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDateStore } from 'hooks/useDateStore';
import moment from 'moment-timezone';

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div className="position-relative">
    <Form.Control
      size="sm"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {}}
    />
    <FontAwesomeIcon
      icon="calendar-alt"
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const GreetingsDate = () => {
  moment.tz.setDefault('America/Santiago');
  const { startDataPicker } = useDateStore();
  const [startDate, setStartDate] = useState(null); // Inicializar en null
  const [endDate, setEndDate] = useState(null); // Inicializar en null

  useEffect(() => {
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');

    if (storedStartDate && storedEndDate) {
      const parsedStartDate = moment(storedStartDate, 'YYYY-MM-DD');
      const parsedEndDate = moment(storedEndDate, 'YYYY-MM-DD');

      if (parsedStartDate.isValid() && parsedEndDate.isValid()) { // Verificar validez de las fechas
        setStartDate(parsedStartDate.toDate());
        setEndDate(parsedEndDate.toDate());
      }
    }else{
        const inicioFecha = moment().subtract(1, 'months')
        const finFecha = moment()
        
        setStartDate(inicioFecha.toDate());
        setEndDate(finFecha.toDate());
    }
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;

    if (start && end) {
      const formattedStartDate = moment(start).format('YYYY-MM-DD');
      const formattedEndDate = moment(end).format('YYYY-MM-DD');

      startDataPicker({ fechaInicio: formattedStartDate, fechaFin: formattedEndDate });

      localStorage.setItem('startDate', formattedStartDate);
      localStorage.setItem('endDate', formattedEndDate);
    }

    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      formatWeekDay={(day) => day.toString().slice(0, 3)}
      endDate={endDate}
      selectsRange
      dateFormat="MMM dd"
      customInput={<GreetingsDateInput />}
    />
  );
};

GreetingsDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default GreetingsDate;
