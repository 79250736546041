import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import GreetingCard from '../GreetingCard';
import GreetingsDate from '../../crm/greetings/GreetingsDate';
import Rendimiento from './Rendimiento';
import Pendientes from './Pendientes';
import RankingMensual from './RankingMensual';
import { useSelector } from 'react-redux';
import { useDashboardStore } from 'hooks';
import moment from 'moment';

const FuerzaDeVenta = () => {
    const [pendientesPromise, setPendientesPromise] = useState([
        { pendientesAcumuladoPropYdoc: 0 },
        { pendientesAcumuladoPago: 0 },
        { pendientesInspeccion: 0 }
    ]);
    const [ejecutivos, setEjecutivos] = useState([]);
    const [rankingMensual, setRankingMensual] = useState([]);
    const [ufActual, setUfActual] = useState();

    const {
        startDashboardCotiz,
        data,
        dataEmis,
        startDashboardEmis,
        startEjecutivos,
        startDashboardPendientesEjecutivo,
        startRankingMensual,
        startRankingAnual
    } = useDashboardStore();

    const { user } = useSelector(state => state.login);
    const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
  
    useEffect(() => {
        //incializacion de los Slice Redux
        startDashboardCotiz(user.name, dataInicio, dataFinal, user.rol)
        startDashboardEmis(user.name, dataInicio, dataFinal, user.rol)
    }, [dataInicio, dataFinal]);

    useEffect(async () => {
        let ejecutivos = await startEjecutivos(dataInicio, dataFinal);
        setEjecutivos(ejecutivos)
    }, [dataInicio, dataFinal]);

    useEffect(async () => {
        let pendientesPromise = await startDashboardPendientesEjecutivo(user.name, user.rol);

        setPendientesPromise(pendientesPromise);
        let rankingMensual = await startRankingMensual(moment().format('MM'), user.uid);
        setRankingMensual(rankingMensual?.data);
    }, []);

    useEffect(async () => {
        let rankingAnual = await startRankingAnual();
        setUfActual(rankingAnual?.ufActual);
    }, []);

    return (
        <>
            <Card className="bg-100 shadow-none border mb-3">
                <Card.Body>
                    <Row className="g-0 justify-content-between">
                        <Col md="auto" className="mb-3 mb-md-0">
                            <Row className="g-3 gy-md-0 h-100 align-items-center">
                                <Col xs="auto">
                                    <h6 className="text-700 mb-0 text-nowrap">Mostrando información para las fechas:</h6>
                                </Col>
                                <Col md="auto">
                                    <GreetingsDate />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <Row className="g-3">
                        <Col xs={6}>
                            <GreetingCard nombre={user.name} />
                        </Col>
                        <Col xs={6}>
                            <Rendimiento cotizaciones={data} emisiones={dataEmis} />
                        </Col>
                        <Col md={6}>
                            <Pendientes data={pendientesPromise} />
                        </Col>
                        <Col md={6}>
                            <RankingMensual data={rankingMensual} ufActual={ufActual} ejecutivos={ejecutivos} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default FuerzaDeVenta;