import { gestionAPI } from "api";
import { useSelector, useDispatch } from "react-redux";

export const useEmisionStore = () => {

    const dispatch = useDispatch();

    const startObtenerTablaEmision = async (dataInicio, dataFinal, userName) => {
        try {
            const { data } = await gestionAPI.post('/obtenerTablaEmision', userName?{ dataInicio, dataFinal, userName }:{ dataInicio, dataFinal});
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startObtenerEmision = async (emision_id) => {
        try {
            const { data } = await gestionAPI.post('/obtenerEmision', { emision_id });
            return data;
        } catch (error) {
            console.log(error.data);
        }
    }
     

    
    return {
        //Propiedades


        //*Metodos
        startObtenerTablaEmision,
        startObtenerEmision
    }
}