import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor } from '../../../helpers/utils';
import React from 'react';
import BasicECharts from '../../../components/common/BasicEChart';
import {
    GridComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer
]);

const getOptions = (data, radius, fontsize) => ({
    color: [
      getColor(data[0].color),
      getColor(data[1].color)
    ],
    tooltip: {
      show:false,
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params =>
        `<strong>${params.data.name}:</strong> ${params.percent}%`
    },
    series: [
      {
        name: data[1].value+"%",
        type: 'pie',
        radius,
        startAngle: 90, // Ajuste del ángulo inicial
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: fontsize,
          color: getColor('gray')
        },
        data,
        animation: false // Desactivar la animación
      }
    ]
  });
  

const Cumplimiento = ({ data, radius,size='9rem',fontsize=33, color="" }) => {
    return (
        <div className="d-flex justify-content-center">
            <BasicECharts
                echarts={echarts}
                options={getOptions(data, radius, fontsize)}
                style={{ width: size, height: size }}
            />
        </div>
    );
};

export default Cumplimiento;
