import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import merge from 'lodash.merge';
import { getColor } from '../../../helpers/utils';
import moment from 'moment';
import 'moment/locale/es';

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer]);

const getOption = (data) => {
  const names = Object.keys(data[0]).filter((key) => key !== 'mes');

  const series = names.map((name, index) => ({
    name: name,
    type: 'line',
    smooth: false,
    symbol: 'none',
    lineStyle: {
      width: 3,
      lineCap: 'butt',
      lineEnd: 'arrow'
    },
    itemStyle: {
      borderWidth: 1,
      borderColor: '#fff',
      color: getColor(`series.${index}`),
    },
    data: data.map((item) => ({
      value: item[name] !== undefined ? item[name] : 0,
    })),
    yAxisIndex: 0,
  }));

  const xAxisData = data.map((item) => {
    const date = moment(item.mes, 'M');
    return date.format('MMM-YYYY');
  });

  return {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: [
      {
        type: 'value',
        min: 0,
        splitLine: {
          show: true,
        },
      },
    ],
    series,
  };
};

const GraficoVentasChart = ({ data }) => {
  const chartRef = useRef(null);

  const renderChart = () => {
    if (chartRef.current) {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (chartInstance) {
        chartInstance.dispose();
      }
      const newChartInstance = echarts.init(chartRef.current);

      newChartInstance.setOption(merge(getOption(data)));
    }
  };

  useEffect(() => {
    renderChart();
    window.addEventListener("resize", renderChart);
    return () => window.removeEventListener("resize", renderChart);
  }, [data]);

  return <div ref={chartRef} style={{ width: '100%', height: '19.5rem' }} />;
};

export default GraficoVentasChart;
