import { renovacionesAPI } from "api";
import { useSelector, useDispatch } from "react-redux";

export const useRenovaciones = () => {

    const dispatch = useDispatch();

    const startRenovacionVariacion = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await renovacionesAPI.post('/variacionAutoRenovacion', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRenovacionRendimientoAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await renovacionesAPI.post('/rendimientoAutoRenovacion', { fechaDesde, fechaHasta });
       
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }
    const startRenovacionPendientesAdmin = async () => {
        try {
            const { data } = await renovacionesAPI.post('/pendientesAutoRenovacion');
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRenovacionProduccionAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await renovacionesAPI.post('/produccionAutoRenovacion', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRenovacionIngresosAdmin = async (fechaDesde, fechaHasta) => {
        try {
            const { data } = await renovacionesAPI.post('/ingresosAutoRenovacion', { fechaDesde, fechaHasta });
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startGraficoVentas = async () => {
        try {
            const { data } = await renovacionesAPI.post('/graficoVentas');
            return data.data;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRankingAnulaciones = async (mes) => {
        try {
            const { data } = await renovacionesAPI.post('/dataAnulaciones',mes ? { mes } : {});
            return data.formato;
        } catch (error) {
            console.log(error.data);
        }
    }

    const startRankingCancelaciones = async (mes) => {
        try {
            const { data } = await renovacionesAPI.post('/dataCancelaciones',mes ? { mes } : {});
            return data.formato;
        } catch (error) {
            console.log(error.data);
        }
    }
    

    return {
        //Propiedades


        //*Metodos
        startRenovacionVariacion,
        startRenovacionRendimientoAdmin,
        startRenovacionPendientesAdmin,
        startRenovacionProduccionAdmin,
        startRenovacionIngresosAdmin,

        startGraficoVentas,
        startRankingAnulaciones,
        startRankingCancelaciones
    }
}