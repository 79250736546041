import { useGestionStore } from 'hooks/useGestionStore';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import CargandoTabla from './CargandoTabla';
import TablaReact from './TablaReact';
import { Card } from 'react-bootstrap';
import TablaVacia from './TablaVacia';

const TablaInspecciones = ({nombre}) => {
  moment.tz.setDefault('America/Santiago');

  const { user } = useSelector(state => state.login);
  
  const [dataCambio, setDataCambio] = useState([])
  const [metricas, setMetricas] = useState()
  const { dataInicio, dataFinal } = useSelector(state => state.datapicker);

  const {
    startObtenerInspecciones,
    startMetricasInspeccion
    // Otros métodos del useGestionStore
  } = useGestionStore();

  useEffect(async() => {
    // Función para cargar los datos iniciales
    const fetchData = async () => {
      try {

        


        if(user.rol === '1'){
            // Obtener y actualizar las cotizaciones iniciales
            let nuevasCotizaciones = await startObtenerInspecciones(dataInicio, dataFinal);
            if(nuevasCotizaciones){
              const newData = nuevasCotizaciones?.data.map(item => {
                const lowerCaseObject = {};
                for (const key in item) {
                  if (Object.prototype.hasOwnProperty.call(item, key)) {
                    const value = item[key];
                    lowerCaseObject[key] = typeof value === 'string' ? value.toLowerCase() : value;
                  }
                }
                return lowerCaseObject;
              });
  
              setDataCambio(newData);
            }else{
              setDataCambio([]);
            }
        }else if(user.rol === '4'){
             // Obtener y actualizar las cotizaciones iniciales
             let nuevasCotizaciones = await startObtenerInspecciones(dataInicio, dataFinal, user.name);
             if(nuevasCotizaciones){
              const newData = nuevasCotizaciones?.data.map(item => {
                const lowerCaseObject = {};
                for (const key in item) {
                  if (Object.prototype.hasOwnProperty.call(item, key)) {
                    const value = item[key];
                    lowerCaseObject[key] = typeof value === 'string' ? value.toLowerCase() : value;
                  }
                }
                return lowerCaseObject;
              });
  
              setDataCambio(newData);
            }else{
              setDataCambio([]);
            }       
        }else{
            // Obtener y actualizar las cotizaciones iniciales
            let nuevasCotizaciones = await startObtenerInspecciones(dataInicio, dataFinal , user.name);
            if(nuevasCotizaciones){
              const newData = nuevasCotizaciones?.data.map(item => {
                const lowerCaseObject = {};
                for (const key in item) {
                  if (Object.prototype.hasOwnProperty.call(item, key)) {
                    const value = item[key];
                    lowerCaseObject[key] = typeof value === 'string' ? value.toLowerCase() : value;
                  }
                }
                return lowerCaseObject;
              });
  
              setDataCambio(newData);
            }else{
              setDataCambio([]);
            }

        }
       
      } catch (error) {
        console.error('Error al cargar los datos iniciales:', error);
      }
    };

    fetchData();
    
  }, [dataInicio, dataFinal]);


  						 			 	
  const columns = React.useMemo(
    () => [
      {
        Header: 'N° ',
        accessor: 'inspec_id',
      },
      {
        Header: 'Cotización',
        accessor: 'inspec_cotiz_id',
      },
      {
        Header: 'RUT',
        accessor: 'clie_rut',
      },
      {
        Header: 'Nombre',
        accessor: 'clie_nombre',
      },
      {
        Header: 'Apellido',
        accessor: 'clie_paterno',
      },
      
      {
        Header: 'E-Mail',
        accessor: 'clie_email',
      },
      {
        Header: 'Celular',
        accessor: 'clie_celular',
      },
      {
        Header: 'Fecha',
        accessor: 'inspec_fecha',
        Cell: ({ value }) => {

          const fechaHoraOriginal = moment(value,'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
          const DIFF_HORA_GLOBAL = parseInt(process.env.DIFF_HORA_GLOBAL, 10);
          const fechaHoraRestada = fechaHoraOriginal.subtract(DIFF_HORA_GLOBAL, 'hours');
          const fechaHoraFormateada = fechaHoraRestada.format('DD/MM/YYYY HH:mm');
       

          return fechaHoraFormateada
        }, // Formatea la fecha usando moment
      
      },
      {
        Header: 'Patente',
        accessor: 'cotiza_patente',
        textTransform:'uppercase'
        
      },
      {
        Header: 'Aseguradora',
        accessor: 'aseg_nombre',
      },
      {
        Header: 'Estado',
        accessor: 'inspec_estado',
        
      },
      {
        Header: 'Responsable',
        accessor: 'cotiza_responsable',
        
      },
      // ... Add more columns as needed
    ],
    []
  );

  return (
  <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-5 mt-2">{nombre}</h6>
      </Card.Header>
      <Card.Body className="pb-0">
        {
          Object.keys(dataCambio).length?<TablaReact  Ncolum={20}  columns={columns} data={dataCambio}  />:Object.keys(dataCambio).length==0?<TablaVacia columns={columns} />:<CargandoTabla columns={columns} />
        }
      </Card.Body>
  </Card>
  )
}

export default TablaInspecciones