// En el slice (dataSlice.js)
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

// Obtener fechas iniciales desde el localStorage si están disponibles
const storedStartDate = localStorage.getItem('startDate');
const storedEndDate = localStorage.getItem('endDate');
const initialStartDate = storedStartDate
  ? moment(storedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
  : moment().subtract(1, 'months').format('YYYY-MM-DD');
const initialEndDate = storedEndDate
  ? moment(storedEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
  : moment().format('YYYY-MM-DD');

export const dataSlice = createSlice({
  name: 'datapicker',
  initialState: {
    status: 'checking',
    dataInicio: initialStartDate,
    dataFinal: initialEndDate,
  },
  reducers: {
    onDataPicker: (state, { payload }) => {
      state.dataInicio = moment(payload.fechaInicio).format('YYYY-MM-DD');
      state.dataFinal = moment(payload.fechaFin).format('YYYY-MM-DD');
    },
  },
});

export const { onDataPicker } = dataSlice.actions;
