import React from 'react';
import { Card } from 'react-bootstrap';
import Flex from '../../../../components/common/Flex';
import RendimientoChart from './RendimientoChart';

const Rendimiento = ({ data }) => {
    let conversionVenta = 0
    if(data[0].ventas !== 0 && data[1].cotizaciones !== 0){
        conversionVenta = (parseInt(data[0].ventas) * 100 / parseInt(data[1].cotizaciones)).toFixed(2);
    }
     let color = '';
    if (conversionVenta < 5.1) {
        color = 'danger';
    } else if (conversionVenta > 5 && conversionVenta < 7) {
        color = 'warning';
    } else if (conversionVenta >= 7 && conversionVenta < 8.5) {
        color = 'success';
    } else if (conversionVenta >= 8.5) {
        color = 'primary'
    }
    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0 mt-2">Rendimiento</h6>
            </Card.Header>

            <Card.Body as={Flex} className='align-items-start justify-content-between'>
                <div>
                    <h6 className="mb-1 text-700 fw-normal lh-1">{data[0].ventas}&nbsp;Ventas activas</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1 mr-1">{data[1].cotizaciones}&nbsp;Cotizaciones</h6>
                    <h6 className="mb-1 text-700 fw-normal lh-1">
                        <span className={`text-${color}`}>{conversionVenta}%</span>&nbsp;Conversión a venta
                    </h6>
                </div>
                <div>
                    <RendimientoChart color={color} conversionVenta={conversionVenta} />
                    <p className="mb-0 mt-4 text-center fs--2 text-500">
                        Rendimiento: <span className="text-800"> {conversionVenta}% </span>
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Rendimiento;
