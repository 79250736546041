import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate  } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsToggle from '../components/settings-panel/SettingsToggle';
import SettingsPanel from '../components/settings-panel/SettingsPanel';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from '../components/common/Toast';
import AppContext from '../context/Context';

import FuerzaDeVentaAdmin from 'components/dashboards/fuerza-de-venta/admin';
import FuerzaDeVentaVendedor from 'components/dashboards/fuerza-de-venta/vendedor';
import Login from 'components/auth/login/Login';
import { useAuthStore } from 'hooks';
import Renovaciones from 'page/dashboards/renovaciones';
import VentaNueva from 'page/dashboards/venta-nueva';
import General from 'page/dashboards/general';
import FuerzaDeVentaL2p from 'page/dashboards/fuerza-venta-l2p';
import Cotizacion from 'page/gestion-venta/cotizacion';
import Emision from 'page/gestion-venta/emision';
import Inspeccion from 'page/gestion-venta/inspeccion';
import Siniestros from 'page/siniestros/home';
import KPI from 'page/siniestros/kpi';
import KPICesantia from 'page/siniestros/KPICesantia';
import KPIDesgravamen from 'page/siniestros/KPIDesgravamen';
import Usuarios from 'page/usuarios';


const TIEMPO_INACTIVIDAD = 1000 * 60 * 15; // 15 minutos de inactividad

const Layout = () => {



  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);


 
  //manejar variables de entorno
  const { status, checkAuthToken, rol, startLogout } = useAuthStore();


  useEffect(() => {
    
    checkAuthToken()

        // Inicializar la detección de inactividad solo si el usuario está autenticado
        if (status === 'authenticated') {
          const eventos = ['mousemove', 'keydown', 'scroll'];
    
          let temporizadorInactividad = setTimeout(logoutPorInactividad, TIEMPO_INACTIVIDAD);
    
          const resetTemporizador = () => {
            clearTimeout(temporizadorInactividad);
            temporizadorInactividad = setTimeout(logoutPorInactividad, TIEMPO_INACTIVIDAD);
          };
    
          const logoutPorInactividad = () => {
            // Lógica para manejar el cierre de sesión
            console.log('Sesión cerrada por inactividad');
            //logout(); // Esto debería limpiar el estado de la sesión y posiblemente el JWT
            startLogout();
           
          };
    
          eventos.forEach((evento) => {
            window.addEventListener(evento, resetTemporizador, true);
          });
    
          return () => {
            eventos.forEach((evento) => {
              window.removeEventListener(evento, resetTemporizador, true);
            });
            clearTimeout(temporizadorInactividad);
          };
        }
      
  }, [status])
  if(status === 'checking'){
    return(
      <h1>Cargando...</h1>
    )
  }


  return (
    <>
      <Routes>
        {
   
          (status === 'not-authenticated' || status === '')
            ?(
              <Route element={<AuthSimpleLayout />}>
                <Route path="/auth/*" element={<Login />} />
                <Route path="/*" element={<Navigate to="/auth/login" />} />
              </Route>
            )
            :(
              
                
                  rol==="1"?(
                  <Route element={<MainLayout />}>
                    <Route path="/fuerza-de-venta" element={<FuerzaDeVentaAdmin />} />
                    <Route path="/renovaciones" element={<Renovaciones />} />
                    <Route path="/ventaNueva" element={<VentaNueva />} />
                    <Route path="/general" element={<General />} />
                    <Route path="/cotizacion" element={<Cotizacion />} />
                    <Route path="/emision" element={<Emision />} />
                    <Route path="/inspeccion" element={<Inspeccion />} />
                    <Route path="/siniestros" element={<Siniestros />} />
                    <Route path="/KPI" element={<KPI />} />
                    <Route path="/KPICesantia" element={<KPICesantia />} />
                    <Route path="/KPIDesgravamen" element={<KPIDesgravamen />} /> 
                    <Route path="/usuarios" element={<Usuarios />} /> 
                    <Route path="/*" element={<Navigate to="/general" />} />
                    
                  </Route>)
                :
                (<Route element={<MainLayout />}>
                  <Route path="/fuerza-de-venta" element={<FuerzaDeVentaVendedor />} />
                  <Route path="/cotizacion" element={<Cotizacion />} />
                  <Route path="/emision" element={<Emision />} />
                  <Route path="/inspeccion" element={<Inspeccion />} />
                  <Route path="/*" element={<Navigate to="/fuerza-de-venta" />} />
                </Route>)
                

             
            )
        }


        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        {/* <Route path="*" element={<Navigate to="/errors/404" replace />} /> */}
      </Routes>

    </>
  );
};

export default Layout;
