import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useSelector } from "react-redux";

import GreetingsDate from '../../../components/dashboards/crm/greetings/GreetingsDate';
import GreetingCard from 'components/dashboards/fuerza-de-venta/GreetingCard';
import { useSiniestrosStore } from 'hooks/useSiniestrosStore';
import Rendimiento from 'components/siniestros/KPICesantia/Rendimiento';
import GrafCircle from 'components/siniestros/KPICesantia/GrafCircle';
import { useKPISiniestroStore } from 'hooks/useKPISiniestroStore';
import MontosKPI from 'components/siniestros/KPICesantia/MontosKPI';
import GrafBarraAnno from 'components/siniestros/KPICesantia/GrafBarraAnno';
import { useKPICesantiaStore } from 'hooks/useKPICesantiaStore';


const KPICesantia = () => {
    const [rendimientoPromise, setRendimientoPromise] = useState([
        { ventas: 0 }, { cotizaciones: 0 }
    ]);


    //States
    const { user } = useSelector(state => state.login);
    const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
    const {
        startObtenerMetricasInspeccion,
        
    } = useSiniestrosStore();

    useEffect(async () => {
        if(user.rol === 'admin'){
            let rendimientoPromise = await startObtenerMetricasInspeccion(dataInicio, dataFinal);
            setRendimientoPromise(rendimientoPromise.data);
        }else if(user.rol === '4'){
            let rendimientoPromise = await startObtenerMetricasInspeccion(dataInicio, dataFinal);
            setRendimientoPromise(rendimientoPromise.data);
        }else{
            let rendimientoPromise = await startObtenerMetricasInspeccion(dataInicio, dataFinal,  user.name);
            setRendimientoPromise(rendimientoPromise.data);
        }


        let dataTemp = await startObtenerSiniestrosKPICesantia(dataInicio, dataFinal)
        setDataKPICaja(dataTemp.data)
  
  
        let dataTemp2 = await startObtenerSiniestrosKPIE3Cesantia(dataInicio, dataFinal)
        setDataKPICajaE3(dataTemp2.data)
  


    }, [dataInicio, dataFinal]);


    const [dataKPICaja, setDataKPICaja] = useState()
    const [dataKPICajaE3, setDataKPICajaE3] = useState()

    const {
      startObtenerSiniestrosKPICesantia,
      startObtenerSiniestrosKPIE3Cesantia,
      startMontoIndemnizadoCesantia,
      startmontoAportadoCesantia
  } = useKPICesantiaStore();
  
    useEffect(async() => {
      let dataTemp = await startObtenerSiniestrosKPICesantia()
      setDataKPICaja(dataTemp.data)


      let dataTemp2 = await startObtenerSiniestrosKPIE3Cesantia()
      setDataKPICajaE3(dataTemp2.data)



  


      

      
    }, [])

  return (
        <>
            <Card className="bg-100 shadow-none border mb-3">
                <Card.Body>
                    <Row className="g-0 justify-content-between">
                        <Col md="auto" className="mb-3 mb-md-0">
                            <Row className="g-3 gy-md-0 h-100 align-items-center">
                                <Col xs="auto">
                                    <h6 className="text-700 mb-0 text-nowrap">Mostrando información para las fechas:</h6>
                                </Col>
                                <Col md="auto">
                                    <GreetingsDate />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <Row className="g-3">
                        <Col md={6}>
                            {dataKPICaja?<Rendimiento data={dataKPICaja} titulo={'ETAPA 1 - 2'} />:''}
                        </Col>
                        <Col md={6}>
                            {dataKPICaja?<Rendimiento data={dataKPICajaE3} titulo={'ETAPA 3'} />:''}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="g-3 mb-3">
                <Col xxl={12} xl={12}>
                    <GrafCircle />
                </Col>


                <Col xxl={12} xl={12}>
                    <GrafBarraAnno />
                </Col>
            </Row>

        </>)
}

export default KPICesantia