import React, { useState, useEffect, forwardRef } from 'react';
import { Card, Form, Button, Modal, Col, Row, ButtonGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';

import TablaReact from './TablaReact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import CargandoTabla from './CargandoTabla';
import { BsFillFilePdfFill } from "react-icons/bs";

import moment from 'moment-timezone';
import { useSiniestrosStore } from 'hooks/useSiniestrosStore';
import { useUtilStore } from 'hooks/useUtilStore';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import TablaReact2 from './TablaReact2';
import TablaVacia from './TablaVacia';

import { BsCheckCircleFill } from "react-icons/bs";
import { BsFillXCircleFill  } from "react-icons/bs";
const TablaSiniestros = ({ nombre }) => {

  const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Form.Control
        
        ref={ref}
        onClick={onClick}
        value={value}
        className="ps-4"
        onChange={(e) => {
      
        }}
      />
      <FontAwesomeIcon
        icon="calendar-alt"
        className="text-primary position-absolute top-50 translate-middle-y ms-2"
      />
    </div>
  ));



  moment.tz.setDefault('America/Santiago');
  const { dataInicio, dataFinal } = useSelector(state => state.datapicker);
  const { user } = useSelector(state => state.login);

  const {
    startObtenerSiniestros,
    startObtenerSiniestrosByID,
    startGuardarValEs,
    startGuardarValAseg,
    startGuardarCierreMC,
    startEstadosOperacion,
    startObtenerCuotas,
    startInsertarCuota,
    startCambiarEstadoCuota

  } = useSiniestrosStore();

  const [dataSiniestros, setDataSiniestros] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [filterData, setfilterData] = useState([])

  const {
    startufDelDia
  } = useUtilStore();
  const [ufActual, setUfActual] = useState(36500)
  const [obtenerEstados, setObtenerEstados] = useState([])
  useEffect(async() => {
    // Función para cargar los datos iniciales
    const fetchData = async () => {
      try {

        let TempdataSiniestros = await startObtenerSiniestros()
        setDataSiniestros(TempdataSiniestros.data)

        let tempUf = await startufDelDia();
        setUfActual(tempUf.data)
       

        let infoEstados = await startEstadosOperacion()
        setObtenerEstados(infoEstados.data)
   

      } catch (error) {
        console.error('Error al cargar los datos iniciales:', error);
      }
    };

    fetchData();
    
  }, [dataInicio, dataFinal]);


  const columns = React.useMemo(
    () => [
      {
        Header: 'N° de Crédito',
        accessor: 'op_num',
      },
      {
        Header: 'Tipo',
        accessor: 'op_tipo_poliza',
      },
      {
        Header: 'Aseguradora',
        accessor: 'op_aseguradora',
      },
      {
        Header: 'Rut del Asegurado',
        accessor: 'op_aseg_rut',
      },
      {
        Header: 'Fecha de Recepción',
        accessor: 'op_fecha_recepcion',
        Cell: ({ value }) => {

          const fechaHoraOriginal = moment(value,'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
          const DIFF_HORA_GLOBAL = parseInt(process.env.DIFF_HORA_GLOBAL, 10);
          const fechaHoraRestada = fechaHoraOriginal.subtract(DIFF_HORA_GLOBAL, 'hours');
          const fechaHoraFormateada = fechaHoraRestada.format('DD/MM/YYYY HH:mm');
       

          return fechaHoraFormateada
        }, // Formatea la fecha usando moment
      
      },
      {
        Header: 'Fecha de Denuncia',
        accessor: 'op_fecha_obtencion_antecedentes',
        Cell: ({ value }) => {

          const fechaHoraOriginal = moment(value,'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
          const DIFF_HORA_GLOBAL = parseInt(process.env.DIFF_HORA_GLOBAL, 10);
          const fechaHoraRestada = fechaHoraOriginal.subtract(DIFF_HORA_GLOBAL, 'hours');
          const fechaHoraFormateada = fechaHoraRestada.format('DD/MM/YYYY HH:mm');
       

          return fechaHoraFormateada
        }, // Formatea la fecha usando moment
      
      },
      {
        Header: 'Estado de la Operación',
        accessor: 'op_est_operacion',
      },



      {
        Header: 'Acción',
        Cell: ({ row }) => (
          <div className="d-grid">
            <Button variant={row.original.tiene_emision==1?'success':'primary'} className='me-2 mb-1' size="sm" onClick={() => buscarEmis(row.original.op_id)}>Detalle</Button>
            {
              row.original.tiene_pendiente == 1?<Button variant='light' className='me-2 mb-1' size="sm" >Pendiente</Button>:''
            }
            
          </div>

        ),
      },
      // ... Add more columns as needed
    ],
    []
  );

  const columnsTabla = React.useMemo(
    () => [
      {
        Header: 'Estado Cuota',
        accessor: 'e_operacion',
      },
      {
        Header: 'N° Cuota',
        accessor: 'n_cuota',
      },
      {
        
        Header: 'Valor Cuota',
        accessor: 'deducible',
        Cell: ({ row }) => (
          "$"+formatMoneda(row.original.deducible)
        ),
      },
      {
        Header: 'Observaciones',
        accessor: 'observaciones',
      },
      {
        Header: 'Acción',
        Cell: ({ row }) => (
          <div className="flexMagic jc-l">
            {row.original.e_operacion == 'En Proceso'?(<>
              <BsCheckCircleFill className='btn-tabla-noButton cg' onClick={() => cambioEstado(row.original.id, 'Aprobado', row.original.op_num)}/>
              <BsFillXCircleFill  className='btn-tabla-noButton cr' onClick={() => cambioEstado(row.original.id, 'Rechazado', row.original.op_num)} />
            </>):('')}

          </div>
        ),
      },
      // ... Add more columns as needed
    ],
    []
  );

    const formatearHora = (fecha) => {
      
      const fechaHoraOriginal = moment(fecha, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

      const DIFF_HORA_GLOBAL = parseInt(process.env.DIFF_HORA_GLOBAL, 10);
      const fechaHoraRestada = fechaHoraOriginal.subtract(DIFF_HORA_GLOBAL, 'hours');
      const fechaHoraFormateada = fechaHoraRestada.format('DD/MM/YYYY HH:mm');
      return fechaHoraFormateada
    }

    const formatMoneda = (number) => {
      if (number !== undefined) {
          const parts = number.toString().split('.');
          const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          let decimalPart = parts[1] ? ',' + parts[1] : '';
  
          if (decimalPart.length > 1) {
              // Si la longitud del decimal es mayor a 1, redondea a un decimal
              decimalPart = ',' + parseFloat('0.' + parts[1]).toFixed(1).split('.')[1];
          }
  
          return integerPart + decimalPart;
      }
    }

    const [op_fecha_recepcion, setOp_fecha_recepcion] = useState(0)
    const [op_num_siniestro, setOp_num_siniestro] = useState('')
    const [op_total_indemnizar, setOp_total_indemnizar] = useState('')
    const [op_ingresado, setOp_ingresado] = useState('')
    const [op_observaciones, setOp_observaciones] = useState('')
    const [op_est_operacion, setOp_est_operacion] = useState('')
    const [op_fecha_pago_rechazo, setOp_fecha_pago_rechazo] = useState('')
    const [op_num_cuota, setOp_num_cuota] = useState('')
    const [op_deducible, setOp_deducible] = useState('')
    const [op_motivo_rechazo, setOp_motivo_rechazo] = useState('')
    const [op_monto_aportado_cliente, setOp_monto_aportado_cliente] = useState('')
    const [op_fecha_vb_tesoreria, setOp_fecha_vb_tesoreria] = useState('')
    const [op_fecha_cierre_siniestro, setOp_fecha_cierre_siniestro] = useState('')
    const [startDate, setStartDate] = useState('');
    const [op_est_obtencion_ant, setOp_est_obtencion_ant] = useState('')


    const [obtenerCuotas, setObtenerCuotas] = useState([])


    const [op_num, setOp_num] = useState('')
    const [estadoOperacion, setEstadoOperacion] = useState('')
    const [nCuotas, setNCuotas] = useState('')
    const [valorCuota, setValorCuota] = useState('')
    const [obsCuota, setObsCuota] = useState('')



  const buscarEmis = async(op_id) =>{
    setModalShow(true)

    const dataInfo = await startObtenerSiniestrosByID(op_id)


    let temp1 = await startObtenerCuotas(op_id)
    if(temp1){
      setObtenerCuotas(temp1.data)
    }else{
      setObtenerCuotas([])
    }
    


    setOp_est_obtencion_ant(dataInfo.data[0].op_est_obtencion_ant)
    setfilterData(dataInfo.data[0])
    setOp_fecha_recepcion(dataInfo.data[0].op_fecha_recepcion)
    setOp_num_siniestro(dataInfo.data[0].op_num_siniestro)
    setOp_total_indemnizar(dataInfo.data[0].op_total_indemnizar)
    setOp_ingresado(dataInfo.data[0].op_ingresado)
    setOp_observaciones(dataInfo.data[0].op_observaciones)
    setOp_est_operacion(dataInfo.data[0].op_est_operacion)

    
    setOp_fecha_pago_rechazo(dataInfo.data[0].op_fecha_pago_rechazo)
    setOp_num_cuota(dataInfo.data[0].op_num_cuota)
    setOp_deducible(dataInfo.data[0].op_deducible)
    setOp_motivo_rechazo(dataInfo.data[0].op_motivo_rechazo)
    setOp_monto_aportado_cliente(dataInfo.data[0].op_monto_aportado_cliente)
    setOp_fecha_vb_tesoreria(dataInfo.data[0].op_fecha_vb_tesoreria)
    setOp_fecha_cierre_siniestro(dataInfo.data[0].op_fecha_cierre_siniestro)


    setOp_num(dataInfo.data[0].op_id)
    setEstadoOperacion(dataInfo.data[0].op_est_operacion)
    temp1?
    setNCuotas(temp1.data.length+1)
    :setNCuotas([])
    
  }

  const guardarValES = async(op_id) => {
    const respGuardar = await startGuardarValEs(op_id,op_fecha_recepcion,
    op_est_obtencion_ant,
    op_total_indemnizar,
    op_ingresado,
    op_observaciones)
  
    if(respGuardar.data.affectedRows>0){
      Swal.fire("Datos Modificados Correctamente","","success")
      let TempdataSiniestros = await startObtenerSiniestros()
      setDataSiniestros(TempdataSiniestros.data)
    }
  }

  const guardarValAseg = async(op_id) => {
    const respGuardar = await startGuardarValAseg(op_id, op_est_operacion,op_num_siniestro,
    op_fecha_pago_rechazo,
    op_num_cuota,
    op_deducible,
    op_motivo_rechazo)

    if(respGuardar.data.affectedRows>0){
      Swal.fire("Datos Modificados Correctamente","","success")
      let TempdataSiniestros = await startObtenerSiniestros()
      setDataSiniestros(TempdataSiniestros.data)
    }
  }


  const guardarCierreMC = async(op_id) => {
    const respGuardar = await startGuardarCierreMC(op_id, op_monto_aportado_cliente,
      op_fecha_vb_tesoreria,
      op_fecha_cierre_siniestro)

    if(respGuardar.data.affectedRows>0){
      Swal.fire("Datos Modificados Correctamente","","success")
      let TempdataSiniestros = await startObtenerSiniestros()
      setDataSiniestros(TempdataSiniestros.data)
    }
  }
  

  
  
  const handleClose = () => {
    setModalShow(false)
    setfilterData([])
    setNCuotas([])
  }
 
  const GuardarCuota = async() =>{
 

    if(op_num, estadoOperacion, nCuotas, valorCuota, obsCuota){
    let tempInsert2 = await startInsertarCuota(op_num, 'En Proceso', nCuotas==0?1:nCuotas, valorCuota, obsCuota)
  
    if(tempInsert2.ok){
      Swal.fire({
        title: "Cuota creada correctamente",
        text: "¿Desea ingresar otra cuota?",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Crear otra cuota"
      }).then(async(result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let tempData4 = await startObtenerCuotas(op_num);
          if(tempData4){
            setObtenerCuotas(tempData4.data)
          }else{
            setObtenerCuotas([])
          }
        }else{
          let tempData4 = await startObtenerCuotas(op_num);
          if(tempData4){
            setObtenerCuotas(tempData4.data)
          }else{
            setObtenerCuotas([])
          }
        }
      });
    }

  }
  }

  const cambioEstado = (id, estado, idPoliza) =>{
    Swal.fire({
      title: "¿Esta seguro que desea cambiar el estado?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Cambiar Estado"
    }).then(async(result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let temp0 = await startCambiarEstadoCuota(id,estado)
        let temp1 = await startObtenerCuotas(idPoliza)
        if(temp1){
          setObtenerCuotas(temp1.data)
        }else{
          setObtenerCuotas([])
        }
      } else {
       
      }
    });
  }


  return (
    <>
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-5 mt-2"></h6>
      </Card.Header>
      {
         Object.keys(dataSiniestros).length>0?<TablaReact  Ncolum={20}  columns={columns} data={dataSiniestros} />:<CargandoTabla columns={columns} />
      }
      
    </Card>

     <Modal
    show={modalShow}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={handleClose}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">ANTECEDENTES DEL ASEGURADO</Modal.Title>
    </Modal.Header>
    <Modal.Body key={1}>
    <Row className="p-2 mb-1">
      {
        
        Object.keys(filterData).length>0?(
          <>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Operación">
                  <Form.Label>N° de Operación</Form.Label>
                  <p> {filterData.op_num} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="EmisionID">
                  <Form.Label>Rut Asegurado</Form.Label>
                  <p> {filterData.op_aseg_rut} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Poliza">
                  <Form.Label>Fecha de Nacimiento</Form.Label>
                  <p> {filterData.op_nac==''?moment(filterData.clie_fechnac).format("DD/MM/YYYY"):moment(filterData.op_nac).format("DD/MM/YYYY")} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Nombre</Form.Label>
                  <p> {filterData.op_nombre==''?filterData.clie_nombre:filterData.op_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="RUT">
                  <Form.Label>Apellido Paterno</Form.Label>
                 
                  <p> {filterData.op_paterno==''?filterData.clie_paterno:filterData.op_paterno} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Compañia">
                  <Form.Label>Apellido Materno</Form.Label>
                  <p> {filterData.op_materno==''?filterData.clie_materno:filterData.op_materno} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Tipo de Póliza</Form.Label>
                  <p> {filterData.op_tipo_poliza} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Aseguradora</Form.Label>
                  <p> {filterData.op_aseguradora} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>N° de Póliza</Form.Label>
                  <p> {filterData.op_num_poliza} </p>
                </Form.Group>
            </Col>


            <hr/>
            <h5 className=''>VALIDACIÓN ESTOYSEGURO.CL</h5>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal cajaInputModal" controlId="Patente">
                  <Form.Label>Fecha de Recepción del Siniestro</Form.Label>
             
                  <DatePicker
                  selected={moment(op_fecha_recepcion!==null?op_fecha_recepcion:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                  onChange={setOp_fecha_recepcion} // Handle date selection
                  startDate={startDate}
                  dateFormat="dd / MM / yy" // Format for the displayed date
                  customInput={<GreetingsDateInput />}
                />
                  
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>E° de Obtención de Antecedentes</Form.Label>
                  <Form.Control size="sm" type="text" value={op_est_obtencion_ant} onChange={(e) => setOp_est_obtencion_ant(e.target.value)} />
                </Form.Group>
            </Col>
            {
              filterData.op_tipo_poliza!='Desgravamen CV'?( <Col xs={3} className="p-2 ">
              <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                <Form.Label>Monto Total a Indemnizar</Form.Label>
                <Form.Control size="sm" type="text" value={(op_total_indemnizar)} onChange={(e) => setOp_total_indemnizar(e.target.value)} />
              </Form.Group>
          </Col>):''
            }
            
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Fecha de denuncia en la Aseguradora</Form.Label>
              
                   <DatePicker
                  selected={moment(op_ingresado!==null?op_ingresado:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                  onChange={setOp_ingresado} // Handle date selection
                  startDate={moment(op_ingresado, 'YYYY-MM-DD').toDate()}
                  dateFormat="dd / MM / yy" // Format for the displayed date
                  customInput={<GreetingsDateInput />}
                />
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Observaciones</Form.Label>
                  <Form.Control size="sm" type="text" value={op_observaciones} onChange={(e) => setOp_observaciones(e.target.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} className="p-2 ">
                <Button onClick={() => guardarValES(filterData.op_id)} color='primary' style={{float:'right', margin:'1rem'}}>Guardar</Button>
            </Col>
            <hr/>

            <h5 className=''>VALIDACIÓN ASEGURADORA</h5>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>E° de la Operación</Form.Label>
                 
                  <select value={op_est_operacion} onChange={(e) => setOp_est_operacion(e.target.value)} className="form-control" id="auto_motivo" name="auto_motivo">
                        <option value="">Seleccione</option>
                        {
                            obtenerEstados.length>0?obtenerEstados.map((item, key) => (

                            <option key={key} value={item.est_op_estado}>{item.est_op_estado}</option>
                            )):''
                          
                          
                        }
                  </select>
                </Form.Group>
            </Col>
            
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>N° de Siniestro</Form.Label>
                  <Form.Control size="sm" type="text" value={op_num_siniestro} onChange={(e) => setOp_num_siniestro(e.target.value)} />
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Fecha de Pago o Rechazo</Form.Label>
                  <DatePicker
                    selected={moment(op_fecha_pago_rechazo!==null?op_fecha_pago_rechazo:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                    onChange={setOp_fecha_pago_rechazo} // Handle date selection
                    startDate={startDate}
                    dateFormat="dd / MM / yy" // Format for the displayed date
                    customInput={<GreetingsDateInput />}
                  />
                  
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>N° de Cuota</Form.Label>
                  <p>{obtenerCuotas.length}</p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Deducible</Form.Label>

                      <Form.Control size="sm" type="text" value={op_deducible} onChange={(e) => setOp_deducible(e.target.value)} />
      
                  
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                  <Form.Label>Observaciones</Form.Label>
                  
                  <Form.Control size="sm" type="text" value={op_motivo_rechazo} onChange={(e) => setOp_motivo_rechazo(e.target.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} className="p-2 ">
                <Button onClick={() => guardarValAseg(filterData.op_id)} color='primary' style={{float:'right', margin:'1rem'}}>Guardar</Button>
            </Col>
            
            <Col xs={12} className="p-2 ">
            {
            obtenerCuotas.length>0?
            <TablaReact2 columns={columnsTabla} data={obtenerCuotas}/>
            :
            <TablaVacia columns={columnsTabla} />

            }

            </Col>
            {
            filterData.op_tipo_poliza!='Desgravamen CV'?( <>
            <hr/>
              <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Cuota">
                        <Form.Label>Valor Cuota</Form.Label>
                        <Form.Control size="sm" type="text" value={(valorCuota)} onChange={(e) => setValorCuota(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="N° de Cuota">
                        <Form.Label>Observaciones de la Cuota</Form.Label>
                        <Form.Control size="sm" type="text" value={(obsCuota)} onChange={(e) => setObsCuota(e.target.value)} />
                    </Form.Group>
                </Col>
            <Col xs={12} className="p-2 ">
            <Button onClick={() => GuardarCuota()} color='primary' style={{float:'right', margin:'1rem'}}>Agregar Cuota</Button>
            </Col>
            </>):''}         
            {
              filterData.op_tipo_poliza=='Desgravamen CV'?(<>
                <hr/>
                <h5 className=''>CIERRE SINIESTRO MUNDO CRÉDITO</h5>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                      <Form.Label>Monto Aportado por el Cliente</Form.Label>
                     
                      <Form.Control size="sm" type="text" value={op_monto_aportado_cliente} onChange={(e) => setOp_monto_aportado_cliente(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                      <Form.Label>Fecha VB° Tesorería</Form.Label>
                      <DatePicker
                        selected={moment(op_fecha_vb_tesoreria!==null?op_fecha_vb_tesoreria:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                        onChange={setOp_fecha_vb_tesoreria} // Handle date selection
                        startDate={startDate}
                        dateFormat="dd / MM / yy" // Format for the displayed date
                        customInput={<GreetingsDateInput />}
                      />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo cajaInputModal" controlId="Patente">
                      <Form.Label>Fecha Cierre de Siniestro</Form.Label>
                      <DatePicker
                        selected={moment(op_fecha_cierre_siniestro!==null?op_fecha_cierre_siniestro:'', 'YYYY-MM-DD').toDate()} // Pass the selected date
                        onChange={setOp_fecha_cierre_siniestro} // Handle date selection
                        startDate={startDate}
                        dateFormat="dd / MM / yy" // Format for the displayed date
                        customInput={<GreetingsDateInput />}
                      />
                    </Form.Group>
                </Col>
                <Col xs={12} className="p-2 ">
                    <Button onClick={() => guardarCierreMC(filterData.op_id)}  color='primary' style={{float:'right', margin:'1rem'}}>Guardar</Button>
                </Col></>):''
            }

          </>
        ):(
          <>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal skeleton" controlId="N° de Operación">
                  <Form.Label>N° de Operación</Form.Label>
          
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo cajaInputModal skeleton" controlId="EmisionID">
                  <Form.Label>Rut Asegurado</Form.Label>

                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Poliza">
                  <Form.Label>Fecha de Nacimiento</Form.Label>

                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Patente">
                  <Form.Label>Nombre</Form.Label>

                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="RUT">
                  <Form.Label>Apellido Paterno</Form.Label>

                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Compañia">
                  <Form.Label>Apellido Materno</Form.Label>
        
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Patente">
                  <Form.Label>Tipo de Póliza</Form.Label>
              
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Patente">
                  <Form.Label>Aseguradora</Form.Label>
   
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Patente">
                  <Form.Label>N° de Póliza</Form.Label>

                </Form.Group>
            </Col>

          </>
        )
      }



      
      
    </Row>
    </Modal.Body>
    <Modal.Footer>
      <Row>
        <Col xs={12}>
            <ButtonGroup aria-label="Basic example">

            </ButtonGroup>

        </Col>
      </Row>


    </Modal.Footer>
    </Modal> 

      </>
    
  );
};

export default TablaSiniestros;
