import Flex from '../../../components/common/Flex';
import LoginForm from '../../../components/authentication/LoginForm';

const Login = () => {
  return (
    <>

    <Flex justifyContent="between" alignItems="center" className="mb-2">
      <h5>Ingresar</h5>
{/*       <p className="fs--1 text-600 mb-0">
        or <Link to="/authentication/simple/register">Create an account</Link>
      </p> */}
    </Flex>
    <LoginForm />
  </>
  )
}

export default Login